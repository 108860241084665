import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface SearchInputComponentProps {
  text: string,
  style: any,
  children?: React.ReactNode
  numberOfLines?: number,
  fontFamily?: string,
}
const SearchInputComponent = ({ text, style, children, numberOfLines, fontFamily }: SearchInputComponentProps) => {
  const [search, setSearch] = useState("")
  const navigate = useNavigate()
  useEffect(() => {
    if (search !== "") {
      if (search == "movies" || search == "MOVIES") {
        navigate("movies")
      }
      if (search == "webseries" || search == "WEBSERIES") {
        navigate("webseries")
      }
      if (search == "trending" || search == "TRENDING") {
        navigate("trending")
      }
    }
  }, [search])
  return (
    <div className='relative xl:w-[42rem] md:w-96 h-[42px]'>
      <input type="text"
        placeholder="Search Movie, NFT Name,  Category...... "
        className="rounded-[30px] focus:outline-none input input-bordered w-full xl:w-[42rem] md:w-96 h-[42px] pr-12"
        value={search}
        onChange={(e) => { setSearch(e.target.value) }} />
      <span className='icon search absolute top-[10px] right-[18px] cursor-pointer' />
    </div>
  );
};

export default SearchInputComponent;
