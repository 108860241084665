import React from "react";
import ChannelCard from "../../../../ui/cards/channel";
const MyChannelsView = ({channelList}) => {
  return (
    <div className="flex overflow-x-auto no-scrollbar gap-[50px] pb-3">
      {channelList?.data?.map((channel: any) => (
        <div key={channel.id}>
          <ChannelCard
            id={channel.id}
            creatorId={channel.creatorId}
            logo={channel.logo}
            name={channel.name}
            subscribers={channel.subscribers}
            earned={channel.earned}
          />
        </div>
      ))}
    </div>
  );
};

export default MyChannelsView;
