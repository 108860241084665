import { validateContentRule } from "../../../../../utils/validation";
const validateForm = (form: any) => {
  const {
    name,
    description,
    image,
    category,
    monthlyMembershipPrice,
    quarterlyMembershipPrice,
    halfYearlyMembershipPrice,
    yearlyMembershipPrice,
  } = form;
  const newErrors: any = {};
  let isValid = true;
  if (!name || name === "") {
    newErrors.name = "Is required";
    isValid = false;
  } else if (validateContentRule(name)) {
    newErrors.name = "Please provide valid content";
    isValid = false;
  }
  if (!description || description === "") {
    newErrors.description = "Is required";
    isValid = false;
  } else if (validateContentRule(description)) {
    newErrors.description = "Please provide valid content";
    isValid = false;
  }
  if (!image || image === "") {
    newErrors.image = "Is required";
    isValid = false;
  }
  if (!category || category === "") {
    newErrors.category = "Is required";
    isValid = false;
  }
  if (!monthlyMembershipPrice) {
    newErrors.monthlyMembershipPrice = "Is required";
    isValid = false;
  } else if (!monthlyMembershipPrice?.match(/^\d{1,}(\.\d{0,8})?$/)) {
    newErrors.monthlyMembershipPrice = "Must be numeric value";
    isValid = false;
  } else if (monthlyMembershipPrice*100000000<=0) {
    newErrors.monthlyMembershipPrice = "Must be greater than 0";
    isValid = false;
  }
  if (!quarterlyMembershipPrice) {
    newErrors.quarterlyMembershipPrice = "Is required";
    isValid = false;
  } else if (!quarterlyMembershipPrice?.match(/^\d{1,}(\.\d{0,8})?$/)) {
    newErrors.quarterlyMembershipPrice = "Must be numeric value";
    isValid = false;
  } else if (quarterlyMembershipPrice*100000000<=0) {
    newErrors.quarterlyMembershipPrice = "Must be greater than 0";
    isValid = false;
  }
  if (!halfYearlyMembershipPrice) {
    newErrors.halfYearlyMembershipPrice = "Is required";
    isValid = false;
  } else if (!halfYearlyMembershipPrice?.match(/^\d{1,}(\.\d{0,8})?$/)) {
    newErrors.halfYearlyMembershipPrice = "Must be numeric value";
    isValid = false;
  } else if (halfYearlyMembershipPrice*100000000<=0) {
    newErrors.halfYearlyMembershipPrice = "Must be greater than 0";
    isValid = false;
  }
  if (!yearlyMembershipPrice) {
    newErrors.yearlyMembershipPrice = "Is required";
    isValid = false;
  } else if (!yearlyMembershipPrice?.match(/^\d{1,}(\.\d{0,8})?$/)) {
    newErrors.yearlyMembershipPrice = "Must be numeric value";
    isValid = false;
  } else if (yearlyMembershipPrice*100000000<=0) {
    newErrors.yearlyMembershipPrice = "Must be greater than 0";
    isValid = false;
  }
  return [isValid, newErrors];
};

export default validateForm;
