import React, { useEffect } from "react";
import { store } from "../../../store";
import { useSelector } from "react-redux";
import { fetchGeners } from "../../../reducers/dashboardReducer";
import ScrollingComponent from "../../../ui/scrollingComponent";

const take = 20;
const Genres = () => {
  const dashbaordData = useSelector((store: any) => store?.dashboard);
  useEffect(() => {
    store.dispatch(fetchGeners());
  }, []);
  return (
    <div className="">
      <ScrollingComponent
        title={"Genres"}
        data={dashbaordData?.genres?.data}
        toDisplay={"genre"}
        isLoading={dashbaordData?.genres?.isLoading}
      />
    </div>
  );
};

export default Genres;
