import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../store";
// import trailer from "../../../assets/video/Adipurush.mp4";
// import logo from "../../../assets/images/animal-banner.png";
import { fetchchannelsrelativeVideos } from "../../../reducers/channelReducer";
import PaginationComponent from "../../../ui/paginationComponent";
import { setError } from "../../../reducers/layoutReducer";

let take =3;
const RelatedVideos = () => {
    const dispatch = useDispatch();

    const relativevideos = useSelector((store: any) => store?.channels);
    useEffect(() => {
      store.dispatch(fetchchannelsrelativeVideos({
        take: take,
        page: 1,
        data: null,
      }));
    }, [dispatch]);
    const fetchMoreChannels = ( data: any, page: number) => {
        store.dispatch(
            fetchchannelsrelativeVideos({
            take: take,
            page: page,
            data: null,
          })
        );
      };
    if(relativevideos?.channelrelativeVideos?.error){
        dispatch(setError({ message: relativevideos?.channelrelativeVideos?.error }))
      }

    return (
    <>
        <div className="mb-4 flex items-center justify-between mt-[34px]">  
            <h3 className="text-xl font-medium textw-[390px]-secondary">Related Videos</h3>
            {/* <div className="flex ">
                <div className="hover:bg-accent hover:rounded-full h-9 w-9 flex items-center justify-center cursor-pointer"><span className="icon leftarrow-small"></span></div>
                <div className="hover:bg-accent hover:rounded-full h-9 w-9 flex items-center justify-center cursor-pointer"><span className="icon rightarrow-small"></span></div>
            </div> */}
        </div>

        <div className="carousel gap-4 overflow-y-hidden w-full" >
        <PaginationComponent
          toDisplay="video"
          data={relativevideos?.channelrelativeVideos?.data}
          title={''}
          isLoading={relativevideos?.channelrelativeVideos?.loading}
          itemsPerPage={take}
          nextPage={relativevideos?.channelrelativeVideos?.nextPage}
          onPageChange={(data: any, page: number) =>
            fetchMoreChannels( data, page)
          }
          displayNoData={true}
        />
        {/* { relativevideos.channelrelativeVideos.data.map((relativevideosList)=>(
           <div className="carousel-item shadow-lg">
          
           <div className=" rounded-lg border header-shadow relative " key={relativevideosList.id}>
                <video controls className="rounded-t-lg h-[146px] w-full object-cover" muted>
                    <source src={relativevideosList.videoLink} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="px-3 py-4">
                    <div className="flex gap-2">

                        <img className="h-[40px] w-[40px] rounded-full object-cover" src={relativevideosList.logo} alt="" />
                        <div className="flex-1">
                            <p className="text-neutral text-sm font-normal">{relativevideosList.createdDate}</p>
                            <p className="text-base text-secondary font-medium">{relativevideosList.title}</p>
                            <div className="md:flex gap-3 items-center justify-between">
                                <div className="flex gap-3 items-center">
                                    <div className="flex gap-1 items-center">
                                        <span className="icon eye-view"></span><label className="text-neutral text-sm font-normal">{relativevideosList.language}</label>
                                    </div>
                                    <div className="flex gap-1">
                                        <span className="icon clock"></span><label className="text-neutral text-sm font-normal">{relativevideosList.views}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
           
           </div>
             ))
            } */}

        </div>

    </>

    );
};

export default RelatedVideos;
