import React from "react";
import { Link } from "react-router-dom";
import VideoPlayer from "../videoPlayer";
import Button from "../Button";
import Moment from "react-moment";
import { useAccount } from "wagmi";
import { useSelector } from "react-redux";
interface VideoCardProps {
  id: string;
  price: number;
  videoLink: string;
  thumbnail: string;
  channelLogo: string;
  channelName: string;
  videoTitle: string;
  language: string;
  views: string;
  createdDate: string;
  type: string;
  isVideoForSale: boolean;
  creatorId: any;
}
const VideoCard = ({
  id,
  price,
  videoLink,
  thumbnail,
  channelLogo,
  channelName,
  videoTitle,
  language,
  views,
  createdDate,
  type,
  isVideoForSale,
  creatorId,
}: VideoCardProps) => {
  const { isConnected } = useAccount();
  const user = useSelector((store: any) => store.auth.user);
  return (
    <div className=" rounded-lg border header-shadow relative w-full">
      <Link to={`/watch/${id}`}>
        <VideoPlayer
          videoclass="video-height"
          src={videoLink}
          controls={false}
          poster={thumbnail}
        />
      </Link>
      <div className="px-3 py-4">
        <div className="flex gap-2">
          <img
            className="h-[40px] w-[40px] rounded-full object-cover"
            src={channelLogo}
            alt=""
          />
          <div className="flex-1">
            <p className="text-neutral text-sm font-normal">{channelName}</p>
            <p className="text-base text-secondary font-medium">
              {videoTitle} - {language}
            </p>
            <div className="md:flex gap-3 items-center justify-between">
              <div className="flex gap-3 items-center">
                <div className="flex gap-1 items-center">
                  <span className="icon clock"></span>

                  <Moment
                    className="text-neutral text-sm font-normal"
                    toNow
                    local={true}
                  >
                    {createdDate}
                  </Moment>
                </div>
                <div className="flex gap-1">
                  <span className="icon eye-view"></span>
                  <label className="text-neutral text-sm font-normal">
                    {views} views
                  </label>
                </div>
              </div>
              <div className="max-sm:text-right">
                {price > 0 &&
                  isVideoForSale &&
                  isConnected &&
                  user.id !== creatorId && (
                    <Button
                      type="cardButton"
                      btnClassName="px-4 h-[28px] hover:opacity-60"
                    >
                      Buy NFT
                    </Button>
                  )}
                {price > 0 && isConnected && user.id === creatorId && (
                  <Button
                    type="cardButton"
                    btnClassName="px-4 h-[28px] hover:opacity-60"
                  >
                    Put On Sale
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {type && (
        <div
          className={` ${
            !price || price === 0 ? "bg-[#008000]" : "bg-[#D600004D]"
          }  px-[14px] py-1 rounded-ss-lg rounded-ee-lg w-fit absolute left-0 top-0`}
        >
          <p className="text-[10px] text-base-100 font-semibold">
            {` ${!price || price === 0 ? type : `Watch with ${price} Matic`} `}
          </p>
        </div>
      )}
    </div>
  );
};

export default VideoCard;
