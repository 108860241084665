import React, { useEffect, useState } from "react";
import Button from "../../../../ui/Button";
//import CastAndCrewMember from "../../projects/castandcrewmember";
import NftProducers from "./NftProducers";
import success from "../../../../assets/images/success.png";
import successgif from "../../../../assets/images/reviewsuccess.gif";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchchannelvideoPlay } from "../../../../reducers/channelReducer";
import { store } from "../../../../store";
import { POST, PUT } from "../../../../api/httpClient";
import VideoPlayer from "../../../../ui/videoPlayer";
import Moment from "react-moment";
import { setError, setToaster } from "../../../../reducers/layoutReducer";
import apiCalls from "../../../../utils/api";
import Spinner from "../../../loaders/spinner";
// import successgif from '../../../assets/images/reviewsuccess.gif'

const DetailviewLeftpanel = () => {
  const customerData = useSelector((store: any) => store?.auth);
  const playVideo = useSelector((store: any) => store?.channels);
  const params = useParams();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hideMetamask, setHideMetamask] = useState(true);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showseemore, setShowseemore] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [disLike, setDisLike] = useState(false);
  const [subscribed, setSubscribed] = useState({});
  const description = playVideo?.channelvideoPlay?.data?.description;
  const creatorId = playVideo?.channelvideoPlay?.data?.creatorId;
  const channelId = playVideo?.channelvideoPlay?.data?.channelId;
  const customerId = customerData.user.id;
  const customeraddress = customerData.address;
  const walletAddress = customerData.address;
  const channelOwnerAddress = playVideo.channelOwnerAddress;
  const [isSubscribing, setIsSubscribing] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    store.dispatch(fetchchannelvideoPlay(params?.videoId, customerId));
  }, []);

  const toggleShowMore = () => {
    setShowFullDescription(!showFullDescription);
    setShowseemore(true);
  };

  const handleMetamaskClick = () => {
    setShowConfirmation(true);
  };
  const hideMetamaskClick = () => {
    setHideMetamask(false);
  };
  const handleSubscribe = async (channelId: any) => {
    setIsSubscribing(true);
    try {
      const isSubscribed = subscribed[channelId];
      const postData = {
        channelId: channelId,
        customerId: customerId,
        walletAddress: walletAddress,
        subscriptionState: !isSubscribed,
      };
      const response = isSubscribed
        ? await apiCalls.handleChannelSubscription(postData, "update")
        : await apiCalls.handleChannelSubscription(postData, "save");

      if (response?.status === 200) {
        dispatch(
          setToaster({
            message: isSubscribed ? "removed!" : "added!",
          })
        );
        setSubscribed((prevSubscribeAction) => {
          const updatesubscribeAction = { ...prevSubscribeAction };
          updatesubscribeAction[channelId] = !isSubscribed;
          return updatesubscribeAction;
        });
      } else {
        dispatch(setError({ message: response }));
      }
    } catch (error) {
      dispatch(setError({ message: error }));
    } finally {
      setIsSubscribing(false);
    }
  };

  const Likehandler = async () => {
    try {
        let data = {
            customerId: customerId,
            videoOrCommentId: params?.videoId,
            isLiked: !isLike,
        };
        let response: any;
        if (customerId !== undefined) {
            if (isLike) {
              response = await PUT(`updatevideolike`, data);
              setIsLike(false);
            } else {
              response = await POST(`savelikevideo`, data);
              setIsLike(true);
            }
            if (response.status === 200) {
                store.dispatch(fetchchannelvideoPlay(params?.videoId, customerId));
            } else {
                dispatch(setError({ message: response }));
            }
        } else {
            dispatch(setError({ message: 'Customer ID is undefined' }));
        }
    } catch (error) {
        dispatch(setError({ message: error }));
    }
};

  const handleDisLike = async () => {
      let data = {
        customerId: customerId,
        videoOrCommentId: params?.videoId,
        isLiked: !disLike,
      }
      let response: any
      try {
      if (disLike) {
        response = await PUT(`updatevideolike`, data);
        setDisLike(false);
      } else {
        response = await POST(`savelikevideo`, data);
        setDisLike(true);
      }
      if (response.status === 200) {
        store.dispatch(fetchchannelvideoPlay(params?.videoId, customerId));
      } else {
        dispatch(setError({ message: response }));
      }
    } catch (error) {
      dispatch(setError({ message: error }));
    }
  };

  const videosherehandler = async () => {
    try {
      let data = {
        videoId: params?.videoId,
        customerAddress: customeraddress,
      };
      const res = await POST("savevideoshare", data);
    } catch (error) {
      dispatch(setError({ message: error }));
    }
  };

  return (
    <div className=" rounded-lg header-shadow relative shadow-md h-full">
      <div className="relative">
        {/* <video controls className="rounded-t-lg h-[546px] w-full object-cover" muted>
                    <source src={playVideo?.channelvideoPlay?.data?.videoLink} type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
        <VideoPlayer
          src={playVideo?.channelvideoPlay?.data?.videoLink}
          playerClass="rounded-t-lg lg:h-[546px] w-full object-cover overflow-hidden"
        />

        {/* {hideMetamask && (
          <div className="h-full absolute pt-12 md:pt-24 top-0 bg-[#00000040] w-full text-center max-sm:px-4">
            {showConfirmation ? (
              <div className="relative">
                <div>
                  <img src={success} alt="success" className="mx-auto" />
                  <h1 className="text-error-content text-[42px] font-semibold">
                    Confirmation
                  </h1>
                  <img
                    src={successgif}
                    alt=""
                    className="h-[280px] m-[-180px] mx-auto"
                  />
                  <p className="text-error-content mt-20 mb-6">
                    Connect with one of available wallet providers or create a{" "}
                    <br /> new wallet.What is wallet?
                  </p>
                  <Button
                    type="primary"
                    btnClassName="h-[52px]"
                    handleClick={hideMetamaskClick}
                  >
                    Confirm to paly with 2 Matic
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <div className="text-right pr-4 absolute top-6 right-2">
                  <Link to="/dashboard">
                    <span className="icon close-red cursor-pointer scale-[1.3]"></span>
                  </Link>
                </div>
            </div>

            <div className="flex justify-end gap-2.5 px-3">
                <button className="bg-accent px-4 py-2 rounded-[20px] text-sm font-medium whitespace-nowrap like" onClick={Likehandler}> <span className="icon  like-icon mr-2" ></span>Like <span className="text-xs">({playVideo?.channelvideoPlay?.data?.likes})</span></button>
                <button className="bg-accent px-4 py-2 rounded-[20px] text-sm font-medium whitespace-nowrap unlike" onClick={Likehandler}> <span className="icon unlike-icon mr-2" ></span>Unlike<span className="text-xs"> ({playVideo?.channelvideoPlay?.data?.unLikes})</span></button>
                <button className="group bg-accent px-4 py-2 rounded-[20px] text-sm font-medium whitespace-nowrap h-[42px]">
                    <span className="group-hover:hidden">
                        <span className="icon share mr-2"></span><span className="align-middle">Share</span>
                    </span>
                    <div className='group-hover:grid grid-cols-6 gap-3 hidden '>
                        <a href="https://twitter.com/YellowblockNet" target='_blank' rel="noreferrer"> <span className={`twitter icon`}></span></a>
                        <a href="https://www.facebook.com/YellowblockNet/" target='_blank' rel="noreferrer"> <span className={`facebook icon`}></span></a>
                        <a href="https://www.instagram.com/yellowblock_software_sols/" target='_blank' rel="noreferrer"> <span className={`insta icon`}></span></a>
                        <a href="https://t.me/yellowblock_software_solutions" target='_blank' rel="noreferrer"> <span className={`telegram icon`}></span></a>
                        <a href="https://discord.com/invite/62bJtveA" target='_blank' rel="noreferrer"> <span className={`discord icon`}></span></a>
                        <a href="https://yellowblock.net/" target='_blank' rel="noreferrer"> <span className={`globe icon`}></span></a>
                    </div>
                </button>

            </div>
            <hr className="mt-4 mb-[18px]" />
            <div className="px-3">
                <h1 className="text-lg font-semibold text-secondary mb-2">NFT Producers</h1>
                <NftProducers nft={playVideo?.channelvideoPlay?.data} />
            </div>
            <hr className="mt-4 mb-2" />
            <div className="px-3 pb-3">
                <h1 className="text-lg font-semibold text-secondary">About the movie</h1>
                <p className="text-base-200">
                    {showFullDescription ? description : description?.slice(0, 40)}
                </p>
                <div
                  className="bg-primary text-error-content py-2.5 px-6 w-[232px] min-h-[56px] flex items-center gap-3.5 mx-auto rounded-[42px] cursor-pointer hover:opacity-80"
                  onChange={handleMetamaskClick}
                >
                  <span className="icon Metamask"></span>
                  <span>Metamask Wallet</span>
                </div>
                <div className="bg-primary text-error-content py-2.5 px-6 w-[232px] min-h-[56px] flex items-center gap-3.5 mx-auto mt-4 rounded-[42px] cursor-pointer hover:opacity-80">
                  <span className="icon WalletConnect"></span>
                  <span>WalletConnect</span>
                </div>
                <p className="mt-8 text-white opacity-80">
                  we do not own private keys and cannot access your <br /> funds
                  without your confirmation
                </p>
              </div>
            )}
          </div>
        )} */}
      </div>
      <div className="px-3 py-4 flex justify-between items-center">
        <div className="flex gap-2">
          <img
            className="h-[40px] w-[40px] rounded-full object-cover"
            src={playVideo?.channelvideoPlay?.data?.logo}
            alt=""
          />
          <div className="flex-1">
            <p className="text-neutral text-sm font-normal">
                {playVideo?.channelvideoPlay?.data?.channelName}
            </p>
            <h2 className="text-base text-secondary font-medium">
              {playVideo?.channelvideoPlay?.data?.title}
            </h2>
            <p className="text-base text-secondary font-medium">
              {playVideo?.channelvideoPlay?.data?.language}
            </p>
          </div>
        </div>
       {walletAddress !==channelOwnerAddress && 
        <div className="">
          <Button
            type="cardButton"
            btnClassName="!h-[32px]"
            handleClick={() => handleSubscribe(channelId)}
            disabled={isSubscribing || customerId === creatorId}
          >
            {" "}
            {isSubscribing && (
              <span>
                <Spinner />
              </span>
            )}
            {subscribed[channelId] ? "Subscribed" : "Subscribe"}
          </Button>
        </div>
       }
      </div>

      <div className="flex justify-end gap-2.5 px-3">
        <button
          className="bg-accent px-4 py-2 rounded-[20px] text-sm font-medium whitespace-nowrap"
          onClick={Likehandler}
        >
          {" "}
          <span className="icon  like-icon mr-2"></span>Like{" "}
          <span className="text-xs">
            ({playVideo?.channelvideoPlay?.data?.likes})
          </span>
        </button>
        <button
          className="bg-accent px-4 py-2 rounded-[20px] text-sm font-medium whitespace-nowrap"
          onClick={handleDisLike}
        >
          {" "}
          <span className="icon unlike-icon mr-2"></span>Unlike
          <span className="text-xs">
            {" "}
            ({playVideo?.channelvideoPlay?.data?.unLikes})
          </span>
        </button>
        <button className="group bg-accent px-4 py-2 rounded-[20px] text-sm font-medium whitespace-nowrap h-[42px]">
          <span className="group-hover:hidden">
            <span className="icon share mr-2"></span>
            <span className="align-middle">Share</span>
          </span>
          <div className="group-hover:grid grid-cols-6 gap-1 hidden ">
            <a
              href="https://twitter.com/YellowblockNet"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <span
                className={`twitter icon scale-[0.8] hover:scale-[1] transition-[2.5]`}
              ></span>
            </a>
            <a
              href="https://www.facebook.com/YellowblockNet/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <span
                className={`facebook icon scale-[0.8] hover:scale-[1] transition-[2.5]`}
              ></span>
            </a>
            <a
              href="https://www.instagram.com/yellowblock_software_sols/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <span
                className={`insta icon scale-[0.8] hover:scale-[1] transition-[2.5]`}
              ></span>
            </a>
            <a
              href="https://t.me/yellowblock_software_solutions"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <span
                className={`telegram icon scale-[0.8] hover:scale-[1] transition-[2.5]`}
              ></span>
            </a>
            <a
              href="https://discord.com/invite/62bJtveA"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <span
                className={`discord icon scale-[0.8] hover:scale-[1] transition-[2.5]`}
              ></span>
            </a>
            <a href="https://yellowblock.net/" target="_blank" rel="noreferrer">
              {" "}
              <span
                className={`globe icon scale-[0.9] hover:scale-[1] transition-[2.5]`}
              ></span>
            </a>
          </div>
        </button>
      </div>
      <hr className="mt-4 mb-[18px]" />
      <div className="px-3">
        <h1 className="text-lg font-semibold text-secondary mb-2">
          NFT Producers
        </h1>
        <NftProducers nft={playVideo?.channelvideoPlay?.data} />
      </div>
      <hr className="mt-4 mb-2" />
      <div className="px-3 pb-3">
        <h1 className="text-lg font-semibold text-secondary">
          About {playVideo?.channelvideoPlay?.data?.title}
        </h1>
        <p className="text-base-200">
          {showFullDescription ? description : description?.slice(0, 40)}
        </p>
        {showseemore && description && description.length > 40 && (
          <button
            className="text-primary font-semibold"
            onClick={toggleShowMore}
          >
            {" "}
            {showFullDescription ? "Show less" : "Show more"}
          </button>
        )}
      </div>
    </div>
  );
};

export default DetailviewLeftpanel;
