import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from '../reducers/rootReducer';
import authReducer from '../reducers/authReducer';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { dashboardReducer } from '../reducers/dashboardReducer';
import {channelReducer} from '../reducers/channelReducer';
import { commentReducer } from '../reducers/commentReducer';
import { ratingReducer } from '../reducers/ratingReducer';
import layoutReducer from '../reducers/layoutReducer';
import { webSeriesReducer } from '../reducers/webseriesReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};
const rootReducerState = combineReducers({ 
  auth: rootReducer,
  oidc: authReducer,
  channels:channelReducer,
  dashboard:dashboardReducer, 
  getcomment:commentReducer,
  ratingdetails:ratingReducer,
  layoutReducer:layoutReducer,
  webseriesDeatils:webSeriesReducer,
 });
const persistedReducer = persistReducer(persistConfig, rootReducerState);
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
const persistor = persistStore(store);
export { store, persistor };


