import { GET } from "../api/httpClient"
import { isErrorDispaly } from "../components/profile/utils"

const GET_RATING = "getRating"
const SET_RATING = "setRating"

const getRating = () => {
    return {
        type: GET_RATING,
        payload: null
    }
}
const setRating = (data) => {
    return {
        type: SET_RATING,
        payload: data
    }
}

export const fetchRating = (id) => {
    return async (dispatch) => {
        dispatch(getRating(id))
        try {
            const response = await GET(`ratingdetails/${id}`)
            if (response.status === 200) {
                dispatch(setRating({ error: null, data: response.data }))
            }
            else {
                dispatch(setRating({ error: isErrorDispaly(response), data: [] }))
            }
        } catch (err) {
            dispatch(setRating({ error: isErrorDispaly(err), data: [] }))
        }
    }
}

const initailstate = {
    rating: {
        isLoading: false,
        data: [],
        error: null
    }
}

export const ratingReducer = (state = initailstate, action) => {
    switch (action.type) {
        case GET_RATING:
            state = { ...state, rating: { isLoading: true, data: [], error: null } };
            return state;
        case SET_RATING:
            state = { ...state, rating: { isLoading: false, ...action.payload } };
            return state;
        default:
            return state;
    }
}