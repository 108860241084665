import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setError } from "../reducers/layoutReducer";
import { apiUploadPost } from "../utils/api";
import Spinner from "../components/loaders/spinner";
interface FileUploaderProps {
  accept?: string;
  canDragAndDrop?: boolean;
  canCopyAndPaste?: boolean;
  inputClass?: string;
  file?: any;
  setFile: Function;
  inputRef: any;
  uploaderClass?: string;
  size?: number;
  uploadTo?: string;
  previewWithInlineUpload?: boolean;
  uploadText?: string;
  uploadIcon?: any;
  uploadTextClass?: string;
  uploadTextBoxClass?: string;
  uploadIconNote?: string;
}
const fileTypes = {
  images: {
    "image/png": true,
    "image/jpg": true,
    "image/jpeg": true,
    "image/PNG": true,
    "image/JPG": true,
    "image/JPEG": true,
    errorMessage:
      "File is not allowed. Please upload only jpg, png, jpeg files!",
  },
  videos: {
    "video/mp4": true,
    "video/webm": true,
    "video/ogg": true,
    errorMessage:
      "File is not allowed. Please upload only mp4, webm, ogg files!",
  },
  all: true,
};

const isFileAcceptable = (
  file: any,
  size: number | undefined,
  accept: string | undefined
) => {
  const fileSizeInMB = file.size / (1024 * 1024);
  if (accept && !fileTypes[accept][file.type]) {
    return { acceptable: false, error: fileTypes[accept]["errorMessage"] };
  }
  if (size && fileSizeInMB > size) {
    return {
      acceptable: false,
      error: `File Size should be less than ${size}MB! Current File size : (${fileSizeInMB}) MB`,
    };
  }
  return { acceptable: true, error: "" };
};
const FileUploader = ({
  uploadText = "",
  uploadTextClass = "",
  uploadIcon = "",
  uploadTextBoxClass = "",
  uploadIconNote = "",
  accept,
  canDragAndDrop = false,
  canCopyAndPaste = false,
  inputClass,
  file,
  setFile,
  inputRef,
  uploaderClass,
  size,
  uploadTo = `Upload/UploadFileNew`,
  previewWithInlineUpload = false,
}: FileUploaderProps) => {
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const rootDispatch = useDispatch();
  const uploadToServer = async (file: any, event) => {
    const body: any = new FormData();
    setLoader(true);
    setProgress(0)
    body.append("file", file);
    if (accept ? fileTypes[accept][file?.type] : fileTypes["all"]) {
      try {
        const response =
          accept === "videos"
            ? await apiUploadPost(uploadTo, body, setProgress)
            : await apiUploadPost(uploadTo, body);
        if (response.statusText.toLowerCase() === "ok") {
          rootDispatch(setError({ message: "" }));
          let url=""
          if(accept==='images') url=response?.data[0]
          if(accept==='videos') url=response?.data
          setFile?.({ name: file?.name, url: url });
        } else {
          rootDispatch(setError({ message: response }));
        }
      } catch (error) {
        rootDispatch(setError({ message: error }));
      } finally {
        progress && setProgress(0)
        setLoader(false);
      }
    } else {
      rootDispatch(
        setError({
          message: accept
            ? fileTypes[accept]["errorMessage"]
            : "File not allowed!",
        })
      );
      event.target.value = "";
      setLoader(false);
    }
  };
  const handleFileChange = (event: any) => {
    event.preventDefault();
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const { acceptable, error } = isFileAcceptable(
        selectedFile,
        size,
        accept
      );
      if (acceptable) {
        uploadToServer(selectedFile, event);
      } else {
        if (inputRef) inputRef.current.value = null;
        rootDispatch(setError({ message: error }));
      }
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (canDragAndDrop) {
      const selectedFile = event.dataTransfer.files[0];
      if (selectedFile) {
        const { acceptable, error } = isFileAcceptable(
          selectedFile,
          size,
          accept
        );
        if (acceptable) {
          uploadToServer(selectedFile, event);
        } else {
          if (inputRef) inputRef.current.value = null;
          rootDispatch(setError({ message: error }));
        }
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handlePaste = (event) => {
    event.preventDefault();
    if (canCopyAndPaste) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent.clipboardData;
      const pastedFile = clipboardData.files[0];
      if (pastedFile) {
        const { acceptable, error } = isFileAcceptable(
          pastedFile,
          size,
          accept
        );
        if (acceptable) {
          uploadToServer(pastedFile, event);
        } else {
          if (inputRef) inputRef.current.value = null;
          rootDispatch(setError({ message: error }));
        }
      }
    }
  };
  return (
    <>
      {!previewWithInlineUpload && (
        <label
          htmlFor="fileUploader"
          className={
            uploaderClass ||
            "relative flex items-center justify-between border border-t-0 px-3.5 py-4  fileUpload"
          }
        >
          {!loader && (
            <button
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onPaste={handlePaste}
              className="w-full"
            >
              {uploadIcon && (
                <>
                  <img
                    className="object-cover m-auto"
                    src={uploadIcon}
                    alt={"profile"}
                  />
                  {uploadIconNote && (
                    <span className="text-secondary opacity-50 text-sm font-normal">
                      {uploadIconNote}
                    </span>
                  )}
                </>
              )}
              <input
                id="fileUploader"
                ref={inputRef}
                type="file"
                className={
                  inputClass ||
                  "absolute bottom-0 left-0 right-0 top-0 ml-0 w-full opacity-0 cursor-pointer"
                }
                onChange={handleFileChange}
              />
              <div
                className={`flex justify-center items-center gap-2 ${uploadTextBoxClass} `}
              >
                <p className={uploadTextClass || "fileUploadText"}>
                  {uploadText || (
                    <>
                      {accept === "images" && "Upload Your  Image"}{" "}
                      {accept === "videos" && "Upload Your Video"}{" "}
                      {accept === "documents" && "Upload Your Document"}{" "}
                      {accept === "all" && "Upload your File"}{" "}
                    </>
                  )}
                </p>
                <span className="icon upload-plus opacity-100"></span>
              </div>
              <span className="pointer-events-none relative pl-1 text-sm">
                <span className={`text-secondary text-sm font-normal`}>
                  {canDragAndDrop &&
                    !canCopyAndPaste &&
                    `Attach ${accept} by dragging & dropping, or by uploading them.`}
                  {!canDragAndDrop &&
                    canCopyAndPaste &&
                    `Attach ${accept} by selecting and pasting, or by uploading them.`}
                  {canDragAndDrop &&
                    canCopyAndPaste &&
                    `Attach ${accept} by dragging & dropping, or  selecting and pasting, or by uploading them.`}
                </span>
              </span>
            </button>
          )}
          {
            <p className="text-center grow">
              {loader && progress===0  && <Spinner />}
              {loader && progress>0 && (
                    <div className="mt-5">
                      <progress value={progress} max="100" />
                      <span>{progress}%</span>
                      <p>upload in progress...</p>
                    </div>
                  )}
            </p>
          }
        </label>
      )}
      {previewWithInlineUpload && (
        <div className="mx-auto">
          <div className="relative">
            <div className="avatar">
              <div className="md:w-[150px] md:w-[150px] rounded-full">
                <img
                  className="h-full w-full object-cover"
                  src={file?.url}
                  alt={"profile"}
                />
              </div>
            </div>
            <span className="icon camera absolute bottom-2 right-[17px] cahnnel-cam cursor-pointer ">
              <input
                type="file"
                name="myImage"
                className="opacity-0 w-[26px] cursor-pointer"
                onChange={(e) => handleFileChange(e)}
                ref={inputRef}
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default FileUploader;
