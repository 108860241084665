import React, { useCallback, useEffect, useMemo, useState } from "react";
import logo from "../../../../assets/images/logo.svg";
import { Image } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import WalletConnect from "../../../modules/ConnectButton/connect.wallet";
import { useAccount, useDisconnect } from "wagmi";
import { store } from "../../../../store";
import { setUserID, walletAddress } from "../../../../reducers/rootReducer";
import { getKyc } from "../../../../utils/api";
import userImage from "../../../../assets/images/avatar.jpg";
import PrimaryLink from "../../../../ui/Links/PrimaryLink";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../ui/Button";
import SearchInputComponent from "../../SearchComponent";
import DropdownMenus from "../../../../ui/DropdownMenus";
import { Modal, modalActions } from "../../../../ui/Modal";
import { setSidebarDetails } from "../../../../reducers/layoutReducer";
import Sidebar from "./sidebar";
import profileLogo from "../../../../assets/images/default-profile.svg";
function HeaderNavbar() {
  const [modalShow, setModalShow] = useState(false);
  const { disconnectAsync } = useDisconnect();
  const { isConnected, address } = useAccount();
  const rootDispatch = useDispatch();
  const sidebarDetails: any = useSelector(
    (store: any) => store.layoutReducer.sidebar
  );
  const getCustomerDetails = async () => {
    let res = await getKyc(`User/CustomerDetails/${address}`);
    store.dispatch(setUserID(res.data));
    store.dispatch(walletAddress(address));
  };
  const userProfilePic = useSelector(
    (state: any) => state.auth.user?.profilePicUrl
  );

  const navigate = useNavigate();
  const handleDisconnect = async () => {
    await disconnectAsync();
    store.dispatch(setUserID(null));
    setModalShow(false);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    if (address) {
      getCustomerDetails();
    }
  }, [address]);

  const handleSideBarShow = () => {
    rootDispatch(
      setSidebarDetails({
        show: !sidebarDetails.show,
        sidebarClass: sidebarDetails.show ? "w-0" : "w-[250px]",
        outletClass: sidebarDetails.show ? "ml-0" : "ml-[264px]",
      })
    );
  };
  const handleDropdownAction = useCallback((path: string) => {
    navigate(path);
  }, []);

  const { navBarDropDownMenu, globalDropdown } = useMemo(() => {
    const globalDropdown = [
      {
        name: "Streaming",
        image:
          "https://dottdevstoragespace.blob.core.windows.net/images/streming.png",
        action: () => navigate("/home/all"),
      },
      {
        name: "Launchpad",
        image:
          "https://dottdevstoragespace.blob.core.windows.net/images/launchpad 1.png",
        action: () => window.open("https://www.dott.network", "_blank"),
      },
      {
        name: "Marketplace",
        image:
          "https://dottdevstoragespace.blob.core.windows.net/images/marketplace.png",
        action: () =>
          window.open("https://www.dott.network/marketplace", "_blank"),
      },
      {
        name: "DAO’s",
        image:
          "https://dottdevstoragespace.blob.core.windows.net/images/dao.png",
        action: () => window.open("https://www.dott.network/daos", "_blank"),
      },
    ];
    const navBarDropDownMenu = [
      {
        name: "Profile",
        action: () => handleDropdownAction("/profile"),
        isActive: pathname.includes("profile"),
      },
      {
        name: "My Channels",
        action: () => handleDropdownAction("/mychannels"),
        isActive: pathname.includes("mychannels"),
      },
      {
        name: "Disconnect",
        action: () => {
          handleDisconnect();
          handleDropdownAction("/home/All");
        },
      },
      // { name: "Notifications (3)", action: () => handleDropdownAction("#") },
    ];
    return { navBarDropDownMenu, globalDropdown };
  }, [pathname]);
  return (
    <div>
      <div className="sticky top-0 z-[99] bg-success-content">
        <div className="navbar bg-success-content p-0 header-shadow px-4">
          <div className="navbar-start">
            <span
              className="icon toggle-icon cursor-pointer bg-inherit rounded-none border-0"
              onClick={handleSideBarShow}
            />

            <div className="pr-4  hidden lg:flex">
              {" "}
              {/*border-r border-gray-300 */}
              <PrimaryLink path={"/home/All"}>
                <Image src={logo} className="h-8 mr-3 w-[93px]" />
              </PrimaryLink>
            </div>
            <div className="flex items-center">
              <div className="pr-4 lg:hidden">
                <PrimaryLink path={"/streamingdashboard"}>
                  <Image src={logo} className="h-8 mr-3 w-[60px]" />
                </PrimaryLink>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <SearchInputComponent />
          </div>
          <div className="navbar-end">
            <div className="mr-6">
              <DropdownMenus
                btnContent={<span className="icon menu-icon"></span>}
                dropdownClass="dropdown-end"
                dropdownList={globalDropdown}
                menuwidth="min-w-[254px] grid grid-cols-2 global-list"
                btnCenter="text-center py-4"
                borderList="border border-t-0"
              />
            </div>
            {!isConnected && (
              <>
                <Button
                  type="primary"
                  handleClick={() => {
                    modalActions("walletConnectModal", "open");
                  }}
                  btnClassName="flex items-center"
                >
                  <>
                    <span>Connect Wallet</span>
                    <span className={`icon wallet mt-[-3px]`}></span>
                  </>
                </Button>
                {
                  <Modal id={"walletConnectModal"}>
                    <WalletConnect
                      onWalletConect={() => getCustomerDetails()}
                      onWalletClose={() => {
                        modalActions("walletConnectModal", "close");
                      }}
                    />
                  </Modal>
                }
              </>
            )}
            {isConnected && (
              <DropdownMenus
                menuwidth={"!min-w-[172px]"}
                btnContent={
                  <span className="relative">
                    <div
                      className={`p-2 px-2 rounded-[33px] border-solid border-[1px] border-primary bg-primary hover:bg-primary !text-base-100 font-semibold text-sm flex items-center gap-4 lg:px-4 max-sm:scale-[0.7]`}
                    >
                      <span className="!text-base-100 inline-block text-sm leading-5">
                        {address?.slice(0, 4) +
                          "...." +
                          address?.substring(
                            address.length - 4,
                            address.length
                          )}
                      </span>
                      {!userProfilePic && (
                        <span>
                          <img
                            src={profileLogo}
                            alt="user profile"
                            className="w-[30px] h-[30px] rounded-full object-cover border border-[#fff]"
                          />
                        </span>
                      )}
                      {userProfilePic && (
                        <span>
                          <img
                            src={userProfilePic}
                            alt="user profile"
                            className="w-[30px] h-[30px] rounded-full object-cover border border-[#fff]"
                          />
                        </span>
                      )}
                    </div>
                    {/* <span className='inline-block w-6 h-6 bg-black border rounded-full absolute text-base-100 flex justify-center items-center right-[-4px] top-[-10px]'>3</span> */}
                  </span>
                }
                dropdownList={navBarDropDownMenu}
              />
            )}
          </div>
          {modalShow && (
            <div id="my_modal_1">
              <div className="modal-box rounded-[15px] lg:max-w-[750px] bg-primary-content">
                <WalletConnect
                  onWalletConect={(addr) => getCustomerDetails(addr)}
                  onWalletClose={() => setModalShow(false)}
                />
              </div>
            </div>
          )}
        </div>
        <Sidebar selectedItem={null} />
      </div>
    </div>
  );
}
const connectStateToProps = ({ auth }) => {
  return { auth: auth };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(HeaderNavbar);
