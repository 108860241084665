import React from 'react';
import LeftSection from './leftSection';
import RightSection from "./rightSection"
import { useParams } from 'react-router-dom';

const Video = () => {
  return (
    <div className="max-sm:px-3 md:mt-5 px-4">
      <div className='md:grid grid-cols-12 lg:gap-6'>
        <div className='col-span-12 lg:col-span-8'>
          <LeftSection  />
        </div>
        <div className='col-span-12 lg:col-span-4 max-sm:mt-4'>
          <RightSection />
        </div>
      </div>
    </div>

  );
}
export default Video;
