import React from "react";
import Button from "../../../../ui/Button";
import Select from "react-select";
import { getOption } from "./utils";
import Spinner from "../../../loaders/spinner";

const Form = ({
  formErrors,
  form,
  setField,
  lookups,
  handleUpload,
  isSaving,
}) => {
  const handleBlur = (field: string, value: any) => {
    setField(field, value.trim().replace(/\s+/g, " "));
  };
  return (
    <div className="videoupload-files">
      <div className="mt-4">
        <label
          htmlFor=""
          className="text-dark text-sm font-normal p-0 mb-2 label ml-4 star"
        >
          Title
        </label>
        <input
          className="input input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-4 h-9"
          type="text"
          placeholder="Title"
          value={form.title}
          onChange={(e) => setField("title", e.target.value)}
          onBlur={(e) => handleBlur("title", e.target.value)}
        />
        <p className="text-sm font-normal text-red-600 ml-4">
          {formErrors.title}
        </p>
      </div>
      <div className="mt-4">
        <label
          htmlFor=""
          className="text-dark text-sm font-normal p-0 mb-2 label ml-4 star"
        >
          Category
        </label>
        <Select
          options={lookups?.categories}
          classNames={{
            control: (state) =>
              state.isFocused
                ? "border-red-600 !rounded-[50px] h-9 border-blue pl-[6px]"
                : "border-grey-300 !rounded-[50px] h-9 pl-[6px]",
          }}
          onChange={(value) =>
            setField("category", value?.["category"] || value)
          }
          getOptionValue={(option) => getOption(option, "category")}
          getOptionLabel={(option) => getOption(option, "category")}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#f1f1f1",
              primary: "#D60000",
              dangerLight: "red",
            },
          })}
        />
        <p className="text-sm font-normal text-red-600 ml-4">
          {formErrors.category}
        </p>
      </div>
      <div className="mt-4">
        <label
          htmlFor=""
          className="text-dark text-sm font-normal p-0 mb-2 label ml-4 star"
        >
          Sub-category
        </label>
        <Select
          options={lookups?.subCategories}
          isMulti={true}
          classNames={{
            control: (state) =>
              state.isFocused
                ? "border-red-600 !rounded-[50px] min-h-9 border-blue pl-[6px]"
                : "border-grey-300 !rounded-[50px] min-h-9 pl-[6px]",
          }}
          onChange={(value) =>
            setField(
              "subCategory",
              value.map((option) => option?.["subCategory"] || option)
            )
          }
          getOptionValue={(option) => getOption(option, "subCategory")}
          getOptionLabel={(option) => getOption(option, "subCategory")}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#f1f1f1",
              primary: "#D60000",
              dangerLight: "red",
            },
          })}
        />
        <p className="text-sm font-normal text-red-600 ml-4">
          {formErrors.subCategory}
        </p>
      </div>
      <div className="mt-4">
        <label
          htmlFor=""
          className="text-dark text-sm font-normal p-0 mb-2 label ml-4 star"
        >
          Collection
        </label>
        <div className="relative ">
          <Select
            options={lookups?.collections}
            classNames={{
              control: (state) =>
                state.isFocused ? "border-red-600 h-9 border-blue pl-[6px]" : "border-grey-300 h-9 pl-[6px]",
            }}
            onChange={(value) =>
              setField("collection", value?.["collectionName"] || value)
            }
            getOptionValue={(option) => getOption(option, "collectionName")}
            getOptionLabel={(option) => getOption(option, "collectionName")}
            theme={(theme) => ({
              ...theme,
              borderRadius: 50,
              colors: {
                ...theme.colors,
                primary25: "#f1f1f1",
                primary: "#D60000",
                dangerLight: "red",
              },
            })}
          />
        </div>
        <p className="text-sm font-normal text-red-600 ml-4">
          {formErrors.collection}
        </p>
      </div>
      <div className="mt-4">
        <label
          htmlFor=""
          className="text-dark text-sm font-normal p-0 mb-2 label ml-4 star"
        >
          Languages
        </label>
        <Select
          options={lookups?.languages}
          isMulti={true}
          classNames={{
            control: (state) =>
              state.isFocused
                ? "border-red-600 !rounded-[50px] border-blue pl-[6px]"
                : "border-grey-300 !rounded-[50px] pl-[6px]",
          }}
          onChange={(value) =>
            setField(
              "language",
              value.map((option) => option?.["subCategory"] || option)
            )
          }
          getOptionValue={(option) => getOption(option, "subCategory")}
          getOptionLabel={(option) => getOption(option, "subCategory")}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: "#f1f1f1",
              primary: "#D60000",
              dangerLight: "red",
            },
          })}
        />
        <p className="text-sm font-normal text-red-600 ml-4">
          {formErrors.language}
        </p>
      </div>
      <div className="mt-4">
        <div className="flex justify-between items-center px-4">
          <label
            htmlFor=""
            className="text-dark text-sm font-normal p-0 mb-2 label star"
          >
            Description
          </label>
          <span>0/20,000</span>
        </div>
        <textarea
          className="textarea textarea-bordered w-full resize-none leading-4 rounded-[28px] pl-5 pt-3 focus:outline-none"
          rows={5}
          placeholder="Description"
          name="summary"
          value={form.description}
          onChange={(e) => setField("description", e.target.value)}
          onBlur={(e) => handleBlur("title", e.target.value)}
        />
        <p className="text-sm font-normal text-red-600 ml-4">
          {formErrors.description}
        </p>
      </div>
      <div className="mt-4">
        <label
          htmlFor=""
          className="text-dark text-sm font-normal p-0 mb-2 label ml-4 star"
        >
          Video Type
        </label>
        <div className="flex gap-2 items-center w-full rounded-[28px] border-[#A5A5A5] border px-4 py-2 h-10 mt-1">
          <label
            htmlFor="freeVideo"
            className="cursor-pointer relative inline-block mt-1"
          >
            <span>
              <input
                id="freeVideo"
                className="checkbox checkbox-error opacity-0 rounded-[28px]"
                type="checkbox"
                checked={form.isFreeVideo}
                onChange={(e) => setField("isFreeVideo", e.target.checked)}
              />
              <span className=""></span>
            </span>
          </label>
          <span className="text-dark text-sm font-normal p-0 label">
            Free Video
          </span>
          <label
            htmlFor="membershipBasis"
            className="cursor-pointer relative inline-block mt-1"
          >
            <span>
              <input
                id="membershipBasis"
                className="checkbox checkbox-error opacity-0 rounded-[28px]"
                type="checkbox"
                checked={form.onlyMembers}
                onChange={(e) => setField("onlyMembers", e.target.checked)}
              />
              <span className=""></span>
            </span>
          </label>
          <span className="text-dark text-sm font-normal p-0 label">
            Only Members
          </span>
          <label
            htmlFor="buyOrRent"
            className="cursor-pointer relative inline-block mt-1"
          >
            <span>
              <input
                id="buyOrRent"
                className="checkbox checkbox-error opacity-0 rounded-[28px]"
                type="checkbox"
                checked={form.buyOrRent}
                onChange={(e) => setField("buyOrRent", e.target.checked)}
              />
              <span className=""></span>
            </span>
          </label>
          <span className="text-dark text-sm font-normal p-0 label">
            Buy/Rent
          </span>
        </div>
        <p className="text-sm font-normal text-red-600 ml-4">
          {formErrors.videoType}
        </p>
      </div>

      {form.buyOrRent && (
        <div className="mt-4">
          <label
            htmlFor=""
            className="text-dark text-sm font-normal p-0 mb-2 label ml-4 star"
          >
            Video Price (Matic)
          </label>
          <input
            className="input text-sm input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-5"
            type="text"
            placeholder="Video Price"
            name="price"
            value={form.price}
            maxLength={20}
            onChange={(e) => setField("price", e.target.value)}
          />
          <p className="text-sm font-normal text-red-600 ml-4">
            {formErrors.price}
          </p>
        </div>
      )}
      <div className="mt-4">
        <label
          htmlFor=""
          className="text-dark text-sm font-normal p-0 mb-2 label ml-4"
        >
          Royalty (%)
        </label>
        <input
          className="input text-sm input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-5 h-9"
          type="text"
          placeholder="Royalty (%)"
          name="royality"
          value={form.royality}
          min={0}
          maxLength={6}
          onChange={(e) => setField("royality", e.target.value)}
        />
        <p className="text-sm font-normal text-red-600 ml-4">
          {formErrors.royality}
        </p>
      </div>

      <div className="mt-14 text-right">
        <Button
          type="primary"
          btnClassName="min-w-[289px]"
          handleClick={() => handleUpload()}
        >
          {isSaving && (
            <span>
              <Spinner />
            </span>
          )}
          {<span>Upload</span>}
        </Button>
      </div>
    </div>
  );
};

export default Form;
