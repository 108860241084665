
import React, { useEffect, useState } from "react";
import Button from "../../../ui/Button";
//import CastAndCrewMember from "../../projects/castandcrewmember";
import NftProducers from "../Video/leftSection/NftProducers";
import success from "../../../assets/images/success.png";
import successgif from '../../../assets/images/reviewsuccess.gif'
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchchannelvideoPlay } from "../../../reducers/channelReducer";
import { store } from "../../../store";
import { POST, PUT } from "../../../api/httpClient";
import RelatedVideos from "../Dashboard/Relatedvideos";
// import successgif from '../../../assets/images/reviewsuccess.gif'


interface GenresCardSliderInterface {
    handleClick?: Function;
    btnClassName?: string;
    children?: any;
    type?: string;
}

const WebseriesLeftpanel = ({ handleClick, btnClassName, children, type  }: GenresCardSliderInterface) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [hideMetamask, setHideMetamask] = useState(true);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const[showseemore,setShowseemore]=useState(false)
    const [isLike, setIsLike] = useState(false);
    // const [unLike, setUnlike] = useState(false);
   
    const toggleShowMore = () => {
      setShowFullDescription(!showFullDescription);
      setShowseemore(true)
    };

  


    const handleMetamaskClick = () => {
        setShowConfirmation(true);
    }
    const hideMetamaskClick = () => {
        setHideMetamask(false);
    }

    const dispatch = useDispatch();
    const playVideo = useSelector((store: any) => store?.channels);
    
    const params = useParams();

    const customerData = useSelector((store: any) => store?.auth)
    const customerId = customerData.user.id;
    const customeraddress = customerData.address;
    useEffect(() => {
        store.dispatch(fetchchannelvideoPlay(params?.id, customerId));
    }, [dispatch]);

    const [subscribed, setSubscribed] = useState({});
    const walletAddress= customerData.address
    const channelId=params.channelId ||""
    const handleSubscribe = async (channelId: any) => {
        const isSubscribed = subscribed[channelId]
        const postData = {
          channelId: channelId,
          customerId: customerId,
          walletAddress: walletAddress,
          subscriptionState: !isSubscribed,
        };
        const response =isSubscribed?await PUT(`updatesubscription`,postData) :await POST(`savesubscription`, postData)
    
        if (response) {
          setSubscribed((prevSubscribeAction) => {
            const updatesubscribeAction = { ...prevSubscribeAction };
            updatesubscribeAction[channelId] = !isSubscribed;
            return updatesubscribeAction;
          });
        }
    
      };
      const description = playVideo?.channelvideoPlay?.data?.description;
      const Likehandler = async () => {
     
        try {
            let data = {
                "customerId": customerId,
                "videoOrCommentId": params?.id,
                "isLiked":isLike
            }
            let res: any;
            if (customerId !== undefined && isLike !== true) {
                res = await POST(`savelikevideo`, data);
                if (res && res.status === 200) {
                    store.dispatch(fetchchannelvideoPlay(params?.id, customerId));
                    setIsLike(true);
                }
            } else {
                res = await PUT(`updatevideolike`, data);
                if (res && res.status === 200) {
                    store.dispatch(fetchchannelvideoPlay(params?.id, customerId));
                    setIsLike(false);
                }
            }
        }
        catch (error) {
        console.error("Error:", error.message);
    }

    };
//     const Unlikehandler = async () => {
//         try {
//             let data = {
//                 "customerId": customerId,
//                 "videoOrCommentId": params?.id,
//                 "isLiked": unLike
//             }
//             let res: any;
//             if (customerId !== undefined && isLike !== true) {
//                 res = await POST(`savelikevideo`, data);
//                 if (res && res.status === 200) {
//                     store.dispatch(fetchchannelvideoPlay(params?.id, customerId));
//                     setUnlike(true);
//                 }
//             } else {
//                 res = await PUT(`updatevideolike`, data);
//                 if (res && res.status === 200) {
//                     store.dispatch(fetchchannelvideoPlay(params?.id, customerId));
//                     setUnlike(false);
//                 }
//             }
//         }
//         catch (error) {
//         console.error("Error:", error.message);
//     }

// }
const videosherehandler = async () => {
    try {
        let data = {
            "videoId": params?.id,
            "customerAddress": customeraddress,
        }
        const res = await POST('savevideoshare', data)
        console.log("shereresponce",res)
        
    } catch (error) {
        console.error("Error:", error.message);     
    }
};
    return (
          
        <div className=" rounded-lg header-shadow relative shadow-md h-full">

            <div className="relative" >
                <video controls  className="rounded-t-lg h-[546px] w-full object-cover"muted>
                    <source src={playVideo?.channelvideoPlay?.data?.videoLink} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
    
                {hideMetamask && <div className="h-full absolute pt-12 md:pt-24 top-0 bg-[#00000040] w-full text-center max-sm:px-4">

                    {showConfirmation ? (<div className="relative">
                        <div>
                            <img src={success} alt="success" className="mx-auto" />
                            <h1 className="text-error-content text-[42px] font-semibold">Confirmation</h1>
                            <img src={successgif} alt="" className='h-[280px] m-[-180px] mx-auto' />
                            <p className="text-error-content mt-20 mb-6">Connect with one of available wallet providers or create a <br /> new wallet.What is wallet?</p>
                            <Button type="primary" btnClassName="h-[52px]" handleClick={hideMetamaskClick}>Confirm to paly with 2 Matic</Button>
                        </div>
                    </div>) : (
                        <div>
                            <div className="text-right pr-4 absolute top-6 right-2">
                                <Link to='/home/All'><span className="icon close-red cursor-pointer scale-[1.3]"></span></Link>
                            </div>
                            <h1 className="text-error-content text-[42px] font-semibold">Connect Your Wallet</h1>
                            <p className="text-error-content mt-3 mb-6">Connect with one of available wallet providers or create a <br /> new wallet.What is wallet?</p>
                            <div className="bg-primary text-error-content py-2.5 px-6 w-[232px] min-h-[56px] flex items-center gap-3.5 mx-auto rounded-[42px] cursor-pointer hover:opacity-80" onChange={handleMetamaskClick}>
                                <span className="icon Metamask"></span><span>Metamask Wallet</span>
                            </div>
                            <div className="bg-primary text-error-content py-2.5 px-6 w-[232px] min-h-[56px] flex items-center gap-3.5 mx-auto mt-4 rounded-[42px] cursor-pointer hover:opacity-80">
                                <span className="icon WalletConnect"></span><span>WalletConnect</span>
                            </div>
                            <p className="mt-8 text-white opacity-80">we do not own private keys and cannot access your <br /> funds without your confirmation</p>
                        </div>
                    )

                    }
                </div>}
            </div>
            <div className="px-3 py-4 flex justify-between items-center">
                <div className="flex gap-2">
                    <img className="h-[40px] w-[40px] rounded-full object-cover" src={playVideo?.channelvideoPlay?.data?.logo} alt="" />
                    <div className="flex-1">
                        <p className="text-neutral text-sm font-normal">{playVideo?.channelvideoPlay?.data?.createdDate}</p>
                        <h2 className="text-base text-secondary font-medium">{playVideo?.channelvideoPlay?.data?.title}</h2>
                        <p className="text-base text-secondary font-medium">{playVideo?.channelvideoPlay?.data?.language}</p>

                    </div>
                </div>
                <div className="">
                    <Button type="cardButton" btnClassName="!h-[32px]" handleClick={() => handleSubscribe(channelId)}>{subscribed[channelId] ? 'Subscribed' : 'Subscribe'}</Button>
                </div>
            </div>

            <div className="flex justify-end gap-2.5 px-3">
                <button className="bg-accent px-4 py-2 rounded-[20px] text-sm font-medium whitespace-nowrap" onClick={Likehandler}> <span className="icon  like-icon mr-2" ></span>Like <span className="text-xs">({playVideo?.channelvideoPlay?.data?.likes})</span></button>
                <button className="bg-accent px-4 py-2 rounded-[20px] text-sm font-medium whitespace-nowrap" onClick={Likehandler}> <span className="icon unlike-icon mr-2" ></span>Unlike<span className="text-xs"> ({playVideo?.channelvideoPlay?.data?.unLikes})</span></button>
                <button className="bg-accent px-4 py-2 rounded-[20px] text-sm font-medium whitespace-nowrap" onClick={videosherehandler}> <span className="icon share mr-2" ></span>Share</button>
               
            </div>
            <hr className="mt-4 mb-[18px]" />
            <div className="px-3">
            <RelatedVideos/>
            </div>
            <hr className="mt-4 mb-[18px]"  />
            <div className="px-3">
           <h1 className="text-lg font-semibold text-secondary mb-2">NFT Producers</h1>
           <NftProducers nft={playVideo?.channelvideoPlay?.data}/>
           </div>
            <hr className="mt-4 mb-2" />
            <div className="px-3 pb-3">
                <h1 className="text-lg font-semibold text-secondary">About the movie</h1>
                <p className="text-base-200">
        {showFullDescription ? description : description?.slice(0, 40)}
      </p>
      {showseemore && description && description.length > 40 && (<button className="text-primary font-semibold" onClick={toggleShowMore}>  {showFullDescription ? "Show less" : "Show more"}</button>)}
            </div>

        </div>

    );
};


export default WebseriesLeftpanel;