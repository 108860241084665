import React, { useEffect, useState } from "react";
import CommentShimmer from "../../loaders/comments";
import { store } from "../../../../store";
import { fetchComments } from "../../../../reducers/commentReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { POST, PUT } from "../../../../api/httpClient";
import Button from "../../../../ui/Button";
import { setError, setToaster } from "../../../../reducers/layoutReducer";
import defaultProfile from "../../../../assets/images/default-avatar.jpg";
import apiCalls from "../../../../utils/api";
import Spinner from "../../../loaders/spinner";
import NoData from "../../../../ui/noData";
import { auto_grow } from "../../../../utils/autoGrowInput";
import Moment from "react-moment";
const CommentsComponent = () => {
  const [replyToggles, setReplyToggles] = useState({});
  const [postComment, setPostcomment] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [saveCommentreply, setSavecommentreply] = useState({
    replyComment: "",
  });
  const ReplayOpen = (commentId) => {
    setReplyToggles({ ...replyToggles, [commentId]: !replyToggles[commentId] });
  };
  const getcommentList = useSelector((store: any) => store?.getcomment);
  const user = useSelector((store: any) => store?.auth?.user);
  const customerId = user?.id;
  const walletAddress = user.walletAddress;
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (customerId) {
      store.dispatch(fetchComments(params?.videoId, customerId));
    }
  }, [customerId]);
  const handleCommentSave = async () => {
    if (!postComment) {
      dispatch(setError({ message: "Please type your comment!" }));
      return;
    }
    setIsSaving(true);
    try {
      let data = {
        videoId: params?.videoId,
        customerAddress: walletAddress,
        comment: postComment,
      };
      const res = await apiCalls.postVideoComment(data);
      if (res.status === 200) {
        dispatch(setToaster({ message: "Comment posted successfully!" }));
        store.dispatch(fetchComments(params?.videoId, customerId));
        setPostcomment("");
      } else {
        dispatch(setError({ message: res }));
        setPostcomment(data.comment);
      }
    } catch (error) {
      dispatch(setError({ message: error }));
    } finally {
      setIsSaving(false);
    }
  };
  function Addcommenthandler(e: any) {
    auto_grow(e.target)
    setPostcomment(e.target.value);
  }

  const handleReplySubmit = async (items: any) => {
    try {
      let data = {
        commentId: items.id,
        walletAddress: walletAddress,
        replyComment: saveCommentreply.replyComment,
      };
      const res = await POST("commentreply", data);

      setSavecommentreply({ ...saveCommentreply, replyComment: "" });

      setSavecommentreply(res.data);
      if (res.data) {
        store.dispatch(fetchComments(params?.videoId, customerId));
      }
    } catch (error) {
      dispatch(setError({ message: error }));
    }
  };
  const Replycommenttext = (e: any) => {
    setSavecommentreply({
      ...saveCommentreply,
      replyComment: e.target.value,
    });
  };

  const Commentlikedata = async (items: any) => {
    try {
      let data = {
        customerId: customerId,
        videoOrCommentId: items.id,
      };
      let res: any;
      if (items.id !== undefined && isLike === false) {
        res = await POST(`savecommentlike`, data);
        if (res && res.status === 200) {
          store.dispatch(fetchComments(params?.videoId, customerId));
          setIsLike(true);
        }
      } else {
        res = await PUT(`updatecommentlike`, data);
        if (res && res.status === 200) {
          store.dispatch(fetchComments(params?.videoId, customerId));
          setIsLike(false);
        }
      }
    } catch (error) {
      console.log("Error:", error.message);
    }
  };
  if (getcommentList.error)
    dispatch(setError({ message: getcommentList.error }));
  return (
    <>
      {getcommentList.isLoading && (
        <div className="px-3 mb-5">
          <CommentShimmer />
        </div>
      )}
      {!getcommentList.isLoading &&
        getcommentList.data &&
        getcommentList.data?.length > 0 &&
        getcommentList?.data?.map((items: any) => (
          <div className="flex gap-4 px-3 mb-5" key={items.id}>
            <div className="avatar shrink-0">
              <div className="w-12 h-12 rounded-full shrink-0">
                <img src={items.profilePic || defaultProfile} alt="" />
              </div>
            </div>
            <div className="break-all">
              <p className=" break-words">
                <span className="text-base font-semibold text-secondary">
                  {items.name}
                </span>
                <span>
                  {" "}
                  <Moment local={true} fromNow >{items.createdDate}</Moment>
                </span>
              </p>
              <p
                className={`mt-1 mb-4 text-secondary opacity-70 text-sm leading-4`}
              >
                {items.comment}
              </p>
              <div className="flex justify-between">
                <div className="flex items-center">
                  <button
                    className="font-semibold mr-4 md:mr-9 cursor-pointer text-secondary"
                    onClick={() => ReplayOpen(items.id)}
                  >
                    Reply
                  </button>
                  <span className="font-semibold  mr-4 You Liked md:mr-9 text-secondary">
                    {items.isLikedComment}Like
                  </span>
                </div>
                <span className="flex items-center gap-1">
                  <button
                    className={`icon cursor-pointer ${
                      items === true ? "like-active" : "like"
                    }`}
                    onClick={() => Commentlikedata(items)}
                  ></button>
                  <span className="font-semibold text-secondary text-sm">
                    {items.likes} Likes
                  </span>
                </span>
              </div>

              {replyToggles[items.id] && (
                <div className="mt-3">
                  <div className="flex gap-3">
                    <p
                      className={`mt-2 mb-3 text-secondary opacity-70 text-sm leading-1`}
                    >
                      {items.reply && items.reply.reply}
                    </p>
                    <div className="w-9 h-9 rounded-full shrink-0">
                      <img
                        src={items.reply.profilePic || defaultProfile}
                        alt=""
                        className="object-cover rounded-full h-9 w-9"
                      />
                    </div>

                    <input
                      type="text"
                      placeholder="Type here"
                      className="input w-full h-9 outline-none focus:outline-none border-1 border-neutral-content focus:border-neutral-content rounded-[30px] text-base font-normal"
                      onChange={(e) => Replycommenttext(e)}
                    />
                  </div>
                  <div className="flex gap-2 items-center mt-[10px]">
                    <Button
                      type="reply"
                      handleClick={() => handleReplySubmit(items)}
                    >
                      Reply
                    </Button>
                    <Button
                      type="replyCancel"
                      handleClick={() => ReplayOpen(items.id)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}

      {!getcommentList.isLoading &&
        getcommentList.data &&
        getcommentList.data?.length === 0 && <NoData text="No Comments Yet!" />}
      <div className="relative mt-5 h-auto mb-[-8px]">
        <textarea
          placeholder={
            !getcommentList.isLoading &&
            getcommentList.data &&
            getcommentList.data?.length === 0
              ? "Be the first to comment!"
              : "Share your thoughts on this video!"
          }
          className="input w-full  outline-none focus:outline-none border-0 input-transparent rounded-none text-lg font-normal resize-none overflow-y-hidden py-2 pr-8"
          value={postComment}
          onChange={Addcommenthandler}
        />
        <button
          type="button"
          className="bottom-4 right-2 absolute"
          onClick={handleCommentSave}
          disabled={isSaving}
        >
          {!isSaving && (
            <span className="icon arrow-comment transform rotate-180"></span>
          )}
          {isSaving && (
            <span>
              <Spinner />
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default CommentsComponent;
