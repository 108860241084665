import React, { useEffect } from "react";
import Header from "../components/streaming/Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import FooterComponent from "../components/layouts/Default/FooterComponent";
import ErrorMessage from "../ui/ErrorMessage";
import ToasterMessage from "../ui/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { setError, setToaster } from "../reducers/layoutReducer";
const AppLayout = () => {
  const { pathname } = useLocation();
  const rootDispatch = useDispatch();
  const { error, toaster, sidebarDetails } = useSelector((store: any) => ({
    error: store.layoutReducer.error,
    toaster: store.layoutReducer.toaster,
    sidebarDetails: store.layoutReducer.sidebar,
  }));
  useEffect(() => {
    error?.message && rootDispatch(setError({ message: "" }));
    toaster?.message && rootDispatch(setToaster({ message: "" }));
    window.scrollTo(0,0)
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="sticky top-0 z-[99] bg-success-content">
        {" "}
        <Header />
      </div>
      <div className="">
        {error?.message && (
          <ErrorMessage
            errorMessage={error?.message}
            setErrorMessage={() => rootDispatch(setError({ message: "" }))}
            onCloseCallback={error?.onCloseCallback}
          />
        )}
        {toaster?.message && (
          <ToasterMessage
            message={toaster?.message}
            closeToaster={() => rootDispatch(setToaster({ message: "" }))}
            timeout={toaster?.timeout}
            type={toaster?.type}
            position={toaster?.position}
            callback={toaster?.callback}
            callbackTimeout={toaster?.callbackTimeout}
          />
        )}
        <div id="main" className={`${sidebarDetails.outletClass}`}>
          <Outlet />
          <FooterComponent />
        </div>
      </div>
    </>
  );
};

export default AppLayout;
