import React, { useEffect, useRef, useState } from "react";
import Button from "../../../ui/Button";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../../../store";
import WithMutlipleItems from "../../../ui/carousals/withMutlipleItems";
import PaginationComponent from "../../../ui/paginationComponent";
import {
  clearChannelsData,
  fetchAllChannelsData,
} from "../../../reducers/channelReducer";
import ScrollingComponent from "../../../ui/scrollingComponent";

interface MoviesCardInterface {
  handleClick?: Function;
  btnClassName?: string;
  children?: any;
  type?: string;
}
const take = 20;
const TopChannels = ({ handleClick, children, type }: MoviesCardInterface) => {
  const dashbaordChannels = useSelector(
    (store: any) => store?.channels?.allChannelsData?.All
  );

  useEffect(() => {
    store.dispatch(
      fetchAllChannelsData({
        category: "All",
        take: take,
        page: 1,
        search: null,
        data: null,
      })
    );
    return () => {
      clearChannelsData("all");
    };
  }, []);
  const fetchMoreChannels = (category: string, data: any, page: number) => {
    store.dispatch(
      fetchAllChannelsData({
        category: category,
        take: take,
        page: page,
        search: null,
        data: null,
      })
    );
  };
  return (
    <ScrollingComponent
      toDisplay="channel"
      data={dashbaordChannels?.data}
      title={"Top Channels"}
      isLoading={dashbaordChannels?.loading}
    />
  );
};

export default TopChannels;
