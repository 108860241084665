import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PaginationComponent from '../../../ui/paginationComponent';
import { setError } from '../../../reducers/layoutReducer';
import rootReducer from '../../../reducers/rootReducer';
import { store } from '../../../store';
import { fetchAllTrendingVideos, fetchCategoryRelatedTrendingVideos } from '../../../reducers/dashboardReducer';
const take = 10;
const FilteredTrendingVideos = () => {
    const location = useLocation();
    const { filterBy } = useParams();
    const rootDispatch=useDispatch();
    const trendingVideos = useSelector((store: any) => store?.dashboard?.CategoriziedTrendingVideos)

      useEffect(() => {
        // store.dispatch(fetchrecommendedChannels(customerId))
        if (location.pathname.includes("/channels")) {
          store.dispatch(
            fetchCategoryRelatedTrendingVideos({
              category: filterBy?.toLowerCase(),
              take: take,
              page: 1,
              search: null,
            })
          );
        }
      }, [filterBy]);
      useEffect(() => {
        store.dispatch(rootReducer);
      }, []);
    
      const fetchMoreTrending = (category: string, data: any, page: number) => {
        store.dispatch(
            fetchAllTrendingVideos({
            category: category,
            take: take,
            page: page,
            search: null,
            data: null,
          })
        );
      };
    
      if(trendingVideos?.error){
        rootDispatch(setError({ message: trendingVideos?.error }))
      }

      return (
        <div className="px-4 mt-4">
          <div>
            <PaginationComponent
              toDisplay="channel"
              data={trendingVideos?.data}
              title={filterBy}
              isLoading={trendingVideos?.loading}
              itemsPerPage={take}
              nextPage={trendingVideos?.nextPage}
              onPageChange={(data: any, page: number) =>
                fetchMoreTrending(filterBy, data, page)
              }
              displayNoData={true}
            />
          </div>
        </div>
      );
}

export default FilteredTrendingVideos