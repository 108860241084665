import React, { useEffect, useState } from "react";
import Button from "../../../../ui/Button";
import { useSelector } from "react-redux";
import { fetchMyChannels } from "../../../../reducers/channelReducer";
import { store } from "../../../../store";
import MyChannelsView from "./view";
import CreateChannel from "./create";
import videogif from "../../../../assets/images/video.gif";
import ChannelShimmer from "../../loaders/channel";
const MyChannels = () => {
  const user = useSelector((store: any) => store?.auth.user);
  const channelList = useSelector((store: any) => store?.channels.myChannels);
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (user.id) {
      store.dispatch(
        fetchMyChannels({
          id: user.id,
          data: null,
          page: 1,
          take: 15,
        })
      );
    }
  }, [user]);
  const handleDrawerActions = (payload) => {
    setIsChecked(payload);
  };
  return (
    <div className="px-4 mt-4">
      <div>
        <div className="flex items-center justify-between mb-5">
          <h3 className="text-xl font-medium text-secondary">My Channels</h3>
          {channelList?.data?.length > 0 && (
            <Button
              type="primary"
              handleClick={() => handleDrawerActions(true)}
            >
              Create New Channel
            </Button>
          )}
        </div>
        <MyChannelsView channelList={channelList} />
      </div>
      {channelList?.isLoading && (
        <div className="w-full grid grid-cols-5 gap-2">
          {Array.from({ length: 10 }, (_, index) => {
            return (
              <div key={index}>
                <ChannelShimmer />
              </div>
            );
          })}
        </div>
      )}

      {channelList?.data?.length === 0 && (
        <div className="mt-16">
          <img src={videogif} alt="" className="mx-auto w-[90px]" />
          <p className="text-secondary mt-2 mb-4 text-base font-semibold text-center">
            Create your own channel
          </p>
          <p className="text-secondary opacity-[0.6] mb-0 text-sm font-normal text-center">
            Easily create your channel from anywhere.
          </p>
          <p className="text-secondary opacity-[0.6] mt-0 mb-0 text-sm font-normal text-center">
            All content you create will be prominently displayed here.
          </p>
          <div className="text-center mt-6 ">
            <Button
              type="primary"
              handleClick={() => handleDrawerActions(true)}
            >
              Create
            </Button>
          </div>
        </div>
      )}
      {isChecked && (
        <CreateChannel
          shouldOpenDrawer={isChecked}
          handleDrawerActions={handleDrawerActions}
          user={user}
        />
      )}
    </div>
  );
};

export default MyChannels;
