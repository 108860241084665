import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { store } from "../../../store";
import {
  clearChannelsData,
  fetchAllChannelsData,
} from "../../../reducers/channelReducer";
import PaginationComponent from "../../../ui/paginationComponent";
import ScrollingComponent from "../../../ui/scrollingComponent";
const take = 20;
const ChannelsByCategory = ({ category, key }) => {
  const location = useLocation();
  const channelsByCategory = useSelector(
    (store: any) => store?.channels?.allChannelsData?.[category]
  );
  useEffect(() => {
    if (location.pathname.includes("/channels")) {
      store.dispatch(
        fetchAllChannelsData({
          category: category,
          take: take,
          page: 1,
          search: null,
          data: null,
        })
      );
    }
    return () => {
      clearChannelsData(category);
    };
  }, []);
  return (
    <div className="px-4 mt-4" key={key}>
      <div>
        {/* <PaginationComponent
          toDisplay="channel"
          data={channelsByCategory?.data}
          title={category === "All" ? "Top Channels" : category}
          isLoading={channelsByCategory?.loading}
          itemsPerPage={take}
          nextPage={channelsByCategory?.nextPage}
          onPageChange={(data: any, page: number) =>
            fetchMoreChannels(category, data, page)
          }
        /> */}
        <ScrollingComponent
          toDisplay="channel"
          data={channelsByCategory?.data}
          title={category === "All" ? "Top Channels" : category}
          isLoading={channelsByCategory?.loading}
          key={key}
        />
      </div>
      {(channelsByCategory?.data?.length > 0 ||
        channelsByCategory?.loading) && <hr className="mt-[30px]" />}
    </div>
  );
};

export default ChannelsByCategory;
