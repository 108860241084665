import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { store } from '../../../store';
import { clearMoviesData, getAllMenuMoviesData } from '../../../reducers/dashboardReducer';
import ScrollingComponent from '../../../ui/scrollingComponent';
const take = 10;
const MoviesByCategory = ({category,key}) => {
  const {filterBy}= useParams()

  const categoryWiseMoviesData = useSelector(
    (store: any) => store?.dashboard?.leftMenuMoviesData?.[category]
  );
useEffect(()=>{
  store.dispatch(
    getAllMenuMoviesData({
      type: "movies",
      category: category,
      take: take,
      page: 1,
      search: null,
      data: null,
    })
  )
  return () => {
    clearMoviesData(category);
  };
}, [filterBy])


  return (
    <div className="px-4 mt-4" key={key}>
      <div className="overflow-x-auto no-scrollbar gap-[50px] pb-3">
        <ScrollingComponent
          toDisplay="video"
          data={categoryWiseMoviesData?.data}
          title={category === "All" ? "Top Channels" : category}
          isLoading={categoryWiseMoviesData?.loading}
          key={key}
        />
        {(categoryWiseMoviesData?.data?.length > 0 ||
          categoryWiseMoviesData?.loading) && <hr className="mt-[30px]" />}


      </div>

    </div>
  )
}

export default MoviesByCategory