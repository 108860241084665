import React from 'react'
import { useSelector } from 'react-redux';
import MoviesByCategory from './categorizedMovies';
import movies from '../../../assets/images/movies.png';

const MoviesHomePage = () => {
    const  categoryData = useSelector(
        (store: any) => store?.dashboard?.allCategories?.data
      );
  return (

    <div>
      <div className='flex items-center gap-5 mt-6 px-4'>
        <img src={movies} className="w-[150px] h-[150px] rounded-full" alt="" />
        <div>
          <h2 className='text-secondary text-lg font-medium'>Movies</h2>
          <p className='text-scondary'>Watch the latest and greatest hits</p>
        </div>
      </div>
      <MoviesByCategory category={'All'} key={'All'}/>
        { categoryData?.map(category=><MoviesByCategory category={category?.subCategory} key={category?.subCategory}/>) }
    </div>
  )
}

export default MoviesHomePage