import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { store } from "../../../../store";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../ui/Button";
import Explore from "./explore";
const Sidebar = () => {
  const router = useNavigate();
  const { pathname } = useLocation();
  const sidebarDetails: any = useSelector(
    (store: any) => store.layoutReducer.sidebar
  );
  const handleLinkClick = async (
    e: any,
    path: string,
    callBack?: () => void
  ) => {
    e.preventDefault();
    router(path);
    callBack?.();
  };
  const sidebarMenu = useMemo(() => {
    return [
      {
        name: "Home",
        icon: "home",
        basePath: "home",
        action: (e: any) => handleLinkClick(e, "/home/All"),
      },
      {
        name: "Trending",
        icon: "trending",
        basePath: "/trending",
        action: (e: any) => handleLinkClick(e, "/trending/All"),
      },
      {
        name: "Channels",
        icon: "channels",
        basePath: "/channels",
        action: (e: any) =>
          handleLinkClick(e, "/channels/All"),
      },
      {
        name: "Movies",
        icon: "movies",
        basePath: "/movies",
        action: (e: any) =>
          handleLinkClick(e, "/movies/All"),
      },
      {
        name: "Web Series",
        icon: "web-series",
        basePath: "/webseries",
        action: (e: any) =>
          handleLinkClick(e, "/webseries/All"),
      },
    ];
  }, []);
  return (
    <div>
      <div
        id="mySidebar"
        className={`sidebar no-scrollbar ${sidebarDetails.sidebarClass}`}
      >
        <ul className="px-4 mt-4 ">
          {sidebarMenu.map((menuItem: any) => {
            return (
              <Button type="plain" handleClick={menuItem.action} btnClassName="block w-full text-start">
              <li
                className={`mb-2 px-2.5 py-2 rounded-[30px] hover:bg-[#F0F0F0] cursor-pointer text-base text-secondary
                ${
                  pathname.includes(menuItem.basePath)
                    ? "bg-[#F0F0F0]"
                    : "text-black"
                }
                `}
                key={menuItem.name}
              >
               
                  <span className={`icon ${menuItem.icon} mr-[18px]
                  ${
                    pathname.includes(menuItem.basePath)
                      ? "active-icon"
                      : ""
                  }
                  `}></span>
                  <span
                    className={`align-middle ${
                      pathname.includes(menuItem.basePath)
                        ? "text-black"
                        : "text-black"
                    }`}
                  >
                    {menuItem.name}
                  </span>
               
              </li> </Button>
            );
          })}
        </ul>
        <hr className="mt-2 mb-6" />
       <Explore/>
      </div>
    </div>
  );
};

export default Sidebar;
