import React from 'react'
import { useParams } from 'react-router-dom'
import WebseriesHome from './webseriesHome';
import FilteredWebseries from './filteredWebseries';

 const WebSeries = () => {
    const {filterBy} = useParams();

  return (
    <div>
        {filterBy ==='All' && <WebseriesHome/> }
        {filterBy !=='All'&& <FilteredWebseries/> }
      </div>
  )
}
export default WebSeries
