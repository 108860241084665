import React from 'react';
import trending from '../../../assets/images/trending.png';
import { useParams } from 'react-router-dom';
import TrendingHomePage from './trendingHome';
import FilteredTrendingVideos from './filteredTrendingVideos';

const Trending = () => {
  const {filterBy} = useParams();

  return (
    <div>
      <div className="px-4">
        <div className='flex items-center gap-5 mt-6'>
          <img src={trending} alt="" />
          <div>
            <h2 className='text-secondary text-lg font-medium'>Trending</h2>
            <p className='text-scondary'>Watch the latest and greatest hits</p>
          </div>
        </div>
      </div>
    {filterBy ==='All' &&<TrendingHomePage/>}
    {filterBy !=='All'&& <FilteredTrendingVideos/> }
  </div>
   
  );
};

export default Trending;
