import React, { useEffect, useState } from 'react';
// import Image from 'next/legacy/image';
import Image from 'react-bootstrap/Image';
import Button from '../../../../ui/Button';
import { POST, PUT } from '../../../../api/httpClient';
import { useSelector } from 'react-redux';
import rootReducer from '../../../../reducers/rootReducer';
import { store } from '../../../../store';
import { useParams } from 'react-router-dom';
const WriteReview = ({ setActiveTab,cid }) => {
    const [isToggle, setIsToggle] = useState(false);

    const ReplayOpen = () => {
        setIsToggle(!isToggle);
    }
    const [rating, setRating] = useState(0)
    const handleRatingChange = (value: any) => {
        setRating(value);
    }
    
    const params=useParams()

    const [name, setName] = useState('');
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("")

    const customerDetails = useSelector((store: any) => store?.auth)
    useEffect(() => { store.dispatch(rootReducer) }, [])
    const videoOrChannelId=cid||params.id
    const handleSubmit = async (videoOrChannelId:any) => {
        const postData = {
            videoOrChannelId: videoOrChannelId,
            customerAddress: customerDetails.address,
            rating: rating
        };
        const response =rating? await PUT(`updaterating`,postData):await POST(`saverating`,postData)
        if(response){
                setActiveTab(2)
        }
    }
    
    return (
        <>
            <div className="flex justify-between">
                <div>
                    <h1 className='text-2xl font-semibold text-secondary mb-1'>Write a review</h1>
                    <div className="rating rating-md rating-half mr-2 ml-1"  >
                        <input type="radio" name="rating-10" className="bg-[#F7B704] mask mask-star-2 mask-half-1" value="0.5" onChange={(e) => handleRatingChange(e.target.value)} />
                        <input type="radio" name="rating-10" className="bg-[#F7B704] mask mask-star-2 mask-half-2" value="1" onChange={(e) => handleRatingChange(e.target.value)} />
                        <input type="radio" name="rating-10" className="bg-[#F7B704] mask mask-star-2 mask-half-1" value="1.5" onChange={(e) => handleRatingChange(e.target.value)} />
                        <input type="radio" name="rating-10" className="bg-[#F7B704] mask mask-star-2 mask-half-2" value="2" onChange={(e) => handleRatingChange(e.target.value)} />
                        <input type="radio" name="rating-10" className="bg-[#F7B704] mask mask-star-2 mask-half-1" value="2.5" onChange={(e) => handleRatingChange(e.target.value)} />
                        <input type="radio" name="rating-10" className="bg-[#F7B704] mask mask-star-2 mask-half-2" value="3" onChange={(e) => handleRatingChange(e.target.value)} />
                        <input type="radio" name="rating-10" className="bg-[#F7B704] mask mask-star-2 mask-half-1" value="3.5" onChange={(e) => handleRatingChange(e.target.value)} />
                        <input type="radio" name="rating-10" className="bg-[#F7B704] mask mask-star-2 mask-half-2" value="4" onChange={(e) => handleRatingChange(e.target.value)} />
                        <input type="radio" name="rating-10" className="bg-[#F7B704] mask mask-star-2 mask-half-1" value="4.5" onChange={(e) => handleRatingChange(e.target.value)} />
                        <input type="radio" name="rating-10" className="bg-[#F7B704] mask mask-star-2 mask-half-2" value="5" onChange={(e) => handleRatingChange(e.target.value)} />
                    </div>

                </div>
                <span className='icon closeIcon'></span>
            </div>
            <div className="mt-4" >
                <label className='text-dark text-sm font-normal p-0 mb-2 label ml-5'>Name</label>
                <input className='input input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-5'
                    type="text"
                    placeholder="Enter Name"
                    name="proposal"
                    maxLength={250}
                    value={name}
                    onChange={(e) => { setName(e.target.value) }}
                />
                <label className='text-sm font-normal text-red-600 ml-4'></label>
            </div>
            <div className="mt-4" >
                <label className='text-dark text-sm font-normal p-0 mb-2 label ml-5'>Email</label>
                <input className='input input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-5'
                    type="email"
                    placeholder="Enter Email"
                    name="proposal"
                    maxLength={250}
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                />
                <label className='text-sm font-normal text-red-600 ml-4'></label>
            </div>
            <div className="mb-3 mt-4" >
                <div className="flex gap-2 items-center mb-2"><label className='text-dark text-sm font-normal p-0 label ml-5'>Message</label>
                    <span className='text-[#292929] text-xs'>(10 from 999 words)</span></div>
                <textarea
                    className='textarea textarea-bordered w-full rounded-[28px] focus:outline-none pl-5'
                    rows={3}
                    placeholder="Summary"
                    name='summary'
                    value={message}
                    onChange={(e) => { setMessage(e.target.value) }}
                />
                <label className='text-sm font-normal text-red-600 ml-4'></label>
            </div>
            <div className="text-center mt-[38px]">
                <Button children={'Review with 1 MATIC'} btnClassName='md:min-w-[257px]' type='primary' handleClick={() =>handleSubmit(videoOrChannelId)} />
                <p className='text-xs font-semibold mt-2.5 mb-16'>Note: Gas and total fee will debit from your wallet</p>
            </div>
        </>
    );
};
export default WriteReview;
