import React, { useEffect } from 'react'
import { getAllWebseriesData, getCategoryWiseWebseriesData } from '../../../reducers/dashboardReducer';
import rootReducer from '../../../reducers/rootReducer';
import { store } from '../../../store';
import { useParams } from 'react-router-dom';
import PaginationComponent from '../../../ui/paginationComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setError } from '../../../reducers/layoutReducer';
const take =10;

const FilteredWebseries = () => {
    const {filterBy}= useParams()
    const rootDispatch=useDispatch()
    const categoryWiseWebSeriesData = useSelector(
        (store: any) => store?.dashboard?.categoryWiseWebSeriesData
      );

    useEffect(() => {
        store.dispatch(
            getCategoryWiseWebseriesData({
                type: 'webseries',
                category: filterBy,
                take: take,
                page: 1,
                search: null,
                data:null
            })
        );
    }, [filterBy])
    useEffect(() => {
        store.dispatch(rootReducer);
    }, []);
    const fetchMoreWebseries = (category: string, data: any, page: number) => {
        store.dispatch(
            getAllWebseriesData({
                type: 'webseries',
                category: category,
                take: take,
                page: page,
                search: null,
                data: null,
            })
        );
    };

    
if(categoryWiseWebSeriesData?.error){
  rootDispatch(setError({ message: categoryWiseWebSeriesData?.error }))
}
  return (

    <div>
        <PaginationComponent
              toDisplay="webseries"
              data={categoryWiseWebSeriesData?.data}
              title={filterBy}
              isLoading={categoryWiseWebSeriesData?.loading}
              itemsPerPage={take}
              nextPage={categoryWiseWebSeriesData?.nextPage}
              onPageChange={(data: any, page: number) =>
                fetchMoreWebseries(filterBy, data, page)
              }
              displayNoData={true}
            />
    </div>
  )
}

export default FilteredWebseries