import React from 'react'
import noDataImage from '../assets/images/no-data-img.png';
const NoData = ({text="No Data Found"}) => {
  return (
    <div className="mt-4 text-center">
        <div className="text-center">
          <img width={150} src={noDataImage} className="mx-auto" alt='No Data' />
          <p className="mt-4 text-sm font-normal text-secondary opacity-[0.9]">
            {text}
          </p>
        </div>
      </div>
  )
}

export default NoData