import React, { useEffect, useState } from "react";
import Button from "../../../ui/Button";

import { useDispatch, useSelector } from "react-redux";
import { fetchchannelsDetails } from "../../../reducers/channelReducer";
import { store } from "../../../store";
import { Link, useParams } from "react-router-dom";
import NaviLink from "../../../ui/NaviLink";
import videogif from "../../../assets/images/video.gif";
import VideoCard from "../../../ui/cards/video";
import MovieShimmer from "../loaders/movie.card";
import { setError } from "../../../reducers/layoutReducer";
import PaginationComponent from "../../../ui/paginationComponent";
const take = 4;

const Channel = () => {
  const user = useSelector((store: any) => store.auth.user);
  const { filterBy, channelId, channelName, creatorId } = useParams();
  const rootDispatch=useDispatch()
  const [isChecked, setIsChecked] = useState(false);
  const [summary, setSummary] = useState(false);
  const openChannelDrawer = () => {
    setIsChecked(true);
  };
  const closeChannelDrawer = () => {
    setIsChecked(false);
  };
  const handleSummary = () => {
    setSummary(true);
  };
  const handleCancel = () => {
    setIsChecked(false);
  };
  const channelsView = useSelector((store: any) => store?.channels);
  useEffect(() => {
    store.dispatch(
      fetchchannelsDetails({
      channelId:channelId,
      take:take,
      page:1,
      customerId:user?.id,
    })
    )
  }, []);

  const fetchMoreChannels = ( data: any, page: number) => {
    store.dispatch(
      fetchchannelsDetails({
        channelId:channelId,
      take:take,
      page:page,
      customerId:user?.id,
      })
    );
  };
  if(channelsView.channelDetails?.error){
    rootDispatch(setError({ message: channelsView.channelDetails?.error }))
  }
 
  return (
    <div className="px-4 max-sm:px-3">
      <div className="channel-bg bg-no-repeat bg-cover min-h-[320px] bg-center rounded-lg px-4 md:px-[50px] flex items-center mt-4 max-sm:py-4">
        <div className="md:flex gap-12 items-center">
          <img
            src={channelsView.channelDetails.data.logo}
            className="w-[150px] h-[150px] rounded-full object-cover shrink-0"
            alt=""
          />
          <div>
            <div className="flex max-sm:mt-4 items-center">
              <p className="text-white font-semibold text-[32px] leading-8 mr-2">
                {channelsView.channelDetails.data.channelName}
              </p>{" "}
              <span className="icon circle-check shrink-0"></span>
            </div>

            <p className="text-white mt-4 mb-7 text-base">
              {channelsView.channelDetails.data.description}
            </p>
            <div className="flex md:flex-row items-center gap-2.5 flex-col">
              {creatorId !== user.id && (
                <Button type="primary" handleClick={openChannelDrawer}>
                  {channelsView.channelDetails.data.isChannelSubscribed ? (
                    <span><span className="video-icon icon"></span> Subscribed</span>
                  ) : (
                    <span>{channelsView.channelDetails.data.subscriptionFee} <span className="video-icon icon"></span> Subscription Fee</span>
                  )}
                </Button>
              )}
              {creatorId ===user.id && (
          <p className="text-base text-info bg-[#15AB3D] text-white px-[35px] py-2 rounded-[33px]">{"Owned"}</p>
          )}
              <button className="bg-accent px-4 py-2 rounded-[20px] text-sm font-medium whitespace-nowrap">
                {" "}
                <span className="icon share mr-2"></span>Share
              </button>
            </div>
          </div>
        </div>
      </div>
      {isChecked && (
        <div className="drawer drawer-end bg-primary-content sm:w-96 md:w-80 lg:w-96 xl:w-112">
          <input
            id="my-drawer-4"
            type="checkbox"
            className="drawer-toggle"
            checked={isChecked}
          />
          <div className="drawer-content"></div>
          <div className="drawer-side z-[99]">
            <label
              htmlFor="my-drawer-4"
              aria-label="close sidebar"
              className="drawer-overlay"
              onChange={handleCancel}
            ></label>
            <ul className="menu p-4 md:w-80 min-h-full bg-primary-content">
              <div className="text-right">
                <span
                  className="icon close cursor-pointer"
                  onClick={closeChannelDrawer}
                ></span>
              </div>
              <div className="flex justify-between flex-col gap-4 min-h-[600px] pt-20">
                <div className="flex gap-4 justify-center">
                  <div>
                    <div className="flex justify-center">
                      <div className="relative">
                        <img
                          src={channelsView.channelDetails.data.logo}
                          className="w-[150px] h-[150px] rounded-full object-cover"
                          alt=""
                        />
                        <span className="icon channel-check absolute bottom-2 right-3"></span>
                      </div>
                    </div>
                    <div className="mt-6 items-center">
                      <p className="text-secondary font-semibold text-[32px] text-center leading-10">
                        {channelsView.channelDetails.data.channelName}
                      </p>
                    </div>
                    <div className="flex text-[18px] font-medium gap-2.5 justify-center flex-wrap"></div>
                    <p className="text-base text-info leading-6 mt-12 text-center">
                      {channelsView.channelDetails.data.description}{" "}
                    </p>
                  </div>
                </div>

                {summary && (
                  <div className="bg-base-300 px-6 py-8 rounded-[20px] my-20">
                    <div className="flex justify-between items-center">
                      <p className="text-secondary text-base font-semibold">
                        Price
                      </p>
                      <p className="text-secondary text-base font-semibold">
                        0.00001 WATIC
                      </p>
                    </div>
                    <div className="mt-5">
                      <label className="text-dark text-sm font-normal p-0 mb-2 label ml-5">
                        Your Bid *
                      </label>
                      <input
                        className="input input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-5"
                        type="text"
                        placeholder="Proposal Title"
                        name="proposal"
                        maxLength={250}
                      />
                    </div>
                    <div className="mt-5">
                      <label className="text-dark text-sm font-normal p-0 mb-2 label ml-5">
                        Crypto Type
                      </label>
                      <select className="select select-bordered w-full rounded-[28px] border border-[#A5A5A5] focus:outline-none">
                        <option selected>WMATIC</option>
                        <option>Tether</option>
                        <option>USDT</option>
                      </select>
                    </div>
                    <div className="mt-5">
                      <div className="w-full flex justify-between py-3 px-8 rounded-[28px] ">
                        <p className="text-black">Your balance</p>
                        <p className="text-black font-medium">
                          2.3598125 WMATIC
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="md:px-20">
                  <Button
                    type=""
                    btnClassName="!bg-accent text-secondary w-full !text-[18px] drawer-bid"
                    handleClick={handleSummary}
                  >
                    {channelsView.channelDetails.data.subscriptionFee}
                    subscriptionFee
                  </Button>
                  <Button btnClassName="w-full mt-5 !text-[18px]">
                    {channelsView.channelDetails.data.subscriptionToken}
                    subscriptionToken
                  </Button>
                </div>
              </div>
            </ul>
          </div>
        </div>
      )}
      {user?.id===creatorId && 
      <div className="">
        <div className="mt-28">
          <img src={videogif} alt="" className="mx-auto w-[90px]" />
          <p className="text-secondary mt-2 mb-4 text-base font-semibold text-center">
            Create content on any device
          </p>
          <p className="text-secondary opacity-[0.6] mb-0 text-sm font-normal text-center">
            Upload and record at home or on the go.
          </p>
          <p className="text-secondary opacity-[0.6] mt-0 mb-0 text-sm font-normal text-center">
            Everything you make public will appear here.
          </p>
          <div className="text-center mt-6 ">
            <NaviLink
              path={`/channels/${filterBy}/${channelName}/${channelId}/${creatorId}/${channelsView.channelDetails.data.tokenId}/create/video`}
            >
              <Button type="primary">Create</Button>
            </NaviLink>
          </div>
        </div>{" "}
      </div>}
      
      {/* {channelsView.channelDetails?.isLoading ?( [...Array(8*1)].map((_, index) => (
          <div key={index} className="carousel-item shadow-lg md:w-[420px] max-sm:min-w-[340px]">
            <MovieShimmer />
          </div>
        )) ):(  */}
      <div className="carousel-item shadow-lg md:w-[420px] max-sm:min-w-[340px] rounded-lg mt-4">
      <div>
        <PaginationComponent
          toDisplay="video"
          data={channelsView.channelDetails?.data?.channelVideos}
          title={''}
          isLoading={channelsView.channelDetails?.loading}
          itemsPerPage={take}
          nextPage={channelsView.channelDetails?.nextPage}
          onPageChange={(data: any, page: number) =>
            fetchMoreChannels( data, page)
          }
        />
        </div>
        {/* {channelsView.channelDetails.data?.channelVideos?.map((videos: any) => (
          <div
            key={videos.id}
            className=" rounded-lg border header-shadow relative"
          >
            <Link to={`/watch/${videos.id}`}>
              {" "}
              <VideoCard
                  id={videos.id}
                  price={videos.price}
                  videoLink={videos.videoLink}
                  thumbnail={videos.thumbnail}
                  channelLogo={videos.logo}
                  channelName={videos.channelName}
                  videoTitle={videos.title}
                  language={videos.language}
                  views={videos.views}
                  createdDate={videos.createdDate}
                  type={videos.type}
                />
              {/* <video
                controls
                className="rounded-t-lg h-[213px] w-full object-cover"
                muted
              >
                <source src={videos.videoLink} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Link>
            <div className="flex gap-2 p-2">
              <img
                className="h-[40px] w-[40px] rounded-full object-cover"
                src={videos.logo}
                alt=""
              />
              <div className="flex-1">
                <p className="text-neutral text-sm font-normal">
                  {videos.createdDate}
                </p>
                <div className="flex">
                  <p className="text-base text-secondary font-medium">
                    {videos.title}
                  </p>{" "}
                  {" - "}
                  <p className="text-base text-secondary font-medium">
                    {videos.language}
                  </p>
                </div>
                <p className="text-base text-secondary font-medium">
                  {videos.isLikedVideo}
                </p>
                <p className="text-base text-secondary font-medium">
                  {videos.castCrews}castCrews
                </p>
                <p className="text-base text-secondary font-medium">
                  {videos.description}
                </p>
              </div>
            </div> */}
  {/* </Link>
          </div>

        ))} */}

      </div> 
    {/* ) } */}
    </div>
  );
};

export default Channel;
