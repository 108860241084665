import { GET } from "../api/httpClient";
import apiCalls from "../utils/api";
const GET_CHANNELS = "getChannels";
const SET_CHANNELS = "setChannels";
const GET_RECOMMENDEDCHANNELS = "getrecommendedChannels";
const SET_RECOMMENDEDCHANNELS = "setrecommendedChannels";
const GET_CHANNELSDETAILS = "getchannelsDetails";
const SET_CHANNELSDETAILS = "setchannelsDetails";
const GET_CHANNELSRELATIVEVIDEOS = "getchannelsrelativeVideos";
const SET_CHANNELSRELATIVEVIDEOS = "setchannelsrelativeVideos";
const GET_CHANNELVIDEOPLAY = "getchannelVideoplay";
const SET_CHANNELVIDEOPLAY = "setchannelVideoplay";
const SET_MY_CHANNELS = "setMyChannels";
//-----latest changes
const SET_ALL_CHANNELS_DATA = "setAllChannelsData";
const SET_CATEGORIZED_CHANNELS = "setCategorizesChannels";

const setAllChannelsData = (payload) => {
  return {
    type: SET_ALL_CHANNELS_DATA,
    payload,
  };
};
const setCategorizesChannels = (payload) => {
  return {
    type: SET_CATEGORIZED_CHANNELS,
    payload,
  };
};
const setMyChannels = (payload) => {
  return {
    type: SET_MY_CHANNELS,
    payload,
  };
};
export const clearChannelsData = (category: any) => {
  setAllChannelsData({ loading: false, category, data: null, error: "" });
};

const clearCategorizedChannelsData=()=>{
  setCategorizesChannels({loading: false, data: null, error: "",nextPage:1 })
}
export const fetchAllChannelsData = (information) => {
  const { category, take, page, data, search } = information;
  const skip = take * page - take;
  return async (dispatch) => {
    dispatch(setAllChannelsData({ loading: true, category, data, error: "" }));
    try {
      const res = await apiCalls.getAllChannels(
        category.toLowerCase(),
        take,
        skip,
        search
      );
      if (res.status === 200) {
        dispatch(
          setAllChannelsData({
            loading: false,
            category,
            data: data ? [...res.data, ...data] : res.data,
            error: "",
            nextPage: page + 1,
          })
        );
      } else {
        dispatch(
          setAllChannelsData({ loading: false, category, data, error: res })
        );
      }
    } catch (error) {
      dispatch(
        setAllChannelsData({ loading: false, category, data, error: error })
      );
    }
  };
};

export const fetchCategoryRelatedChannelsData = (information) => {
  const { category, take, page, data, search } = information;
  const skip = take * page - take;
  return async (dispatch) => {
    dispatch(
      setCategorizesChannels({
        loading: true,
        data: data,
        error: "",
        nextPage: page,
      })
    );
    try {
      const res = await apiCalls.getCategoryWiseChannelsData(
        category,
        take,
        skip,
        search
      );
      if (res.status === 200) {
        dispatch(
          setCategorizesChannels({
            loading: false,
            data: data ? [...data, ...res.data] : res.data,
            error: "",
            nextPage: page + 1,
          })
        );
      } else {
        dispatch(
          setCategorizesChannels({
            loading: false,
            data: null,
            error: res,
            nextPage: page,
          })
        );
      }
    } catch (error) {
      dispatch(
        setCategorizesChannels({
          loading: false,
          data: data,
          error: error,
          nextPage: page,
        })
      );
    }
  };
};
const fetchchannelsrelativeVideos = (information) => {
  const { take, page, data } = information;
  const skip = take * page - take;
  return async (dispatch) => {
    dispatch(getchannelsrelativeVideos());
    try {
      const { data } = await GET(`relatedvideos/${take}/${skip}`);
      dispatch(
        setchannelsrelativeVideos({
          error: null,
          data: data,
          nextPage: page + 1,
        })
      );
    } catch (err) {
      dispatch(setchannelsrelativeVideos({ data: [], error: err.message }));
    }
  };
};

//--------------------------------
const getChannels = () => {
  return {
    type: GET_CHANNELS,
    payload: null,
  };
};
const setChannels = (data) => {
  return {
    type: SET_CHANNELS,
    payload: data,
  };
};
const getrecommendedChannels = () => {
  return {
    type: GET_RECOMMENDEDCHANNELS,
    payload: null,
  };
};
const setrecommendedChannels = (data) => {
  return {
    type: SET_RECOMMENDEDCHANNELS,
    payload: data,
  };
};
const getchannelsrelativeVideos = () => {
  return {
    type: GET_CHANNELSRELATIVEVIDEOS,
    payload: null,
  };
};
const setchannelsrelativeVideos = (data) => {
  return {
    type: SET_CHANNELSRELATIVEVIDEOS,
    payload: data,
  };
};
const getchannelsDetails = () => {
  return {
    type: GET_CHANNELSDETAILS,
    payload: null,
  };
};
const setchannelsDetails = (data) => {
  return {
    type: SET_CHANNELSDETAILS,
    payload: data,
  };
};
const getchannelVideoplay = () => {
  return {
    type: GET_CHANNELVIDEOPLAY,
    payload: null,
  };
};
const setchannelVideoplay = (data) => {
  return {
    type: SET_CHANNELVIDEOPLAY,
    payload: data,
  };
};
const fetchchannelsDetails = (information) => {
  const { channelId, take, page, search = null, customerId } = information;
  const skip = take * page - take;
  return async (dispatch) => {
    dispatch(getchannelsDetails());
    try {
      const { data } = await GET(
        `topchannelvideos/${channelId}/${take}/${skip}/${search}/${customerId}`
      );
      dispatch(setchannelsDetails({ error: null, data: data }));
    } catch (error) {
      dispatch(setchannelsDetails({ error: error.message, data: [] }));
    }
  };
};

const fetchChannels = (pageSize = 10, page = 0) => {
  return async (dispatch) => {
    dispatch(getChannels());
    try {
      const { data } = await GET(`topchannels/${pageSize}/${page}/null`);
      dispatch(setChannels({ error: null, data: data }));
    } catch (err) {
      dispatch(setChannels({ data: [], error: err.message }));
    }
  };
};
const fetchrecommendedChannels = (customerId: any, pageSize = 10, page = 0) => {
  return async (dispatch) => {
    dispatch(getrecommendedChannels());
    try {
      const { data } = await GET(
        `recommendedchannels/${customerId}/${pageSize}/${page}/null`
      );
      dispatch(setrecommendedChannels({ error: null, data: data }));
    } catch (err) {
      dispatch(setrecommendedChannels({ data: [], error: err.message }));
    }
  };
};

const fetchchannelvideoPlay = (id: any, customerId: any) => {
  return async (dispatch) => {
    dispatch(getchannelVideoplay());
    try {
      const { data } = await GET(`videodetails/${id}/${customerId}`);
      dispatch(setchannelVideoplay({ error: null, data: data }));
    } catch (err) {
      dispatch(setchannelVideoplay({ data: [], error: err.message }));
    }
  };
};
const fetchMyChannels = (information: any) => {
  const { take, page, id, data } = information;
  const skip = take * page - take;
  return async (dispatch) => {
    dispatch(setMyChannels({ isLoading: true, data: data, error: "" }));
    try {
      const res = await apiCalls.getMyChannels(take, skip, id);
      if (res.status === 200) {
        dispatch(
          setMyChannels({
            isLoading: false,
            data: data ? [...data, ...res.data] : res.data,
            error: "",
            nextPage: page + 1,
          })
        );
      } else {
        dispatch(
          setMyChannels({
            isLoading: false,
            data: data,
            error: res,
          })
        );
      }
    } catch (error) {
      dispatch(
        setMyChannels({
          isLoading: false,
          data: data,
          error: error,
        })
      );
    }
  };
};
const initialState = {
  allChannelsData: {},
  categorizedChannels: { data: [], loading: false, error: "", nextPage: 1 },

  //-------------
  isLoading: false,
  data: [],
  error: null,
  channelDetails: {
    isLoading: false,
    data: {},
    error: null,
  },
  channelrelativeVideos: {
    isLoading: false,
    data: [],
    error: null,
    nextPage: 1,
  },
  channelvideoPlay: {
    isLoading: false,
    data: [],
    error: null,
  },
  recommendedchannel: {
    isLoading: false,
    data: [],
    error: null,
  },
  myChannels: {
    isLoading: false,
    data: null,
    error: null,
    nextPage: 1,
  },
};

const channelReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ALL_CHANNELS_DATA:
      state = {
        ...state,
        allChannelsData: {
          ...state.allChannelsData,
          [action.payload.category]: {
            loading: action.payload.loading,
            error: action.payload.error,
            nextPage: action.payload.nextPage
              ? action.payload.nextPage
              : state.allChannelsData?.[action.payload.category]?.nextPage || 1,
            data: action.payload.data,
          },
        },
      };
      return state;
    case SET_CATEGORIZED_CHANNELS:
      state = {
        ...state,
        categorizedChannels: {
          loading: action.payload.loading,
          data: action.payload.data,
          error: action.payload.error,
          nextPage: action.payload.nextPage || 1,
        },
      };
      return state;
    //------------
    case SET_MY_CHANNELS:
      state = {
        ...state,
        myChannels: {
          isLoading: action.payload.isLoading,
          data: action.payload.data,
          error: action.payload.error,
          nextPage: action.payload.nextPage || state.myChannels.nextPage,
        },
      };
      return state;
    case GET_CHANNELS:
      state = { ...state, isLoading: true };
      return state;
    case SET_CHANNELS:
      state = {
        ...state,
        isLoading: false,
        data: action.payload?.data,
        error: action.payload?.error,
      };
      return state;
    case GET_CHANNELSDETAILS:
      state = {
        ...state,
        channelDetails: {
          ...state.channelDetails,
          isLoading: true,
          data: {},
          error: null,
        },
      };
      return state;
    case SET_CHANNELSDETAILS:
      state = {
        ...state,
        channelDetails: {
          isLoading: false,
          data: action?.payload?.data,
          error: action?.payload?.error,
        },
      };
      return state;
    case GET_CHANNELSRELATIVEVIDEOS:
      state = {
        ...state,
        channelrelativeVideos: {
          ...state.channelrelativeVideos,
          isLoading: true,
          data: [],
          error: null,
        },
      };
      return state;
    case SET_CHANNELSRELATIVEVIDEOS:
      state = {
        ...state,
        channelrelativeVideos: {
          isLoading: false,
          data: action?.payload?.data,
          error: action?.payload?.error,
          nextPage: action.payload.nextPage || state.myChannels.nextPage,
        },
      };
      return state;
    case GET_CHANNELVIDEOPLAY:
      state = {
        ...state,
        channelvideoPlay: {
          ...state.channelvideoPlay,
          isLoading: true,
          data: [],
          error: null,
        },
      };
      return state;
    case SET_CHANNELVIDEOPLAY:
      state = {
        ...state,
        channelvideoPlay: {
          isLoading: false,
          data: action.payload?.data,
          error: action.payload?.error,
        },
      };
      return state;
    case GET_RECOMMENDEDCHANNELS:
      state = {
        ...state,
        recommendedchannel: {
          ...state.recommendedchannel,
          isLoading: true,
          data: [],
          error: null,
        },
      };
      return state;
    case SET_RECOMMENDEDCHANNELS:
      state = {
        ...state,
        recommendedchannel: {
          isLoading: false,
          data: action.payload?.data,
          error: action.payload?.error,
        },
      };
      return state;
    default:
      return state;
  }
};

export { channelReducer };
export {
  fetchChannels,
  fetchchannelsDetails,
  fetchchannelsrelativeVideos,
  fetchchannelvideoPlay,
  fetchrecommendedChannels,
  fetchMyChannels,
  clearCategorizedChannelsData
};
