import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../store";
import PaginationComponent from "../../../ui/paginationComponent";
import rootReducer from "../../../reducers/rootReducer";
import { getAllMenuMoviesData, getCategoryWiseMenuMoviesData } from "../../../reducers/dashboardReducer";
import { setError } from "../../../reducers/layoutReducer";
const take =10;
const MovieVideosAllCategories = () => {
  const {filterBy}= useParams()
  const rootDispatch=useDispatch()

  const leftMenuMoviesData = useSelector(
    (store: any) => store?.dashboard?.categoryWiseMoviesData
  );
useEffect(()=>{
  store.dispatch(
    getCategoryWiseMenuMoviesData({
      type: "movies",
      category: filterBy?.toLowerCase(),
      take: take,
      page: 1,
      search: null,
      data:null
    })
  );
}, [filterBy])
useEffect(() => {
  store.dispatch(rootReducer);
}, []);
const fetchMoreMovies = (data: any, page: number) => {
  store.dispatch(
    getAllMenuMoviesData({
      type: "movies",
      category: filterBy,
      take: take,
      page: page,
      search: null,
      data: data,
    })
  );
};
if(leftMenuMoviesData?.error){
  rootDispatch(setError({ message: leftMenuMoviesData?.error }))
}
  return (
    <div className="px-4 mt-4">

      <div>
        <PaginationComponent
          toDisplay="video"
          data={leftMenuMoviesData?.data}
          title={filterBy}
          isLoading={leftMenuMoviesData?.loading}
          itemsPerPage={take}
          nextPage={leftMenuMoviesData?.nextPage}
          onPageChange={(data: any, page: number) =>
            fetchMoreMovies(data, page)
          }
          displayNoData={true}
        />
      </div>
    </div>
  );
};

export default MovieVideosAllCategories;

