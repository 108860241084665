import React from "react";
interface GenreCardProps {
  id: string;
  image: string;
  category: string;
}
const GenreCard = ({
 id,image,category,
}: GenreCardProps) => {
  return (
    <div>
      <img
        className="md:w-[250px] md:h-[250px] rounded-lg object-cover"
        src={image}
        alt={category}
      />
      <div className="pt-3">
        <p className="text-base text-secondary font-medium">
          {category}
        </p>
      </div>
    </div>
  );
};

export default GenreCard;
