import { useAccount, useSignMessage } from "wagmi";
import streaming from "../contracts/streamingContract.json";
import { ethers } from "ethers";
import {
  prepareWriteContract,
  readContract,
  waitForTransaction,
  writeContract,
} from "wagmi/actions";
import { postSigner } from "../utils/api";
import { useSelector } from "react-redux";
export default function useContractMethods() {
  const PRIVATE_KEY = process.env.REACT_APP_OWNER_PRIVATE_KEY;
  const user = useSelector((state: any) => state?.auth?.user);
  const { address } = useAccount();
  const { signMessageAsync } = useSignMessage({
    message: "Please verify your identity with metamask",
  });
  const decimals: any = process.env.REACT_APP_DECIMALS;
  const decimalPoints: any = process.env.REACT_APP_POINST;

  const convertToWei = (value: any, type: string) => {
    if (type === "array") {
      return value.map((number) => {
        return ethers.utils.parseUnits(number, decimalPoints);
      });
    }
    if (type === "string") {
      return ethers.utils.parseUnits(value, decimalPoints);
    }
  };
  function _provider() {
    const _provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_ALCHEMY_PROVIDER
    );
    return _provider;
  }

  async function requestForStreamingContract(funName, args) {
    const { request } = await prepareWriteContract({
      address: process.env.REACT_APP_STREAMING_TOKEN_CONTRACT_ADDRESS,
      abi: streaming.abi,
      functionName: funName,
      args,
    });
    return request;
  }
  async function approve(callback: Function, amount: number) {
    const _allowence = ethers.BigNumber.from(
      (amount * decimals).toLocaleString("fullwide", { useGrouping: false })
    );
    try {
      const request = await requestForTokenContract("approve", [
        process.env.REACT_APP_STAKING_CONTRACT,
        _allowence,
      ]);
      const { hash } = await writeContract(request);
      const receipt = await waitForTransaction({ hash });
      callback({ ok: true, response: receipt });
    } catch (error) {
      callback({ ok: false, error });
    }
  }

  async function createChannel(
    name: string,
    description: string,
    monthlyMembershipPrice: string,
    // quarterlyMembershipPrice: string,
    // halfYearlyMembershipPrice: string,
    // yearlyMembershipPrice: string,
    metaData: string
  ) {
    const _monthlyMembershipPrice = convertToWei(
      monthlyMembershipPrice,
      "string"
    );
    try {
      const request = await requestForStreamingContract("createChannel", [
        name,
        description,
        metaData,
        _monthlyMembershipPrice,
        // _quarterlyMembershipPrice,
        // _halfYearlyMembershipPrice,
        // _yearlyMembershipPrice,
      ]);
      const { hash } = await writeContract(request);
      const receipt = await waitForTransaction({ hash });
      return { ok: true, response: receipt };
    } catch (error) {
      return { ok: false, error: parseError(error) };
    }
  }

  async function mintVideo(
    channelId: string,
    price: string,
    name: string,
    description: string,
    metaData: string
  ) {
    const _price = ethers.utils.parseUnits(price, decimalPoints);
    try {
      const request = await requestForStreamingContract("mintVideo", [
        channelId,
        _price,
        name,
        description,
        metaData,
      ]);
      const { hash } = await writeContract(request);
      const receipt = await waitForTransaction({ hash });
      return { ok: true, response: receipt };
    } catch (error) {
      return { ok: false, error: parseError(error) };
    }
  }

  async function putVideoForSale(videoId: string, price: string) {
    const _price = ethers.utils.parseUnits(price, decimalPoints);
    try {
      const request = await requestForStreamingContract("putVideoForSale", [
        videoId,
        _price,
      ]);
      const { hash } = await writeContract(request);
      const receipt = await waitForTransaction({ hash });
      return { ok: true, response: receipt };
    } catch (error) {
      return { ok: false, error: parseError(error) };
    }
  }

  async function buyVideo(videoId: string) {
    try {
      const request = await requestForStreamingContract("buyVideo", [videoId]);
      const { hash } = await writeContract(request);
      const receipt = await waitForTransaction({ hash });
      return { ok: true, response: receipt };
    } catch (error) {
      return { ok: false, error: parseError(error) };
    }
  }

  async function subscribeToChannel(channelId: string) {
    try {
      const request = await requestForStreamingContract("subscribeToChannel", [
        channelId,
      ]);
      const { hash } = await writeContract(request);
      const receipt = await waitForTransaction({ hash });
      return { ok: true, response: receipt };
    } catch (error) {
      return { ok: false, error: parseError(error) };
    }
  }

  async function isSubscriber(channelId: string) {
    try {
      const request = await requestForStreamingContract("isSubscriber", [
        address,
        channelId,
      ]);
      const { hash } = await writeContract(request);
      const receipt = await waitForTransaction({ hash });
      return { ok: true, response: receipt };
    } catch (error) {
      return { ok: false, error: parseError(error) };
    }
  }

  async function getChannelInfo(channelId: string) {
    try {
      const request = await requestForStreamingContract("getChannelInfo", [
        channelId,
      ]);
      const { hash } = await writeContract(request);
      const receipt = await waitForTransaction({ hash });
      return { ok: true, response: receipt };
    } catch (error) {
      return { ok: false, error: parseError(error) };
    }
  }
  async function getVideoInfo(videoId: string) {
    try {
      const request = await requestForStreamingContract("getVideoInfo", [
        videoId,
      ]);
      const { hash } = await writeContract(request);
      const receipt = await waitForTransaction({ hash });
      return { ok: true, response: receipt };
    } catch (error) {
      return { ok: false, error: parseError(error) };
    }
  }

  function parseError(message) {
    let _message =
      message?.details ||
      message?.cause?.reason ||
      message?.message ||
      message.fault;
    return _message;
  }

  return {
    approve,
    parseError,
    createChannel,
    mintVideo,
    putVideoForSale,
    buyVideo,
    subscribeToChannel,
    isSubscriber,
    getChannelInfo,
    getVideoInfo,
  };
}
