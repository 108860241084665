import apiCalls from "../utils/api";

const SET_ALL_WEBSERIES_EPISODES_DATA = 'fetchAllWebSeriesEpisodesData';
const SET_WEBSERIES_TABS_DATA = 'fechWebSeriesTabsData';
const fetchAllWebSeriesEpisodesData = (payload) => {
    return {
        type: SET_ALL_WEBSERIES_EPISODES_DATA,
        payload
    }
};
const fechWebSeriesTabsData = (payload) => {
    return {
        type: SET_WEBSERIES_TABS_DATA,
        payload
    }
};

export const fetchWebSeriesTabsData=(information)=>{
    const { name,take,page,data,search=null } = information;
    const skip = take * (page) - take;
    return async (dispatch) => {
        dispatch(fechWebSeriesTabsData({ loading: true, data: data, error: '' }));
        try {
            const res = await apiCalls.getWebSeriesTabsData(name);
            const responce = await apiCalls.getWebSeriesEpisodesData(res?.data[0]?.id,take, skip,search);
            if (res.status === 200) {
                dispatch(fechWebSeriesTabsData({ loading: false, data: data ? [...data, ...res.data] : res.data, error: '' }));
                dispatch(fetchAllWebSeriesEpisodesData({ loading: false, data: data ? [...data, ...responce.data] : responce.data, error: '' }));
            } else {
                dispatch(fechWebSeriesTabsData({loading: false,data: null,error: res,}));
                dispatch(fetchAllWebSeriesEpisodesData({loading: false,data: null,error: res}));
            }
        } catch (error) {
            dispatch(fechWebSeriesTabsData({loading: false,data: data,error: error}));
            dispatch(fetchAllWebSeriesEpisodesData({loading: false,data: data,error: error}));
        }
    };
}
export const fetchWebSeriesEpisodesData=(information)=>{
    const { seriesId,take,page,data ,search} = information;
    const skip = take * (page) - take;
    return async (dispatch) => {
        dispatch(fetchAllWebSeriesEpisodesData({ loading: true, data: data, error: '' }));
        try {
            const res = await apiCalls.getWebSeriesEpisodesData(seriesId,take, skip,search);
            if (res.status === 200) {
                dispatch(fetchAllWebSeriesEpisodesData({ loading: false, data: data ? [...data, ...res.data] : res.data, error: '' }));
            } else {
                dispatch(fetchAllWebSeriesEpisodesData({loading: false,data: null,error: res}));
            }
        } catch (error) {
            dispatch(fetchAllWebSeriesEpisodesData({loading: false,data: data,error: error}));
        }
    };
}

 const webSeriesInitailstate = {
    allWebSeriesEpisodesData: { data: [], loading: false, error: '' },
    allWebSeriesTabsData: { data: [], loading: false, error: '' },

}

export const webSeriesReducer = (state = webSeriesInitailstate, action) => {
    switch (action.type) {
        case SET_ALL_WEBSERIES_EPISODES_DATA:
            state = {...state, allWebSeriesEpisodesData: {loading: action.payload.loading,data: action.payload.data,error: action.payload.error}};
            return state;
        case SET_WEBSERIES_TABS_DATA:
            state = {...state, allWebSeriesTabsData: {loading: action.payload.loading,data: action.payload.data,error: action.payload.error}};
            return state;
        
            default:
    return state;

    }
}