import { isErrorDispaly } from "../components/profile/utils"
import apiCalls from "../utils/api"
const { GET } = require("../api/httpClient")

const GET_TREND = "getTrend"
const GET_VIDEOS = "getVideos"
const SET_VIDEOS = "setVideos"
const GET_GENERS = "getgeners"
const SET_GENERS = "setgeners"
const GET_DASHBOARD_CHANNELS = "getDashboardChannels"
const SET_DASHBOARD_CHANNELS = "setDashboardChannels"
const GET_DASHBOARD_WEBSERIES = "getDashboardWebseries"
const SET_DASHBOARD_WEBSERIES = "setDashboardWebseries"
const GET_WEBSERIESEPISODELIST = "getwebseriesepisodelist"
const SET_WEBSERIESEPISODELIST = "setwebseriesepisodelist"

//-----latest changes
const SET_TRENDING_VIDEOS = "setTrendingVideos"
const CATEGORIED_TRENDNG_VIDEOS = "setCategorizedTrendingVideos"
const FETCH_TOP_MOVIES = "fetchTopMovies";
const FETCH_TOP_WEBSERIES = "fetchTopWebSeries";
const FETCH_ALL_CATEGORES = 'fetAllCategories';
const LEFT_MENU_MOVIES_DATA = 'fetchLeftMenuMoviesData';
const LEFT_MENU_WEBSERIES_DATA = 'fetchLeftMenuWebSeriesData';
const CATEGORY_WISE_MOVIES_DATA = 'fetchCategoryWiseMoviesData';
const CATEGORY_WISE_WEBSERIES_DATA ='fetchCategoryWiseWebseriesData';

const fetchTopMovies = (payload) => {
    return {
        type: FETCH_TOP_MOVIES,
        payload
    }
};
const fetchTopWebSeries = (payload) => {
    return {
        type: FETCH_TOP_WEBSERIES,
        payload
    }
};
const setTrendingVideos = (payload) => {
    return {
        type: SET_TRENDING_VIDEOS,
        payload
    }
}
const setCategorizedTrendingVideos = (payload) => {
    return {
        type: CATEGORIED_TRENDNG_VIDEOS,
        payload
    }
}
const fetAllCategories = (data) => {
    return {
        type: FETCH_ALL_CATEGORES,
        payload: data
    }
}
const fetchLeftMenuMoviesData = (payload) => {
    return {
        type: LEFT_MENU_MOVIES_DATA,
        payload
    }
}
const fetchLeftMenuWebSeriesData = (data) => {
    return {
        type: LEFT_MENU_WEBSERIES_DATA,
        payload: data
    }
}
const fetchCategoryWiseMoviesData = (payload) => {
    return {
        type: CATEGORY_WISE_MOVIES_DATA,
        payload
    }
}
const fetchCategoryWiseWebseriesData = (payload) => {
    return {
        type: CATEGORY_WISE_WEBSERIES_DATA,
        payload
    }
}
//============================================//
const getTrend = () => {
    return {
        type: GET_TREND,
        payload: null
    }
}

const getwebseriesepisodelist = () => {
    return {
        type: GET_WEBSERIESEPISODELIST,
        payload: null
    }
}
const setwebseriesepisodelist = (data) => {
    return {
        type: SET_WEBSERIESEPISODELIST,
        payload: data
    }
}
const getVideos = () => {
    return {
        type: GET_VIDEOS,
        payload: null
    }
}
const setVideos = (data) => {
    return {
        type: SET_VIDEOS,
        payload: data
    }
}
const getgeners = () => {
    return {
        type: GET_GENERS,
        payload: null
    }
}
const setgeners = (data) => {
    return {
        type: SET_GENERS,
        payload: data
    }
}
const getDashboardChannels = () => {
    return {
        type: GET_DASHBOARD_CHANNELS,
        payload: null
    }
}
const setDashboardChannels = (data) => {
    return {
        type: SET_DASHBOARD_CHANNELS,
        payload: data
    }
}

const getDashboardWebseries = () => {
    return {
        type: GET_DASHBOARD_WEBSERIES,
        payload: null
    }
}
const setDashboardWebseries = (data) => {
    return {
        type: SET_DASHBOARD_WEBSERIES,
        payload: data
    }
}



export const fetchAllTrendingVideos = (information) => {
    const { take, page, data, category, search } = information;
    const skip = take * (page) - take;
    return async (dispatch) => {
        dispatch(setTrendingVideos({ loading: true, category, data, error: "" }));
        try {
            const res = await apiCalls.fetchTrendingVideos(
                category.toLowerCase(),
                take,
                skip,
                search
            );
            if (res.status === 200) {
                dispatch(setTrendingVideos({
                    loading: false,
                    category,
                    data: data ? [...res.data, ...data] : res.data,
                    error: "",
                    nextPage: page + 1,
                })
                );
            } else {
                dispatch(setTrendingVideos({ loading: false, category, data, error: res }));
            }
        } catch (error) {
            dispatch(setTrendingVideos({ loading: false, category, data, error: error }));
        }
    };
}
export const fetchCategoryRelatedTrendingVideos = (information) => {
    const { category, take, page,type, data, search } = information;
    const skip = take * page - take;
    return async (dispatch) => {
      dispatch(setCategorizedTrendingVideos({ loading: true, data: data, error: "" }));
      try {
        const res = await apiCalls.fetchTrendingVideos(
            type.toLowerCase(),
            category.toLowerCase(),
            take,
            skip,
            search
        );
        if (res.status === 200) {
          dispatch(
            setCategorizedTrendingVideos({
              loading: false,
              data: data ? [...data, ...res.data] : res.data,
              error: "",
              nextPage: page + 1,
            })
          );
        } else {
          dispatch(
            setCategorizedTrendingVideos({ loading: false, data: null, error: res })
          );
        }
      } catch (error) {
        dispatch(
            setCategorizedTrendingVideos({ loading: false, data: data, error: error })
        );
      }
    };
  };

export const getDashboardTopMovies = (information) => {
    const { type, take, page, search, data } = information;
    const skip = take * (page) - take;
    return async (dispatch) => {
        dispatch(fetchTopMovies({ loading: true, data: data, error: '' }));
        try {
            const res = await apiCalls.getTopMovies(type, take, skip, search);
            if (res.status === 200) {
                dispatch(fetchTopMovies({ loading: false, data: data ? [...data, ...res.data] : res.data, error: '' }));
            } else {
                dispatch(fetchTopMovies({ loading: false, data: null, error: res, }));
            }
        } catch (error) {
            dispatch(fetchTopMovies({ loading: false, data: data, error: error }));
        }
    };
}

export const getDashboardTopWebSeries = (information) => {
    const { type, take, page, search, data } = information;
    const skip = take * (page) - take;
    return async (dispatch) => {
        dispatch(fetchTopWebSeries({ loading: true, data: data, error: '' }));
        try {
            const res = await apiCalls.getTopMovies(type, take, skip, search);
            if (res.status === 200) {
                dispatch(fetchTopWebSeries({ loading: false, data: data ? [...data, ...res.data] : res.data, error: '' }));
            } else {
                dispatch(fetchTopWebSeries({ loading: false, data: null, error: res, }));
            }
        } catch (error) {
            dispatch(fetchTopWebSeries({ loading: false, data: data, error: error }));
        }
    };
}
export const clearMoviesData = (category) => {
    fetchCategoryWiseMoviesData({ loading: false, category, data: null, error: "" })
    fetchLeftMenuWebSeriesData({ loading: false, category, data: null, error: "" });
    setTrendingVideos({ loading: false, category, data: null, error: "" })
    fetchTopWebSeries({  loading: false, category, data: null, error: "" })
}

export const getAllMenuMoviesData = (information) => {
    const { type, take, page, category, search, data } = information;
    const skip = take * (page) - take;
    return async (dispatch) => {
        dispatch(fetchLeftMenuMoviesData({ loading: true, data: data, error: '' }));
        try {
            const res = await apiCalls.getLeftMenuMoviesorWebSeries(type, category, take, skip, search);
            if (res.status === 200) {
                dispatch(fetchLeftMenuMoviesData({
                    loading: false,
                    category,
                    data: data ? [...res.data, ...data] : res.data,
                    error: "",
                    nextPage: page + 1,
                }));
            } else {
                dispatch(fetchLeftMenuMoviesData({ loading: false, category, data, error: res }));
            }
        } catch (error) {
            dispatch(fetchLeftMenuMoviesData({ loading: false, category, data, error: error }));
        }
    };
}

export const getCategoryWiseMenuMoviesData = (information) => {
    const { type, take, page, category, search, data } = information;
    const skip = take * (page) - take;
    return async (dispatch) => {
        dispatch(fetchCategoryWiseMoviesData({ loading: true, data: data, error: "" }));
        try {
            const res = await apiCalls.getLeftMenuMoviesorWebSeries(type, category.toLowerCase(), take, skip, search);
            if (res.status === 200) {
                dispatch(fetchCategoryWiseMoviesData({
                    loading: false,
                    data: data ? [...data, ...res.data] : res.data,
                    error: "",
                    nextPage: page + 1,
                }));
            } else {
                dispatch(fetchCategoryWiseMoviesData({ loading: false, data: null, error: res }));
            }
        } catch (error) {
            dispatch(fetchCategoryWiseMoviesData({ loading: false, data: data, error: error }));
        }
    };
}
export const getAllWebseriesData = (information) => {
    const { type, take, page, category, search, data } = information;
    const skip = take * (page) - take;
    return async (dispatch) => {
        dispatch(fetchLeftMenuWebSeriesData({ loading: true, data: data, error: '' }));
        try {
            const res = await apiCalls.getLeftMenuMoviesorWebSeries(type, category, take, skip, search);
            if (res.status === 200) {
                dispatch(fetchLeftMenuWebSeriesData({
                    loading: false,
                    category,
                    data: data ? [...res.data, ...data] : res.data,
                    error: "",
                    nextPage: page + 1,
                }));

            } else {
                dispatch(fetchLeftMenuWebSeriesData({ loading: false, category, data, error: res }));
            }
        } catch (error) {
            dispatch(fetchLeftMenuWebSeriesData({ loading: false, category, data, error: error }));
        }
    };
}
export const getCategoryWiseWebseriesData = (information) => {
    const { type, take, page, category, search, data } = information;
    const skip = take * (page) - take;
    return async (dispatch) => {
        dispatch(fetchCategoryWiseWebseriesData({ loading: true, data: data, error: "" }));
        try {
            const res = await apiCalls.getLeftMenuMoviesorWebSeries(type, category.toLowerCase(), take, skip, search);
            if (res.status === 200) {
                dispatch(fetchCategoryWiseWebseriesData({
                    loading: false,
                    data: data ? [...data, ...res.data] : res.data,
                    error: "",
                    nextPage: page + 1,
                }));
            } else {
                dispatch(fetchCategoryWiseWebseriesData({ loading: false, data: null, error: res }));
            }
        } catch (error) {
            dispatch(fetchCategoryWiseWebseriesData({ loading: false, data: data, error: error }));
        }
    };
}

export const getAllCategoriesLu = (information) => {
    const { type } = information;
    return async (dispatch) => {
        dispatch(fetAllCategories({ loading: true, data: null, error: '' }));
        try {
            const res = await apiCalls.fetchCategoryLu(type);
            if (res.status === 200) {
                dispatch(fetAllCategories({ loading: false, data: res.data, error: '' }));
            } else {
                dispatch(fetAllCategories({ loading: false, data: null, error: res }));
            }
        } catch (error) {
            dispatch(fetAllCategories({ loading: false, data: null, error: error }));
        }
    };
}

const initailstate = {
    videos: {
        isLoading: false,
        data: [],
        error: null
    },
    genres: {
        isLoading: false,
        data: [],
        error: null
    },
    channels: {
        isLoading: false,
        data: [],
        error: null
    },
    webseries: {
        isLoading: false,
        data: [],
        error: null
    },
    webseriesepisodelist: {
        isLoading: false,
        data: [],
        error: null
    },
    trendingVideosAllCategories: { },
    CategoriziedTrendingVideos:{ data: [], loading: false, error: "" },
    dashoBoardTopMovies: { data: [], loading: false, error: '' },
    dashoBoardTopWebSeries: { data: [], loading: false, error: '' },
    allCategories: { loading: false, data: null, error: '' },
    leftMenuMoviesData: {},
    AllWebSeriesData: {},
    categoryWiseMoviesData: { data: [], loading: false, error: '' },
    categoryWiseWebSeriesData: { data: [], loading: false, error: '' },

}

export const dashboardReducer = (state = initailstate, action) => {
    switch (action.type) {
        case GET_TREND:
            state = { ...state, trends: { isLoading: true, data: [], error: null } };
            return state;
        case SET_TRENDING_VIDEOS:
            state = {
                ...state,
                trendingVideosAllCategories: {
                  ...state.trendingVideosAllCategories,
                  [action.payload.category]: {
                    loading: action.payload.loading,
                    error: action.payload.error,
                    nextPage: action.payload.nextPage
                      ? action.payload.nextPage
                      : state.trendingVideosAllCategories?.[action.payload.category]?.nextPage || 1,
                    data: action.payload.data,
                  },
                },
              };
            return state;
        case CATEGORIED_TRENDNG_VIDEOS:
            state = {
                ...state,
                CategoriziedTrendingVideos: {
                  loading: action.payload.loading,
                  data: action.payload.data,
                  error: action.payload.error,
                  nextPage:action.payload.nextPage,
                },
              };
              return state;
        case GET_VIDEOS:
            state = { ...state, videos: { isLoading: false, data: [], error: null } };
            return state;
        case SET_VIDEOS:
            state = { ...state, videos: { isLoading: false, ...action.payload } };
            return state;
        case GET_GENERS:
            state = { ...state, genres: { ...state, genres: { isLoading: false, data: [], error: null } } };
            return state;
        case SET_GENERS:
            state = { ...state, genres: { isLoading: false, ...action.payload } };
            return state;
        case GET_DASHBOARD_CHANNELS:
            state = { ...state, channels: { isLoading: false, data: [], error: null } };
            return state;
        case SET_DASHBOARD_CHANNELS:
            state = { ...state, channels: { isLoading: false, ...action.payload } };
            return state;
        case GET_DASHBOARD_WEBSERIES:
            state = { ...state, webseries: { isLoading: false, data: [], error: null } };
            return state;
        case SET_DASHBOARD_WEBSERIES:
            state = { ...state, webseries: { isLoading: false, ...action.payload } };
            return state;
        case GET_WEBSERIESEPISODELIST:
            state = { ...state, webseriesepisodelist: { ...state.webseriesepisodelist, isLoading: true, data: [], error: null } };
            return state;
        case SET_WEBSERIESEPISODELIST:
            state = { ...state, webseriesepisodelist: { isLoading: false, data: action.payload?.data, error: action?.payload?.error } };
            return state;
        case FETCH_TOP_MOVIES:
            state = { ...state, dashoBoardTopMovies: { loading: action.payload.loading, data: action.payload.data, error: action.payload.error } };
            return state;
        case FETCH_TOP_WEBSERIES:
            state = { ...state, dashoBoardTopWebSeries: { loading: action.payload.loading, data: action.payload.data, error: action.payload.error } };
            return state;
        case FETCH_ALL_CATEGORES:
            state = { ...state, allCategories: { loading: action.payload.loading, data: action.payload.data, error: action.payload.error } };
            return state;
        case LEFT_MENU_MOVIES_DATA:
            state = {
                ...state,
                leftMenuMoviesData: {
                    ...state.leftMenuMoviesData,
                    [action.payload.category]: {
                        loading: action.payload.loading,
                        error: action.payload.error,
                        nextPage: action.payload.nextPage
                            ? action.payload.nextPage
                            : state.leftMenuMoviesData?.[action.payload.category]?.nextPage || 1,
                        data: action.payload.data,
                    },
                },
            };
            return state;
        case LEFT_MENU_WEBSERIES_DATA:
            state = {
                ...state,
                AllWebSeriesData: {
                    ...state.AllWebSeriesData,
                    [action.payload.category]: {
                        loading: action.payload.loading,
                        error: action.payload.error,
                        nextPage: action.payload.nextPage
                            ? action.payload.nextPage
                            : state.AllWebSeriesData?.[action.payload.category]?.nextPage || 1,
                        data: action.payload.data,
                    },
                },
            };
            return state;
        case CATEGORY_WISE_MOVIES_DATA:
            state = {
                ...state,
                categoryWiseMoviesData: {
                    loading: action.payload.loading,
                    data: action.payload.data,
                    error: action.payload.error,
                },
            };
            return state;
        case CATEGORY_WISE_WEBSERIES_DATA:
            state = {
                ...state,
                categoryWiseWebSeriesData: {
                    loading: action.payload.loading,
                    data: action.payload.data,
                    error: action.payload.error,
                },
            };
            return state;
        default:
            return state;
    }
}

// export const fetchTrend = (pageSize = 10, page = 1) => {
//     return async (dispatch) => {
//         dispatch(getTrend())
//         try {
//             const response = await GET(`trendingvideos/${pageSize}/${page}/null`)
//             if (response.status === 200) {
//                 dispatch(setTrend({ error: null, data: response.data }))
//             }
//             else {
//                 dispatch(setTrend({ error: isErrorDispaly(response), data: [] }))
//             }
//         } catch (err) {
//             dispatch(setTrend({ error: isErrorDispaly(err), data: [] }))
//         }
//     }
// }
export const fetchVideos = (pageSize = 10, page = 1) => {
    return async (dispatch) => {
        dispatch(getVideos())
        try {
            const response = await GET(`topmovies/${pageSize}/${page}/null`)
            if (response.status === 200) {
                dispatch(setVideos({ error: null, data: response.data }))
            }
            else {
                dispatch(setVideos({ error: isErrorDispaly(response), data: [] }))
            }

        } catch (err) {
            dispatch(setVideos({ error: err.message, data: [] }))
        }
    }
}
export const fetchGeners = (pageSize = 10, page = 1) => {
    return async (dispatch) => {
        dispatch(getgeners())
        try {
            const response = await GET(`allgeneres/${pageSize}/${page}`)
            if (response.status === 200) {
                dispatch(setgeners({ error: null, data: response.data }))
            } else {
                dispatch(setgeners({ error: isErrorDispaly(response), data: [] }))
            }
        } catch (err) {
            dispatch(setgeners({ error: err.message, data: [] }))
        }
    }
}
export const fetchDashboardChannels = (pageSize = 10, page = 1) => {
    return async (dispatch) => {
        dispatch(getDashboardChannels())
        try {
            const response = await GET(`topchannels/${pageSize}/${page}/null`)
            if (response.status === 200) {
                dispatch(setDashboardChannels({ error: null, data: response?.data }))
            }
            else {
                dispatch(setDashboardChannels({ error: isErrorDispaly(response), data: [] }))
            }
        } catch (err) {
            dispatch(setDashboardChannels({ error: err.message, data: [] }))
        }
    }
}
export const fetchDashboardWebseries = (pageSize = 10, page = 0) => {
    return async (dispatch) => {
        dispatch(getDashboardWebseries())
        try {
            const response = await GET(`topwebseries/null/${pageSize}/${page}/null`)
            if (response.status === 200) {
                dispatch(setDashboardWebseries({ error: null, data: response?.data }))
            }
            else {
                dispatch(setDashboardWebseries({ error: isErrorDispaly(response), data: [] }))
            }
        } catch (err) {
            dispatch(setDashboardWebseries({ error: err.message, data: [] }))
        }
    }
}
export const fetchwebseriseepisodelist = (seriesId, pageSize = 10, page = 0) => {
    return async (dispatch) => {
        dispatch(getwebseriesepisodelist());
        try {
            const { data } = await GET(`WebSeriesEpisode/${seriesId}/${pageSize}/${page}/null`);
            dispatch(setwebseriesepisodelist({ error: null, data: data }));
        } catch (err) {
            dispatch(setwebseriesepisodelist({ data: [], error: err.message }));
        }

    }
}
