import { GET } from "../api/httpClient";

const SET_COMMENTS = "setComment";
const setComments = (data: any) => {
  return {
    type: SET_COMMENTS,
    payload: data,
  };
};
const fetchComments = (id: any, customerId: any) => {
  return async (dispatch) => {
    dispatch(setComments({ error: null, data: null, loading: true }));
    try {
      const response = await GET(`commentdetails/${id}/${customerId}`);
      if (response.status === 200) {
        dispatch(
          setComments({ error: null, data: response.data, loading: false })
        );
      } else {
        dispatch(setComments({ error: response, data: null, loading: false }));
      }
    } catch (error) {
      dispatch(setComments({ data: null, error: error, loading: false }));
    }
  };
};
const initialState = {
  isLoading: false,
  data: null,
  error: "",
};
const commentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_COMMENTS:
      return {
        ...state,
        isLoading: action.payload.loading,
        data: action?.payload.data,
        error: action?.payload?.error,
      };
    default:
      return state;
  }
};

export { commentReducer };
export { fetchComments };
