import axios from "axios";
const API_BASE_URL = 'https://devdottapi.azurewebsites.net/api/v1/User';
                   
export function GET(url){
    return axios.get(`${API_BASE_URL}/${url}`)
}

export function POST(url:any,data:any){
    return axios.post(`${API_BASE_URL}/${url}`,data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function PUT(url:any,data:any){
    return axios.put(`${API_BASE_URL}/${url}`,data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
