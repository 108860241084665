import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import ChannelShimmer from "../components/streaming/loaders/channel";
import MovieShimmer from "../components/streaming/loaders/movie.card";
import GenreCard from "./cards/genre";
import ChannelCard from "./cards/channel";
import VideoCard from "./cards/video";
import NoData from "./noData";
interface ComponentProps {
  data: any;
  toDisplay: string;
  title: string;
  isLoading: boolean;
  displayNoData?: boolean;
  key:string,
}
const ScrollingComponent = ({
  data,
  toDisplay,
  title,
  isLoading,
  displayNoData = false,
  key
}: ComponentProps) => {
  const containerRef = useRef<any>(null);
  const cardRef = useRef<any>(null);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setHasScrollbar(container.scrollWidth > container.clientWidth);
    }
  }, [containerRef.current,data]);

  const handleScroll = (e: any) => {
    const { scrollLeft, clientWidth, scrollWidth } = e.target;
    const position = Math.ceil(
      (scrollLeft / (scrollWidth - clientWidth)) * 100
    );
    setCurrentPosition(position);
  };
  const handleSlideClick = (direction: any) => {
    const { clientWidth } = cardRef.current;
    setCurrentPosition((prevPosition: any) => {
      return direction === "prev" ? prevPosition - 1 : prevPosition + 1;
    });
    containerRef.current.scrollBy({
      left: direction === "prev" ? -clientWidth : clientWidth,
      behavior: "smooth",
    });
  };
  return (
    <>
      {(data?.length > 0 || isLoading) && (
        <div
          className={`${"overflow-x-auto no-scrollbar gap-[50px] rounded-lg"}`}
          id={key}
          key={key}
        >
          {
            <div className="flex items-center justify-between">
              <h3 className="text-xl font-medium text-secondary mb-[14px]">
                {title}
              </h3>
              <div className="flex">
                {data?.length !== 0 &&
                  hasScrollbar &&
                  currentPosition !== 0 && (
                    <div className="hover:bg-accent hover:rounded-full h-9 w-9 flex items-center justify-center cursor-pointer">
                      <Button
                        type="plain"
                        handleClick={() => {
                          handleSlideClick("prev");
                        }}
                      >
                        <span className="icon leftarrow-small"></span>
                      </Button>
                    </div>
                  )}
                {data?.length !== 0 &&
                  hasScrollbar &&
                  currentPosition !== 100 && (
                    <div className="hover:bg-accent hover:rounded-full h-9 w-9 flex items-center justify-center cursor-pointer">
                      <Button
                        type="plain"
                        handleClick={() => {
                          handleSlideClick("next");
                        }}
                      >
                        <span className="icon rightarrow-small"></span>
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          }
          {
            <div
              className={`carousel overflow-y-hidden w-full ${toDisplay === "channel" ? "gap-[54px]" : "gap-4"}`}
              ref={containerRef}
              onScroll={handleScroll}
            >
              {isLoading && (
                <>
                  {[...Array(10)].map((_, index) => (
                    <div
                      key={index}
                      className={`carousel-item ${
                        toDisplay === "video"
                          ? "shadow-lg md:w-[420px] max-sm:min-w-[340px]"
                          : ""
                      } ${
                        toDisplay === "webseries"
                          ? "shadow-lg md:w-[420px] max-sm:min-w-[340px]"
                          : ""
                      }`}
                    >
                      {toDisplay === "channel" && <ChannelShimmer />}
                      {toDisplay === "video" && <MovieShimmer />}
                      {toDisplay === "webseries" && <MovieShimmer />}
                    </div>
                  ))}
                </>
              )}
              {!isLoading &&
                data?.map((item: any) => {
                  return (
                    <div
                      className={`carousel-item ${
                        toDisplay === "video"
                          ? "shadow-lg md:w-[420px] max-sm:min-w-[340px]"
                          : ""
                      }`}
                      ref={cardRef}
                      key={item.id}
                    >
                      {toDisplay === "genre" && (
                        <GenreCard
                          id={item.id}
                          image={item.image}
                          category={item.category}
                        />
                      )}
                      {toDisplay === "channel" && (
                        <ChannelCard
                          id={item.id}
                          creatorId={item.creatorId}
                          logo={item.logo}
                          name={item.name}
                          subscribers={item.subscribers}
                          earned={item.earned}
                        />
                      )}
                      {toDisplay === "video" && (
                        <VideoCard
                          id={item.id}
                          price={item.price}
                          videoLink={item.videoLink}
                          thumbnail={item.thumbnail}
                          channelLogo={item.logo}
                          channelName={item.channelName}
                          videoTitle={item.name || item.title}
                          language={item.language}
                          views={item.views}
                          createdDate={item.createdDate}
                          type={item.type}
                          isVideoForSale={item.isVideoForSale}
                          creatorId={item.creatorId}
                        />
                      )}
                      {toDisplay === "webseries" && (
                        <VideoCard
                          id={item.id}
                          price={item.price}
                          videoLink={item.videoLink}
                          thumbnail={item.thumbnail}
                          channelLogo={item.logo}
                          channelName={item.channelName}
                          videoTitle={item.name}
                          language={item.language}
                          views={item.views}
                          createdDate={item.createdDate}
                          type={item.type}
                          creatorId={item.creatorId}
                          isVideoForSale={item.isVideoForSale}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          }
        </div>
      )}
      {data?.length === 0 && !isLoading && displayNoData && (
        <div className="mb-4">
          <h3 className="text-xl font-medium text-secondary">{title}</h3>
          <NoData />
        </div>
      )}
    </>
  );
};

export default ScrollingComponent;
