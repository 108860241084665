import React from 'react'
import { useSelector } from 'react-redux';
import WebSeriesComponent from './categorizedWebSeries';

const WebseriesHome = () => {
    const  categoryData = useSelector(
        (store: any) => store?.dashboard?.allCategories?.data
      );
  return (
    <div>
      <div className="mb-4 flex items-center justify-between px-4 mt-4">
            <h3 className="text-xl font-medium textw-[390px]-secondary">Your Free Web series Episodes</h3>
        </div>
        <WebSeriesComponent category={'All'} key={'All'}/>
    { categoryData?.map(category=><WebSeriesComponent category={category?.subCategory} key={category?.subCategory}/>) }
</div>
  )
}

export default WebseriesHome