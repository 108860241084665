import React, { useEffect, useMemo, useRef, useState } from "react";
import Button from "../../../../ui/Button";
import imgupload from "../../../../assets/images/img-upload.png";
import videoupload from "../../../../assets/images/video.png";
import FileUploader from "../../../../ui/fileUploader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import Form from "./form";
import apiCalls from "../../../../utils/api";
import useContract from "../../../../hooks/useContract";
import { setError, setToaster } from "../../../../reducers/layoutReducer";
import { validateForm } from "./formValidation";
const CreateVideo = () => {
  const user = useSelector((store: any) => store.auth.user);
  const { mintVideo } = useContract();
  const rootDispatch = useDispatch();
  const navigate = useNavigate();
  const { channelId, channelToken, creatorId, filterBy, channelName } =
    useParams();
  const { address } = useAccount();
  const thumbnailRef = useRef(null);
  const videoRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [form, setForm] = useState<any>({
    thumbnail: null,
    title: "",
    category: "",
    subCategory: null,
    collection: "",
    description: "",
    language: null,
    videoUrl: null,
    isFreeVideo: false,
    onlyMembers:false,
    buyOrRent:false,
    price: "",
    royality: "",
    walletAddress: address,
    channelId: channelId,
  });
  const [formErrors, setFormErrors] = useState<any>({
    thumbnail: "",
    title: "",
    category: "",
    subCategory: "",
    collection: "",
    description: "",
    language: "",
    videoUrl: "",
    videoType:"",
    price: "",
    royality: "",
    royalityPercentage: "",
  });
  let notRequired = useMemo(()=>{
    return { royality: true }
  },[form.isFreeVideo,form.onlyMembers,form.buyOrRent]);
  const [lookups, setLookups] = useState<any>(null);
  useEffect(() => {
    getLookups();
  }, []);
  const getLookups = async () => {
    try {
      const { categories, collections, languages } =
        await apiCalls.getVideoCreationLookups(channelId);
      let lookups = {};
      if (categories.status === 200) {
        lookups = { ...lookups, categories: categories.data };
      } else {
        rootDispatch(setError(categories));
      }
      if (collections.status === 200) {
        lookups = { ...lookups, collections: collections.data };
      } else {
        rootDispatch(setError(collections));
      }
      if (languages.status === 200) {
        lookups = { ...lookups, languages: languages.data };
      } else {
        rootDispatch(setError(languages));
      }
      Object.keys(lookups).length > 0 && setLookups(lookups);
    } catch (error) {
      rootDispatch(setError(error));
    }
  };
  const getSubCategories = async (category: any) => {
    try {
      const response = await apiCalls.getVideoCreationLookups(
        channelId,
        category
      );
      if (response?.status === 200) {
        setLookups({ ...lookups, subCategories: response?.data });
      } else {
        rootDispatch(setError(response));
      }
    } catch (error) {
      rootDispatch(setError(error));
    }
  };
  const handleClearUploadedFiles = (field: any, ref: any) => {
    setField(field, null);
    if (ref && ref.current) ref.current.value = null;
  };
  const setField = (field: string, value: any) => {
    if (formErrors[field]) {
      setFormErrors({ ...formErrors, [field]: "" });
    }
    if (field === "category" && value) {
      getSubCategories(value);
    }
    if (field === "price" || field === "royality") {
      if (!value || value.match(/^\d{1,}(\.\d{0,8})?$/)) {
        setForm({ ...form, [field]: value });
        return;
      } else {
        return;
      }
    }
    if(field==='isFreeVideo'){
      setForm({ ...form, 'price': '',[field]: value ,'onlyMembers':false,'buyOrRent':false});
      formErrors['videoType'] && setFormErrors({ ...formErrors, 'videoType': "" });
      return;
    }
    if(field==='onlyMembers'){
      setForm({ ...form, 'price': '',[field]: value ,'isFreeVideo':false,'buyOrRent':false});
      formErrors['videoType'] && setFormErrors({ ...formErrors, 'videoType': "" });
      return;
    }
    if(field==='buyOrRent'){
      setForm({ ...form, 'price': '',[field]: value ,'isFreeVideo':false,'onlyMembers':false});
      formErrors['videoType'] && setFormErrors({ ...formErrors, 'videoType': "" });
      return;
    }
    setForm({ ...form, [field]: value });
  };
  const handleSave = async () => {
    setIsSaving(true);
    try {
      if (form.isFreeVideo || form.onlyMembers) notRequired = { ...notRequired, 'price': true };
      const [isValid, formErrors] = validateForm(form, notRequired);
      if (!isValid) {
        setFormErrors(formErrors);
        rootDispatch(setError({message:"Please check and provide the valid information for all fields"}))
        return;
      }
      let remainingFields = {
        onlyForMembers: form.onlyMembers,
        isVideoForSale: form.buyOrRent,
      };
      const response = await apiCalls.handleVideoCreation({
        ...form,
        thumbnail: form.thumbnail?.url,
        videoUrl: form.videoUrl?.url,
        price: (form.price && !form.isFreeVideo) ? Number(form.price) : 0,
        royality: form.royality ? Number(form.royality): 0,
        ...remainingFields,
      });
      if (response?.status === 200) {
        const transaction = await mintVideo(
          channelToken,
          form.price || '0',
          form.title,
          form.description,
          ""
        );
        if (transaction.ok) {
          rootDispatch(
            setToaster({
              message: "Video creation successful!",
              callback: () =>
                navigate(
                  `/channels/${filterBy}/${channelName}/${channelId}/${creatorId}`
                ),
              callbackTimeout: 1000,
            })
          );
        } else {
          rootDispatch(
            setError({
              message: transaction.error,
            })
          );
        }
      } else {
        
        rootDispatch(
          setError({
            message: response,
          })
        );
      }
    } catch (error) {
      rootDispatch(setError({ message: error }));
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <div className="grid md:grid-cols-2 m-5 gap-7">
      <div>
        <div>
          <div className="mb-2">
            <FileUploader
              accept={"images"}
              canDragAndDrop={true}
              uploaderClass="relative flex items-center justify-between border fileUpload video-upload-file"
              inputClass="absolute bottom-0 left-0 right-0 top-0 ml-0 w-full opacity-0 cursor-pointer"
              uploadText="Upload Thumbnail Image"
              uploadIcon={imgupload}
              uploadTextClass="text-secondary opacity-50 text-sm font-normal"
              uploadTextBoxClass="mt-6"
              uploadIconNote="379x213"
              inputRef={thumbnailRef}
              setFile={(payload: any) => setField("thumbnail", payload)}
            />
          </div>
          <div className="uploaded-file rounded-[28px] flex items-center justify-between p-3">
            {!form?.thumbnail && (
              <p className="text-secondary font-medium m-auto">
                <span className="label star">
                  {"Please upload thumbnail for your video!"}
                </span>
              </p>
            )}
            {form?.thumbnail && (
              <>
                {" "}
                <div className="flex items-center gap-1">
                  <span className="icon file-attachment"> </span>
                  <p className="text-sm text-secondary font-medium">
                    {form.thumbnail?.name}
                  </p>
                </div>
                <Button
                  type="plain"
                  handleClick={() =>
                    handleClearUploadedFiles("thumbnail", thumbnailRef)
                  }
                >
                  <span className="icon close cursor-pointer scale-[0.9]"></span>
                </Button>
              </>
            )}
          </div>
          <p className="text-sm font-normal text-red-600 ml-4">
            {formErrors.thumbnail}
          </p>
        </div>

        <div className="mt-4 mb-2">
          <FileUploader
            accept={"videos"}
            canDragAndDrop={true}
            uploaderClass="relative flex items-center justify-between border fileUpload video-upload-file"
            inputClass="absolute bottom-0 left-0 right-0 top-0 ml-0 w-full opacity-0 cursor-pointer"
            uploadIcon={videoupload}
            uploadTextClass="text-secondary opacity-50 text-sm font-normal"
            uploadTextBoxClass="mt-6"
            setFile={(payload: any) => setField("videoUrl", payload)}
            inputRef={videoRef}
            uploadTo="Upload/uploadvideo"
          />
        </div>
        <div className="uploaded-file rounded-[28px] flex items-center justify-between p-3">
          {!form?.videoUrl && (
            <p className="text-secondary font-medium m-auto">
              <span className="label star">{"Please upload your video!"}</span>
            </p>
          )}
          {form?.videoUrl && (
            <>
              {" "}
              <div className="flex items-center gap-1">
                <span className="icon file-attachment"> </span>
                <p className="text-sm text-secondary font-medium">
                  {form.videoUrl?.name}
                </p>
              </div>
              <Button
                type="plain"
                handleClick={() =>
                  handleClearUploadedFiles("videoUrl", videoRef)
                }
              >
                <span className="icon close cursor-pointer scale-[0.9]"></span>
              </Button>
            </>
          )}
        </div>
        <p className="text-sm font-normal text-red-600 ml-4">
          {formErrors.videoUrl}
        </p>
      </div>
      <Form
        setField={setField}
        form={form}
        formErrors={formErrors}
        lookups={lookups}
        handleUpload={handleSave}
        isSaving={isSaving}
      />
    </div>
  );
};
export default CreateVideo;
