import React, { useEffect } from "react";
import VideoCarousal from "../videocarousal";
import WebSeriesComponent from "../Webseries/categorizedWebSeries";
import { useSelector } from "react-redux";
import { fetchAllTrendingVideos } from "../../../reducers/dashboardReducer";
import { store } from "../../../store";
import Movies from "../Movies/movies";
import TopChannels from "../Channels/TopChannels";
import Genres from "../genres";
const take =10;
export default function Home() {
  const dashbaordTrendsData = useSelector((store: any) => store?.dashboard?.trendingVideosAllCategories?.All)

  useEffect(() => {
    store.dispatch(fetchAllTrendingVideos({
      category:'All',
      take: take,
      page: 1,
      search: null
         }));
  }, []);

  
  const firstFourVideos = dashbaordTrendsData?.data ? dashbaordTrendsData?.data.slice(0, 4) : [];
  const remainingVideos = dashbaordTrendsData?.data ? dashbaordTrendsData?.data.slice(4) : [];
  return (
    <div className="max-sm:px-3 mt-3 px-4">
      <VideoCarousal dashbaordTrendsData={firstFourVideos} loading ={dashbaordTrendsData?.loading}/>
     <Movies dashbaordTrendsData={remainingVideos} loading ={dashbaordTrendsData?.loading} />

      <div className="my-10">
        <Genres/>
      </div>
      <hr className="mb-[30px] mt-[40px]" />
      <div>
      <TopChannels/>
      </div>
      <div className="my-10">
        <WebSeriesComponent category={'All'} key={'All'}></WebSeriesComponent>
      </div>
    </div>
  );
}
