import React, { useState } from 'react'
import ChannelsByCategory from './categorizedChannels'
import { useSelector } from 'react-redux';
import ChannelShimmer from '../loaders/channel';

const ChannelsHomePage = () => {
    const  categoryData = useSelector(
        (store: any) => store?.dashboard?.allCategories?.data
      );
  return (
    <div>
        <ChannelsByCategory category={"All"} key={'All'}/>
        { categoryData?.map((category:any)=><>
        <ChannelsByCategory category={category?.subCategory} key={category?.subCategory}/>

        </>) }
    </div>
  )
}

export default ChannelsHomePage