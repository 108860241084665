import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Pageerror from "../utils/pagenotFoundPage";
import ErrorPage from "../utils/errorPage";
import { store } from "../store";
import { getKyc } from "../utils/api";
import { setToken } from "../reducers/rootReducer";
import StreamingDetailview from "../components/streaming/Detailview";
import AppLayout from "./AppLayout";
import NftCardDetailview from "../components/streaming/Detailview/Nftcarddetailview";
import Trending from "../components/streaming/Trending/index.tsx";
// import Movies from "../components/streaming/Movies/movies";
// import Channels from "../components/channels";
// import Channel from "../components/channels/channel";
import WebSeriesComponent from "../components/streaming/Webseries/index.tsx";
import Channels from "../components/streaming/Channels/index.tsx";
import Channel from "../components/streaming/Channels/view.tsx";
import WebSeriesView from "../components/streaming/Webseries/webseriesview.tsx";
import WebseriesDetailview from "../components/streaming/Detailview/webseriesdetailview.tsx";
import MyChannels from "../components/streaming/Channels/myChannels/index.tsx";
import Home from "../components/streaming/Home/index.tsx";
import Movies from "../components/streaming/Movies/index.tsx";
import CreateVideo from "../components/streaming/Video/create";
import Video from "../components/streaming/Video/index.tsx";
import Sumsub from '../components/sumsub'

const KycStatus = React.lazy(() => import("../components/sumsub/kycStatus"));//../components/sumsub/kycStatus
const Profile = React.lazy(() => import("../components/profile"));

const Routes = () => {
  const routes = createBrowserRouter([
    {
      element: (
        <React.Suspense>
          <AppLayout />
        </React.Suspense>
      ),
      errorElement: <ErrorPage />,
      loader: async () => {
        const id = store.getState().auth?.user?.id;
        return getKyc(`User/GetAuthorizationToken/${id || ""}`).then(
          (token) => {
            store.dispatch(setToken(token.data));
            return token;
          }
        );
      },
      children: [
        {
          path: "/",
          element: <Navigate replace to={"/home/All"} />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/home/:filterBy",
          element: <Home />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/kycStatus",
          element: <KycStatus />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/completekyc",
          element: <Sumsub/>,
          errorElement: <ErrorPage />,
        },
        { path: "/profile", element: <Profile />, errorElement: <ErrorPage /> },
        {
          path: "profile/:show",
          element: <Profile />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/watch/:videoId",
          element: <Video />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/webseriesDetailView",
          element: <WebseriesDetailview />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/nftdetailview",
          element: <NftCardDetailview />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/trending/:filterBy",
          element: <Trending />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/channels/:filterBy",
          element: <Channels />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/channels/:filterBy/:channelName/:channelId/:creatorId",
          element: <Channel />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/channel/:id",
          element: <Channel />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/mychannels",
          element: <MyChannels />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/channels/:filterBy/:channelName/:channelId/:creatorId/:channelToken/create/video",
          element: <CreateVideo />,
          errorElement: <ErrorPage />,
        },

        {
          path: "/movies/:filterBy",
          element: <Movies />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/webseries/:filterBy",
          element: <WebSeriesComponent />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/webseries/:filterBy/:seriesName/:seriesId",
          element: <WebseriesDetailview />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/webseriesView/:id",
          element: <WebSeriesView />,
          errorElement: <ErrorPage />,
        },
        {
          path: "/webseries/:webseriesName/:id",
          element: <WebSeriesView />,
          errorElement: <ErrorPage />,
        },
      ],
    },
    {
      path: "*",
      element: (
        <React.Suspense>
          <Pageerror />
        </React.Suspense>
      ),
    },
  ]);

  return <RouterProvider router={routes} />;
};
export default Routes;
