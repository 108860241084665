import { validateContentRule } from "../../../../utils/validation";

export const validateForm = (form: any, notRequired: any) => {
  const {
    thumbnail,
    title,
    category,
    subCategory,
    collection,
    description,
    language,
    videoUrl,
    price,
    royality,
    isFreeVideo,
    onlyMembers,
    buyOrRent,
  } = form;
  const newErrors: any = {};
  let isValid = true;
  if (!thumbnail) {
    newErrors.thumbnail = "Is required";
    isValid = false;
  }
  if (!category || category === "") {
    newErrors.category = "Is required";
    isValid = false;
  }
  if (!subCategory || subCategory === "") {
    newErrors.subCategory = "Is required";
    isValid = false;
  }
  if (!collection || collection === "") {
    newErrors.collection = "Is required";
    isValid = false;
  }
  if (!language || language === "") {
    newErrors.language = "Is required";
    isValid = false;
  }
  if (!title || title === "") {
    newErrors.title = "Is required";
    isValid = false;
  } else if (validateContentRule(title)) {
    newErrors.title = "Please provide valid content";
    isValid = false;
  }
  if (!description || description === "") {
    newErrors.description = "Is required";
    isValid = false;
  } else if (validateContentRule(description)) {
    newErrors.description = "Please provide valid content";
    isValid = false;
  }
  if (!videoUrl || videoUrl === "") {
    newErrors.videoUrl = "Is required";
    isValid = false;
  }
  if (!isFreeVideo && !onlyMembers && !buyOrRent) {
    newErrors.videoType = "Is required";
    isValid = false;
  }
  if ((!price || price === "") && !notRequired?.["price"]) {
    newErrors.price = "Is required";
    isValid = false;
  } else if (!notRequired?.["price"] && !price?.match(/^\d{1,}(\.\d{0,8})?$/)) {
    newErrors.price = "Must be numeric value";
    isValid = false;
  } else if (!notRequired?.["price"] && price * 100000000 <= 0) {
    newErrors.price = "Must be greater than 0";
    isValid = false;
  }
  if ((!royality || royality === "") && !notRequired?.["royality"]) {
    newErrors.royality = "Is required";
    isValid = false;
  } else if (
    !notRequired?.["royality"] &&
    !royality?.match(/^\d{1,}(\.\d{0,8})?$/)
  ) {
    newErrors.royality = "Must be numeric value";
    isValid = false;
  } else if (Number(royality) > 100) {
    newErrors.royality = "Must be less than 100";
    isValid = false;
  }
  return [isValid, newErrors];
};
