import React from "react";
import Button from "./Button";
import ChannelShimmer from "../components/streaming/loaders/channel";
import MovieShimmer from "../components/streaming/loaders/movie.card";
import GenreCard from "./cards/genre";
import ChannelCard from "./cards/channel";
import VideoCard from "./cards/video";
import NoData from "./noData";
interface paginationProps {
  itemsPerPage?: number;
  data: any;
  onPageChange?: Function;
  toDisplay: string;
  title: string;
  isLoading: boolean;
  nextPage: number;
  displayNoData?: boolean;
}
const PaginationComponent = ({
  itemsPerPage = 1,
  data,
  onPageChange,
  toDisplay,
  title,
  isLoading,
  nextPage,
  displayNoData = true,
}: paginationProps) => {
  const handleShowMore = () => {
      onPageChange?.(data, nextPage);
  };
  return (
    <>
      {(data?.length > 0 || isLoading) && (
        <div className={``}>
          {
            <div className="flex items-center justify-between">
              <h3 className="text-xl font-medium text-secondary mb-[14px]">{title}</h3>
            </div>
          }
          {
            <div
              className={`flex flex-wrap ${toDisplay === "channel" ? "gap-[46px]" : "gap-4"}`}
            >
              {isLoading && (
                <>
                  {[...Array(toDisplay === "channel" ? 12:6)].map((_, index) => (
                    <div
                      key={index}
                      className={`${
                        toDisplay === "video"
                          ? "shadow-lg md:w-[420px] max-sm:min-w-[340px]"
                          : ""
                      } ${toDisplay === "webseries" ? 'shadow-lg md:w-[420px] max-sm:min-w-[340px]':''}`}
                    >
                      {toDisplay === "channel" && <ChannelShimmer />}
                      {toDisplay === "video" && <MovieShimmer />}
                      {toDisplay === "webseries" && <MovieShimmer />}
                    </div>
                  ))}
                </>
              )}
              {!isLoading &&
                data?.map((item: any) => {
                  return (
                    <div
                      className={`${
                        toDisplay === "video"
                          ? "shadow-lg md:w-[420px] max-sm:min-w-[340px]"
                          : ""
                      }`}
                      key={item.id}
                    >
                      {toDisplay === "genre" && (
                        <GenreCard
                          id={item.id}
                          image={item.image}
                          category={item.category}
                        />
                      )}
                      {toDisplay === "channel" && (
                        <ChannelCard
                          id={item.id}
                          creatorId={item.creatorId}
                          logo={item.logo}
                          name={item.name}
                          subscribers={item.subscribers}
                          earned={item.earned}
                        />
                      )}
                      {toDisplay === "video" && (
                        <VideoCard
                          id={item.id}
                          price={item.price}
                          videoLink={item.videoLink}
                          thumbnail={item.thumbnail}
                          channelLogo={item.logo}
                          channelName={item.channelName}
                          videoTitle={item.name || item.title}
                          language={item.language}
                          views={item.views}
                          createdDate={item.createdDate}
                          type={item.type}
                          isVideoForSale={item.isVideoForSale}
                          creatorId={item.creatorId}
                        />
                      )}
                      {toDisplay === "webseries" && (
                        <VideoCard
                          id={item.id}
                          price={item.price}
                          videoLink={item.videoLink}
                          thumbnail={item.thumbnail}
                          channelLogo={item.logo}
                          channelName={item.channelName}
                          videoTitle={item.name}
                          language={item.language}
                          views={item.views}
                          createdDate={item.createdDate}
                          type={item.type}
                          creatorId={item.creatorId}
                          isVideoForSale={item.isVideoForSale}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          }
          {data?.length===itemsPerPage*(nextPage-1) && <div className="flex items-center justify-center mt-6">
            <Button type="primary" handleClick={handleShowMore}>Show More</Button></div>}
        </div>
      )}
      {data?.length === 0 &&
        !isLoading &&
        displayNoData && (
          <div className="mb-4">
            <h3 className="text-xl font-medium text-secondary">{title}</h3>
            <NoData />
          </div>
        )}
      {!isLoading && nextPage > 2 && data?.length === 0 && (
        <div className="mb-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-medium text-secondary">{title}</h3>
          </div>
          <NoData text={"No More Data!"} />
        </div>
      )}
    </>
  );
};

export default PaginationComponent;
