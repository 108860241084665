import React, { useState } from 'react';
import Button from '../../../../ui/Button';
import MaticInput from '../../../inputs/maticforminput';
import success from  '../../../../assets/images/success.png'
import successgif from  '../../../../assets/images/reviewsuccess.gif'


const NftProducers = (props: any) => {
  const [isChecked, setIsChecked] = useState(false);
  const [showSuccess,setshowSuccess] = useState(false)

  const successShow = ()=>{
    setshowSuccess(true)
  }
  const openAddmeDrawer = () => {
    setIsChecked(true)
  }
  const closeAddmeDrawer = () => {
    setIsChecked(false)
  }

  return (

    <div className={`flex gap-12 md:gap-4 md:text-center justify-between overflow-x-auto`}>
      {props?.nft?.castCrews?.map((producer: any) => (
        <div className='max-sm:min-w-[120px] truncate ' key={producer.id}>
          <div className={`relative w-20 h-20 mx-auto `}>
            <img src={producer.image} className={`rounded-full w-20 h-20 object-cover`} alt='' />
          </div>
          <p className={`  text-base font-normal text-center font-semibold truncate`}>{producer?.name}</p>
          <p className={`  text-base font-normal text-center font-semibold truncate`}>{producer?.role}</p>
          <p className={`text-base font-normal  text-secondary text-center opacity-80 truncate`}>Earned - <span className='font-[700]'></span></p>
          <p className={`  text-base font-normal text-center font-semibold truncate`}>{producer?.id}</p>
        </div>
      ))}


      <Button handleClick={openAddmeDrawer} btnClassName='bg-transparent h-20 !p-0 hover:bg-transparent'> <div className={`bg-secondary w-20 h-20 rounded-full shrink-0 flex items-center justify-center cursor-pointer ml-0 md:ml-3`}>
        <div className={`text-center`}>
          <span className={`icon plus`}></span>
          <p className={`text-base-100 text-xs font-normal`}>Add Me</p>
        </div>
      </div></Button>

      {isChecked && <div className="drawer drawer-end bg-primary-content sm:w-96 md:w-80 lg:w-96 xl:w-112">
        <input id="my-drawer-4" type="checkbox" className="drawer-toggle" checked={isChecked} />
        <div className="drawer-content">
        </div>
        <div className="drawer-side z-[99]">
          <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay" onChange={closeAddmeDrawer}></label>
          <ul className="menu p-4 md:w-80 min-h-full bg-primary-content">
          <div className="text-right">
              <span className='icon close cursor-pointer' onClick={closeAddmeDrawer}></span>
            </div>

            {showSuccess && <div className='text-center relative pt-24'>            
                        <div>
                        <div className='z-[1] relative'>
                        <img src={success} alt="" className='w-[124px] mx-auto' />
                        <h1 className='text-[28px] text-[#15AB3D] font-semibold mt-3'>Congratulations!</h1>
                        <p className='text-[18px] text-secondary mt-4'>0x4a9Df2CF...37c33929A</p>
                        </div>
                        <img src={successgif} alt="" className='h-[262px] mx-auto mt-[-200px]' />
                         <p className='text-secondary text-base mb-[40px]'>Your <span className='font-semibold opacity-100'>mint for membership pass Registration <br /> is</span> Successfull  </p>
                         <p className='text-secondary opacity-60'>We will Get back to you Soon.</p>
                        </div>
                          </div> }
                         
                    {!showSuccess && <div className='pt-[34px]'>
                      <div className='mt-6 mb-[42px]'>
                        <p className='text-secondary font-semibold text-[32px] text-center leading-10'>Do you want buy nFT <br /> become a producer</p>
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="proposalTitle"
                          className="text-dark text-sm font-normal p-0 mb-2 label ml-4 star"
                        > Name   </label>
                        <input
                          id="proposalTitle"
                          className="input input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-4 h-10"
                          type="text"
                          placeholder="Name"
                          name="proposal"
                          maxLength={250}
                        />
                      </div>
                      <div className="mt-4">
                        <label
                          htmlFor="proposalTitle"
                          className="text-dark text-sm font-normal p-0 mb-2 label ml-4 star"
                        > Address   </label>
                        <input
                          id="proposalTitle"
                          className="input input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-4 h-10"
                          type="text"
                          placeholder="Address"
                          name="proposal"
                          maxLength={250}
                        />
                      </div>
                      <div className="mt-6">
                      <MaticInput customminus='detail-minus icon cursor-pointer' customadd='detail-plus icon cursor-pointer' />
                      </div>
                  <div className="text-center mt-[46px] max-sm:text-center">
                    <Button
                      type="primary"
                      handleClick={successShow}
                      btnClassName=""
                    >
                      MINT For Membership Pass
                    </Button>
                  </div>
                    </div>}
                        
          </ul>
        </div>
      </div>}
    </div>

  );


};

export default NftProducers;