import React, { useEffect } from "react";
import { useLocation,useParams } from 'react-router-dom';
import { getDashboardTopWebSeries ,getAllWebseriesData, clearMoviesData} from "../../../reducers/dashboardReducer";
import { store } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../../reducers/layoutReducer";
import ScrollingComponent from "../../../ui/scrollingComponent";
const take = 10;

const WebSeriesComponent = ({category,key}) => {
    const location = useLocation();

    const rootDispatch=useDispatch()
    const isWebSeriesPath = location.pathname.includes('webseries');
    const dashoBoardTopWebSeries = useSelector((store: any) => store?.dashboard?.dashoBoardTopWebSeries);
    const AllWebSeriesByCategory = useSelector((store: any) => store?.dashboard?.AllWebSeriesData?.[category]);
    const dataToUse = isWebSeriesPath ? AllWebSeriesByCategory : dashoBoardTopWebSeries;

    useEffect(() => { 
        if(location.pathname.includes('home')){
        store.dispatch(getDashboardTopWebSeries({
          type:'webseries',
          take: take,
          page: 1,
          search:null
        }))
      }else if(location?.pathname.includes('webseries')){
        store.dispatch(getAllWebseriesData({
            type:  'webseries',
            category:category,
            take: take,
            page: 1,
            search:null
          }))
      }
      return () => {
        clearMoviesData(category);
      };
    },
     [])
    if(dataToUse?.error){
      rootDispatch(setError({ message: dataToUse?.error }))
    }


    return (
    <>
        {location.pathname.includes('home') && dataToUse?.length > 0 && <>
          {/* <div className="mb-4 flex items-center justify-between px-4 mt-4">
            <h3 className="text-xl font-medium textw-[390px]-secondary">Your Free Web series Episodes</h3>
            <div className="flex ">
                <div className="hover:bg-accent hover:rounded-full h-9 w-9 flex items-center justify-center cursor-pointer"><span className="icon leftarrow-small"></span></div>
                <div className="hover:bg-accent hover:rounded-full h-9 w-9 flex items-center justify-center cursor-pointer"><span className="icon rightarrow-small"></span></div>
            </div>
        </div> */}

          <div className="md:px-4" key={key}>
            <div>
              <ScrollingComponent
                toDisplay="webseries"
                data={dashoBoardTopWebSeries?.data}
                title={''}
                isLoading={dashoBoardTopWebSeries?.loading}
                key={key}
              />
            </div>
            {(dashoBoardTopWebSeries?.data?.length > 0 ||
              dashoBoardTopWebSeries?.loading) && <hr className="mt-[30px]" />}
          </div>
        </>}

        {location.pathname.includes('webseries') &&
          <div className="md:px-4" key={key}>
            <div>
              <ScrollingComponent
                toDisplay="webseries"
                data={AllWebSeriesByCategory?.data}
                title={ category}
                isLoading={AllWebSeriesByCategory?.loading}
                key={key}
              />
            </div>
            {(AllWebSeriesByCategory?.data?.length > 0 ||
              AllWebSeriesByCategory?.loading) && <hr className="mt-[30px]" />}
          </div>
        }
    </>

    );
};

export default WebSeriesComponent;
