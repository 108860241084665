import React, { useEffect } from "react";
import MoviesCard from "../Dashboard/MoviesCard";
import { useDispatch, useSelector } from 'react-redux';
import { clearMoviesData, fetchAllTrendingVideos } from '../../../reducers/dashboardReducer';
import { store } from '../../../store';
import VideoCard from "../../../ui/cards/video";
import PaginationComponent from "../../../ui/paginationComponent";
import { setError } from "../../../reducers/layoutReducer";
import ScrollingComponent from "../../../ui/scrollingComponent";
const take =10;
const TrendingVideosByCategory = ({category,key}) => {
    const trendingVideos = useSelector((store: any) => store?.dashboard?.trendingVideosAllCategories?.[category])
    const rootDispatch=useDispatch()

  useEffect(() => {
    store.dispatch(fetchAllTrendingVideos({ 
      category: category,
      take: take,
      page: 1,
      search: null
       }))
       return () => {
        clearMoviesData(category);
      };
  }, []);
 
  if (trendingVideos?.error) {
    rootDispatch(setError({ message: trendingVideos?.error }))
  }

    return (

      <div className="mt-[34px]" >
      <ScrollingComponent
          toDisplay="video"
          data={trendingVideos?.data}
          title={category === "All" ? "Top Channels" : category}
          isLoading={trendingVideos?.loading}
          key={key}
        />
      {(trendingVideos?.data?.length > 0 ||
        trendingVideos?.loading) && <hr className="mt-[30px]" />}
        
      </div>

    );
};

export default TrendingVideosByCategory;
