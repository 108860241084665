import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../../ui/Button";
import profileIcon from "../../../../../assets/images/daocard-profile.png";
import FileUploader from "../../../../../ui/fileUploader";
import { useAccount } from "wagmi";
import validateForm from "./formValidation";
import apiCalls from "../../../../../utils/api";
import { useDispatch } from "react-redux";
import { setError, setToaster } from "../../../../../reducers/layoutReducer";
import Spinner from "../../../../loaders/spinner";
import useContract from "../../../../../hooks/useContract";
import { store } from "../../../../../store";
import { fetchMyChannels } from "../../../../../reducers/channelReducer";
import { getOption } from "../../../Video/create/utils";
import Select from "react-select";
const CreateChannel = ({ shouldOpenDrawer, handleDrawerActions, user }) => {
  const { address } = useAccount();
  const { createChannel } = useContract();
  const imageRef = useRef(null);
  const rootDispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [form, setForm] = useState({
    image: null,
    name: "",
    description: "",
    walletAddress: address,
    creatorImage: user.profilePicUrl,
    monthlyMembershipPrice: "",
    quarterlyMembershipPrice: "",
    halfYearlyMembershipPrice: "",
    yearlyMembershipPrice: "",
    category: null,
  });
  const [formErrors, setFormErrors] = useState({
    image: null,
    name: "",
    description: "",
    monthlyMembershipPrice: "",
    quarterlyMembershipPrice: "",
    halfYearlyMembershipPrice: "",
    yearlyMembershipPrice: "",
    category: "",
  });
  const [categories, setCategories] = useState(undefined);
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const response = await apiCalls.getVideoCreationLookups("", "channel");
      if (response?.status === 200) {
        setCategories(response?.data);
      } else {
        rootDispatch(setError(response));
      }
    } catch (error) {
      rootDispatch(setError(error));
    }
  };
  const handleBlur = (field: string, value: any) => {
    setField(field, value.trim().replace(/\s+/g, " "));
  };
  const setField = (field: string, value: any) => {
    if (
      field === "monthlyMembershipPrice" ||
      field === "quarterlyMembershipPrice" ||
      field === "halfYearlyMembershipPrice" ||
      field === "yearlyMembershipPrice"
    ) {
      if (!value || value.match(/^\d{1,}(\.\d{0,8})?$/)) {
        setForm({ ...form, [field]: value });
        if (formErrors[field]) {
          setFormErrors({ ...formErrors, [field]: "" });
          return null;
        }
        return;
      } else {
        return;
      }
    }
    setForm({ ...form, [field]: value });
    if (formErrors[field]) {
      setFormErrors({ ...formErrors, [field]: "" });
    }
  };
  const handleChannelCreation = async () => {
    setIsSaving(true);
    try {
      const [isValid, formErrors] = validateForm(form);
      if (!isValid) {
        setFormErrors(formErrors);
        rootDispatch(setError({message: "Please check and provide the valid information for all fields"}))
        return;
      }
      
      const response = await apiCalls.handleChannelCreation(
        { ...form, image: form.image?.url },
        "save"
      );
      if (response?.status === 200) {
        const transaction = await createChannel(
          form.name,
          form.description,
          form.monthlyMembershipPrice,
          // form.quarterlyMembershipPrice,
          // form.halfYearlyMembershipPrice,
          // form.yearlyMembershipPrice,
          ""
        );
        if (transaction.ok) {
          rootDispatch(setToaster({ message: "Channel creation successful!" }));
          handleDrawerActions(false);
          store.dispatch(
            fetchMyChannels({
              id: user.id,
              page: 1,
              skip: 0,
              take: 15,
            })
          );
        } else {
          rootDispatch(
            setError({
              message: transaction.error,
            })
          );
        }
      } else {
        rootDispatch(
          setError({
            message: response,
          })
        );
      }
    } catch (error) {
      rootDispatch(setError({ message: error }));
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <div className="drawer drawer-end bg-primary-content sm:w-96 md:w-80 lg:w-96 xl:w-112">
      <input
        id="my-drawer-4"
        type="checkbox"
        className="drawer-toggle"
        checked={shouldOpenDrawer}
      />
      <div className="drawer-content"></div>
      <div className="drawer-side z-[99]">
        <label
          htmlFor="my-drawer-4"
          aria-label="close sidebar"
          className="drawer-overlay"
          onClick={() => handleDrawerActions(false)}
        ></label>
        <ul className="menu p-4 md:w-80 min-h-full bg-primary-content">
          <div className="flex items-center justify-between">
            <p className="text-xl text-secondary font-semibold">Create New Channel</p>
          <Button type="plain" handleClick={() => handleDrawerActions(false)}>
              <span className="icon close cursor-pointer"></span>
            </Button>
          </div>
          <div className="flex justify-between flex-col gap-4 min-h-[600px] pt-10">
            <div className="mx-auto">
              <div className="relative">
                <FileUploader
                  previewWithInlineUpload={true}
                  accept="images"
                  file={
                    form.image || {
                      url: "https://www.onlylondon.properties/application/modules/themes/views/default/assets/images/image-placeholder.png",
                    }
                  }
                  setFile={(payload: any) => setField("image", payload)}
                  inputRef={imageRef}
                  size={2}
                />
                {formErrors.image && <p className="text-sm font-normal text-red-600 ml-4 text-center">
                  {formErrors.image}
                </p>}
              </div>
            </div>

            <div className="mt-0">
              <div className="">
                <label className="text-dark text-sm font-normal p-0 mb-2 label ml-5 block">
                  <span className="text-primary">* </span>Name
                </label>
                <input
                  className="input text-sm input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-5 h-9"
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  maxLength={250}
                  value={form.name}
                  onChange={(e) => setField("name", e.target.value)}
                  onBlur={(e) => handleBlur("name", e.target.value)}
                />
                <label className="text-sm font-normal text-red-600 ml-4">
                  {formErrors.name}
                </label>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="Description"
                  className="text-dark text-sm font-normal p-0 mb-2 label ml-5 block"
                >
                  <span className="text-primary">* </span>Description
                </label>
                <textarea
                  className="textarea text-sm textarea-bordered w-full rounded-[28px] focus:outline-none pl-5 min-h-[117px]"
                  rows={4}
                  placeholder="Description"
                  name="Description"
                  value={form.description}
                  onChange={(e) => setField("description", e.target.value)}
                  onBlur={(e) => handleBlur("description", e.target.value)}
                />
                <label className="text-sm font-normal text-red-600 ml-4">
                  {formErrors.description}
                </label>
              </div>
              <div className="mt-4">
                <label
                  htmlFor=""
                  className="text-dark text-sm font-normal p-0 mb-2 label ml-5 star"
                >
                  Category
                </label>
                <Select
                  options={categories}
                  classNames={{
                    control: (state) =>
                      state.isFocused
                        ? "border-red-600 !rounded-[50px] min-h-9 pl-2 border-blue"
                        : "border-grey-300 !rounded-[50px] min-h-9 pl-2",
                  }}
                  isMulti={true}
                  onChange={(value) =>
                    setField(
                      "category",
                      value.map((option) => option?.["subCategory"] || option)
                    )
                  }
                  getOptionValue={(option) => getOption(option, "subCategory")}
                  getOptionLabel={(option) => getOption(option, "subCategory")}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#f1f1f1",
                      primary: "#D60000",
                      dangerLight: "red",
                    },
                  })}
                />
                <p className="text-sm font-normal text-red-600 ml-4">
                  {formErrors.category}
                </p>
              </div>
              <div className="mt-4">
                <label className="text-dark text-sm font-normal p-0 mb-2 label ml-3 block">
                  Membership Price (Matic)
                </label>
                <div className="grid grid-cols-2 gap-2 mt-4 gap-x-9">
                  <div>
                    <label className="text-dark text-sm font-normal p-0 mb-2 label ml-5 block">
                      <span className="text-primary">* </span>1 Month
                    </label>
                    <input
                      className="input text-sm input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-5 h-9"
                      type="text"
                      placeholder="Enter 1 Month Price"
                      name="name"
                      value={form.monthlyMembershipPrice}
                      maxLength={250}
                      onChange={(e) =>
                        setField("monthlyMembershipPrice", e.target.value)
                      }
                      onBlur={(e) =>
                        handleBlur("monthlyMembershipPrice", e.target.value)
                      }
                    />
                    <label className="text-sm font-normal text-red-600 ml-4">
                      {formErrors.monthlyMembershipPrice}
                    </label>
                  </div>
                  <div>
                    <label className="text-dark text-sm font-normal p-0 mb-2 label ml-5 block">
                      <span className="text-primary">* </span>3 Months
                    </label>
                    <input
                      className="input text-sm input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-5 h-9"
                      type="text"
                      placeholder="Enter 3 Month Price"
                      name="name"
                      value={form.quarterlyMembershipPrice}
                      maxLength={250}
                      onChange={(e) =>
                        setField("quarterlyMembershipPrice", e.target.value)
                      }
                      onBlur={(e) =>
                        handleBlur("quarterlyMembershipPrice", e.target.value)
                      }
                    />
                    <label className="text-sm font-normal text-red-600 ml-4">
                      {formErrors.quarterlyMembershipPrice}
                    </label>
                  </div>
                  <div className="mt-2">
                    <label className="text-dark text-sm font-normal p-0 mb-2 label ml-5 block">
                      <span className="text-primary">* </span>6 Months
                    </label>
                    <input
                      className="input text-sm input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-5 h-9"
                      type="text"
                      placeholder="Enter 6 Month Price"
                      name="name"
                      value={form.halfYearlyMembershipPrice}
                      maxLength={250}
                      onChange={(e) =>
                        setField("halfYearlyMembershipPrice", e.target.value)
                      }
                      onBlur={(e) =>
                        handleBlur("halfYearlyMembershipPrice", e.target.value)
                      }
                    />
                    <label className="text-sm font-normal text-red-600 ml-4">
                      {formErrors.halfYearlyMembershipPrice}
                    </label>
                  </div>
                  <div className="mt-2">
                    <label className="text-dark text-sm font-normal p-0 mb-2 label ml-5 block">
                      <span className="text-primary">* </span>12 Months
                    </label>
                    <input
                      className="input text-sm input-bordered w-full rounded-[28px] border-[#A5A5A5] focus:outline-none pl-5 h-9"
                      type="text"
                      placeholder="Enter 12 Month Price"
                      name="name"
                      value={form.yearlyMembershipPrice}
                      maxLength={250}
                      onChange={(e) =>
                        setField("yearlyMembershipPrice", e.target.value)
                      }
                      onBlur={(e) =>
                        handleBlur("yearlyMembershipPrice", e.target.value)
                      }
                    />
                    <label className="text-sm font-normal text-red-600 ml-4">
                      {formErrors.yearlyMembershipPrice}
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="WalletAddress"
                  className="text-dark text-sm font-normal p-0 mb-2 label ml-5 block"
                >
                  Wallet Address
                </label>

                <div className="text-sm border-[#A5A5A5] w-full rounded-[28px] border pl-5 h-9 flex items-center gap-2 bg-[rgba(201, 201, 201, 0.35)]">
                  <img
                    src={form.creatorImage || profileIcon}
                    alt=""
                    className="w-5 rounded-full"
                  />
                  <p className="text-secondary opacity-[0.6]">
                    {form.walletAddress}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-14">
              <Button
                type=""
                btnClassName="!bg-accent text-secondary w-full !text-[18px] drawer-bid !h-[39px]"
                handleClick={() => handleDrawerActions(false)}
              >
                Cancel
              </Button>
              <Button
                btnClassName="w-full mt-5 !text-[18px]"
                handleClick={() => handleChannelCreation()}
                disabled={isSaving}
              >
                {isSaving && (
                  <span>
                    <Spinner />
                  </span>
                )}
                Create Channel
              </Button>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default CreateChannel;
