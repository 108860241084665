import React from "react";

const Spinner = () => {
  return (
    <>
      <span className="loading loading-spinner loading-md align-middle mr-2"></span>
    </>
  );
};
export default Spinner;
