import React, { useEffect } from "react";
import { store } from "../../../store";
import {
  clearCategorizedChannelsData,
  fetchCategoryRelatedChannelsData,
} from "../../../reducers/channelReducer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import rootReducer from "../../../reducers/rootReducer";
import PaginationComponent from "../../../ui/paginationComponent";
import { setError } from "../../../reducers/layoutReducer";
const take = 12;
const FilteredChannels = () => {
  const location = useLocation();
  const { filterBy } = useParams();
  const rootDispatch=useDispatch()
  const categorizedChannels = useSelector(
    (store: any) => store?.channels?.categorizedChannels
  );
  useEffect(() => {
    if (location.pathname.includes("/channels")) {
      store.dispatch(
        fetchCategoryRelatedChannelsData({
          category: filterBy?.toLowerCase(),
          data:null,
          take: take,
          page: 1,
          search: null,
        })
      );
    }
    return ()=>{
      clearCategorizedChannelsData()
    }
  }, [filterBy]);
  useEffect(() => {
    store.dispatch(rootReducer);
  }, []);

  const fetchMoreChannels = (data: any, page: number) => {
    store.dispatch(
      fetchCategoryRelatedChannelsData({
        category: filterBy?.toLowerCase(),
        take: take,
        page: page,
        search: null,
        data: data,
      })
    );
  };

  if(categorizedChannels?.error){
    rootDispatch(setError({ message: categorizedChannels?.error }))
  }
  return (
    <div className="px-4 mt-4">
      <div>
        <PaginationComponent
          toDisplay="channel"
          data={categorizedChannels?.data}
          title={filterBy}
          isLoading={categorizedChannels?.loading}
          itemsPerPage={take}
          nextPage={categorizedChannels?.nextPage}
          onPageChange={(data: any, page: number) =>
            fetchMoreChannels(data, page)
          }
          displayNoData={true}
        />
      </div>
    </div>
  );
};

export default FilteredChannels;

