import React from 'react'
import ChannelsHomePage from './channelsHome'
import FilteredChannels from './filteredChannels'
import { useParams } from 'react-router-dom'
 const Channels = () => {
const {filterBy} = useParams();
  return (
    <div>
        {filterBy ==='All' && <ChannelsHomePage />}
        {filterBy !=='All'&& <FilteredChannels />}
      </div>
  )
}
export default Channels
