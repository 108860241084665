import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../../store";
import {
  getAllCategoriesLu,
} from "../../../../reducers/dashboardReducer";
import { Link, useLocation, useParams } from "react-router-dom";
import Spinner from "../../../loaders/spinner";
import { setError } from "../../../../reducers/layoutReducer";
const Explore = () => {
  const { pathname } = useLocation();
  const basePath = pathname.split("/")[1];
 const {filterBy}=useParams()
  const rootDispatch = useDispatch();
  const { loading, data, error } = useSelector(
    (store: any) => store?.dashboard?.allCategories
  );
  useEffect(() => {
    getCategories();
  }, [basePath]);
  const getCategories = () => {
    if (pathname.includes("trending")) {
      store.dispatch(getAllCategoriesLu({ type: "Trending" }));
    } else if (pathname.includes("channels")) {
      store.dispatch(getAllCategoriesLu({ type: "Channel" }));
    } else if (pathname.includes("movies")) {
      store.dispatch(getAllCategoriesLu({ type: "Movies" }));
    } else if (pathname.includes("webseries")) {
      store.dispatch(getAllCategoriesLu({ type: "Web Series" }));
    } else {
      store.dispatch(getAllCategoriesLu({ type: "Home" }));
    }
  };
  if (error) rootDispatch(setError(error));
  return (
    <>
      <h3 className="text-base mb-2.5 px-3">Explore</h3>
      {loading && (
        <p className="text-center">
          <Spinner />
        </p>
      )}
      {!loading && data && (
        <ul className="px-4">
          {data?.map((item: any) => {
            return (
              <li key={item?.id}>
                <Link
                  to={`/${basePath}/${item.subCategory}`}
                  className={`block mb-3.5 px-[18px] py-2 rounded-[30px] cursor-pointer text-base ${
                    filterBy?.toLowerCase()===item.subCategory.toLowerCase()
                      ? "bg-primary text-base-100"
                      : "hover:bg-primary hover:text-base-100"
                  }`}
                >
                  {item?.subCategory}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default Explore;
