import axios from "axios";
import { store } from "../store";
import { ApiControllers } from "../api/config";
const API_END_POINT = process.env.REACT_APP_API_END_POINT;
const API_END_POINT_KYC = process.env.REACT_APP_API_END_POINT_KYCINFO;
const API_VERSION = "api/v1/";
const API_SIGNER_END_POINT = process.env.REACT_APP_API_SIGNATURE;
const REACT_API_BASE_URL = process.env.REACT_APP_API_END_POINT + "api/v1/";

export function getToken() {
  const state = store.getState();
  const token = state.auth.user?.token;
  return token;
}
export function getCombineToken() {
  const state = store.getState();
  const token = state.auth?.token;
  return token;
}
export function updateisreferralpage(url: any, obj: any) {
  return axios.put(`${API_END_POINT_KYC}${API_VERSION}${url}`, obj, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
}

export function isexistingreferralcode(url: string) {
  return axios.get(API_END_POINT_KYC + `${API_VERSION}${url}`, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
}

export function KycPost(url: string, obj: Object) {
  return axios.post(API_END_POINT_KYC + `${API_VERSION}${url}`, obj, {
    headers: {
      Authorization:
        store.getState().auth?.user?.token || store.getState().auth?.token,
    },
  });
}
export function getEarnedBonous(url: string) {
  return axios.get(API_END_POINT + `${API_VERSION}${url}`, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
}

export async function postMinting(url: any, obj: any) {
  return await axios.post(
    `https://devmintapi.azurewebsites.net/${API_VERSION}${url}`,
    obj,
    {
      headers: {
        Authorization: `${getToken()}`,
      },
    }
  );
}
export function getCUstomers(url: string) {
  return axios.get(`https://devkycapi.azurewebsites.net/${API_VERSION}${url}`, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
}
export function getMinting(url: string) {
  return axios.get(
    `https://devmintapi.azurewebsites.net/${API_VERSION}${url}/`,
    {
      headers: {
        Authorization: `${getCombineToken()}`,
      },
    }
  );
}

export async function postSigner(url: string, obj: any) {
  return await axios.post(`${API_SIGNER_END_POINT}${url}`, obj, {
    headers: {
      Authorization: `${getToken()}`,
    },
  });
}

export function get(url: string) {
  return axios.get(API_END_POINT + `${API_VERSION}${url}`, {
    headers: {
      Authorization:
        store.getState().auth?.user?.token || store.getState().auth?.token,
    },
  });
}
export function getCall(url: string) {
  return axios.get(API_END_POINT_KYC + `${API_VERSION}${url}`);
}
export function apiUploadPost(url: string, obj: Object,showProgress:null | Function=null) {
  if(showProgress){
    return axios.post(API_END_POINT + `${API_VERSION}${url}`, obj, {onUploadProgress: progressEvent => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      showProgress?.(percentCompleted);
  }});
  }else{
    return axios.post(API_END_POINT_KYC + `${API_VERSION}${url}`, obj);
  }
}
export function post(url: string, obj: Object) {
  return axios.post(API_END_POINT + `${API_VERSION}${url}`, obj, {
    headers: {
      Authorization:
        store.getState().auth?.user?.token || store.getState().auth?.token,
    },
  });
}
export function put(url: string, obj: any) {
  return axios.put(API_END_POINT + `${API_VERSION}${url}`, obj);
}
export const convertUTCToLocalTime = (dateString: any) => {
  let date = new Date(dateString);
  const milliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  const localTime = new Date(milliseconds);
  return localTime.toISOString();
};
export async function saveUser(url: string, obj: Object) {
  return axios.post(API_END_POINT_KYC + `${API_VERSION}${url}`, obj, {
    headers: {
      Authorization:
        store.getState().auth?.user?.token || store.getState().auth?.token,
    },
  });
}
export async function getKyc(url: string) {
  return await axios.get(API_END_POINT_KYC + `${API_VERSION}${url}`, {
    headers: {
      Authorization:
        store.getState().auth?.user?.token || store.getState().auth?.token,
    },
  });
}

const customerDetails = async (address: any) => {
  return await axios.get(
    API_END_POINT_KYC +
      API_VERSION +
      ApiControllers.user +
      `CustomerDetails/${address}`,
    {
      headers: {
        Authorization:
          store.getState().auth?.user?.token || store.getState().auth?.token,
      },
    }
  );
};
const isErrorDispaly = (objValue) => {
  if (
    objValue.status > 400 &&
    objValue.status < 500 &&
    objValue.status !== 401
  ) {
    return "Something went wrong please try again!";
  } else {
    if (objValue.data && typeof objValue.data === "string") {
      return objValue.data;
    } else if (
      objValue.data &&
      objValue.data.title &&
      typeof objValue.data.title
    ) {
      return objValue.data.title;
    } else if (objValue.title && typeof objValue.title) {
      return objValue.title;
    } else if (objValue.shortMessage && typeof objValue.shortMessage) {
      return objValue.shortMessage;
    } else if (
      objValue.originalError &&
      typeof objValue.originalError.message === "string"
    ) {
      return objValue.originalError.message;
    } else {
      return typeof objValue === "object" && objValue.reason
        ? objValue.reason
        : "Something went wrong please try again!";
    }
  }
};
const uploadErrorDisplay = (objValue) => {
  if (
    objValue?.status >= 400 &&
    objValue?.status < 500 &&
    objValue?.status !== 401
  ) {
    return "Something went wrong please try again!";
  } else {
    if (objValue?.title && typeof objValue?.title) {
      return objValue?.title;
    } else {
      return "Something went wrong please try again!";
    }
  }
};

//-------Streaming---------
const fetchTrendingVideos = async (category:any,take: any, skip: any, search: any) => {
  return await axios.get(
    REACT_API_BASE_URL +
      ApiControllers.user +
      `trendingvideos/${category}/${take}/${skip}/${search}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const getTopMovies = async (type: any, take: any, skip: any, search: any) => {
  return await axios.get(
    REACT_API_BASE_URL +
      ApiControllers.user +
      `topmoviesorwebseries/${type}/${take}/${skip}/${search}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getLeftMenuMoviesorWebSeries = async (
  type: any,
  category: any,
  take: any,
  skip: any,
  search: any
) => {
  return await axios.get(
    REACT_API_BASE_URL +
      ApiControllers.user +
      `moviesorwebseries/${type}/${category}/${take}/${skip}/${search}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getAllChannels = async (category:any,take: any, skip: any, search: any) => {
  return await axios.get(
    REACT_API_BASE_URL +
      ApiControllers.user +
      `topchannels/${category}/${take}/${skip}/${search}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getCategoryWiseChannelsData = async (
  category: any,
  take: any,
  skip: any,
  search: any
) => {
  return await axios.get(
    REACT_API_BASE_URL +
      ApiControllers.user +
      `topchannels/${category}/${take}/${skip}/${search}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getWebSeriesTabsData = async (name: any, take: any, skip: any) => {
  return await axios.get(
    REACT_API_BASE_URL + ApiControllers.user + `seriesdetails/${name}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getWebSeriesEpisodesData = async (
  seriesId: any,
  take: any,
  skip: any,
  search: any
) => {
  return await axios.get(
    REACT_API_BASE_URL +
      ApiControllers.user +
      `WebSeriesEpisode/${seriesId}/${take}/${skip}/${search}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const fetchCategoryLu = async (category: any) => {
  return await axios.get(
    REACT_API_BASE_URL + ApiControllers.user + `subcateorylu/${category}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

//Channel related APIs

const handleChannelCreation = async (object: any, action: string) => {
  if (action === "save") {
    return await axios.post(
      REACT_API_BASE_URL + ApiControllers.user + `savechannel`,
      object,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  if (action === "update") {
    return await axios.put(
      REACT_API_BASE_URL + ApiControllers.user + `updatechannel`,
      object,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
};

const handleChannelSubscription = async (object: any, action: string) => {
  if (action === "save") {
    return await axios.post(
      REACT_API_BASE_URL + ApiControllers.user + `savesubscribe`,
      object,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  if (action === "update") {
    return await axios.put(
      REACT_API_BASE_URL + ApiControllers.user + `updatesubscribe`,
      object,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
};
const getMyChannels=async (take:number,skip:number,id:any)=>{
  return await axios.get(
    REACT_API_BASE_URL + ApiControllers.user + `userchannels/${id}/${take}/${skip}`
  );
}

const getVideoCreationLookups=async (id:string,category='')=>{
  if(category){
    const subcateories=await axios.get(
      REACT_API_BASE_URL + ApiControllers.user + `subcateorylu/${category}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return subcateories;
  }
  const collections=await axios.get(
    REACT_API_BASE_URL + ApiControllers.user + `collectionlu/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const categories=await axios.get(
    REACT_API_BASE_URL + ApiControllers.user + `cateorylu`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const languages=await axios.get(
    REACT_API_BASE_URL + ApiControllers.user + `subcateorylu/languages`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return {categories,collections,languages}
}

const handleVideoCreation=async (object:any)=>{
  return await axios.post(
    REACT_API_BASE_URL + ApiControllers.user + `savevideo`,
    object,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

const postVideoComment=async (object:any)=>{
  return await axios.post(
    REACT_API_BASE_URL + ApiControllers.user + `savecomment`,
    object,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
const apiCalls = {
  isErrorDispaly,
  uploadErrorDisplay,
  customerDetails,
  getTopMovies,
  fetchTrendingVideos,
  fetchCategoryLu,
  getLeftMenuMoviesorWebSeries,
  getAllChannels,
  getWebSeriesTabsData,
  getWebSeriesEpisodesData,
  getCategoryWiseChannelsData,
  handleChannelCreation,
  handleChannelSubscription,
  getVideoCreationLookups,
  getMyChannels,
  handleVideoCreation,
  postVideoComment
};
export default apiCalls;
