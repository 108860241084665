import React from 'react'
import MovieVideosAllCategories from './MovieCarousal'
import movies from '../../../assets/images/movies.png'

const FilteredMovies = () => {

  return (
    <div className="px-4" >
    <div className='flex items-center gap-5 mt-6'>
     <img src={movies} className="w-[150px] h-[150px] rounded-full" alt="" />
     <div>
       <h2 className='text-secondary text-lg font-medium'>Movies</h2>
       <p className='text-scondary'>Watch the latest and greatest hits</p>
     </div>
    </div>
    <div>
     <MovieVideosAllCategories/>
    </div>

    </div>
  )
}

export default FilteredMovies