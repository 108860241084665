import React, { useState } from "react";
import Button from "../Button";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/loaders/spinner";
import { setError, setToaster } from "../../reducers/layoutReducer";
import apiCalls from "../../utils/api";
interface ChannelCardProps {
  id: string;
  creatorId: string;
  logo: string;
  name: string;
  subscribers: string;
  earned: string;
}
const ChannelCard = ({
  id,
  creatorId,
  logo,
  name,
  subscribers,
  earned,
}: ChannelCardProps) => {
  const customerDetails = useSelector((store: any) => store?.auth);
  const { filterBy } = useParams();
  const [subscribed, setSubscribed] = useState({});
  const [isSubscribing, setIsSubscribing] = useState(false);
  const rootDispatch = useDispatch();
  const handleSubscribe = async (channelId: any) => {
    setIsSubscribing(true);
    try {
      const isSubscribed = subscribed[channelId];
      const postData = {
        channelId: channelId,
        customerId: customerDetails.user.id,
        walletAddress: customerDetails.address,
        subscriptionState: !isSubscribed,
      };
      const response = isSubscribed
        ? await apiCalls.handleChannelSubscription(postData, "update")
        : await apiCalls.handleChannelSubscription(postData, "save");

      if (response?.status === 200) {
        rootDispatch(
          setToaster({
            message: `${name} subscription ${
              isSubscribed ? "removed!" : "added!"
            }`,
          })
        );
        setSubscribed((prevSubscribeAction) => {
          const updatesubscribeAction = { ...prevSubscribeAction };
          updatesubscribeAction[channelId] = !isSubscribed;
          return updatesubscribeAction;
        });
      } else {
        rootDispatch(setError({ message: response }));
      }
    } catch (error) {
      rootDispatch(setError({ message: error }));
    } finally {
      setIsSubscribing(false);
    }
  };
  return (
    <div className="flex justify-center" key={id}>
      <div className="text-center">
        <Link to={`/channels/${filterBy}/${name}/${id}/${creatorId}`}>
          <img
            src={logo}
            className="w-[150px] h-[150px] rounded-full object-cover mx-auto"
            alt=""
          />
        </Link>
        <Link to={`/channel/${id}`}>
          <h2 className=" text-lg font-semibold text-secondary mt-4 leading-4 md:w-[150px] truncate">
            {name}
          </h2>
        </Link>
        <p className="text-info text-base">
         <>  {earned?"":"No earnings"} <span className="font-[700]"> {earned}</span>  </>
        </p>
        <p className="text-base text-info mt-2">
          {subscribers + " "} subscribers
        </p>
        {customerDetails.user.id !== creatorId && (
          <Button
            type="secondary"
            btnClassName={`!font-medium ${subscribed[id] ? '!bg-primary':''}`}
            handleClick={() => handleSubscribe(id)}
            disabled={isSubscribing || creatorId === customerDetails.user.id}
          >
            {" "}
            {isSubscribing && (
              <span>
                <Spinner />
              </span>
            )}
            {subscribed[id] ? "Subscribed" : "Subscribe"}
          </Button>
        )}
        {creatorId === customerDetails.user.id && (
          <p className="text-center "><span className="font-medium text-sm text-white bg-[#15AB3D] rounded-[28px] px-2.5 py-1">{"Owned"}</span></p>
        )}
      </div>
    </div>
  );
};

export default ChannelCard;
