import React from 'react';
import MovieShimmer from '../loaders/movie.card';
import VideoCard from '../../../ui/cards/video';
import videoThumbnail from '../../../assets/images/default-thumbnail.jpg';
import profileLogo from '../../../assets/images/default-profile.svg';
const Movies = (props:any) => {
    const take = 6;
    const nextPage = 1;
  return ( 
      <div className="grid md:grid-cols-3 gap-4 my-4">

          {props?.loading && [...Array(take*nextPage)].map((_, index) => (
              <div key={index}>
                <MovieShimmer />
              </div>
            ))} 
            {!props.loading &&(<>
            {
              props?.dashbaordTrendsData?.map((video?: any) => {
                    return (
                        <div key={video.id}>
                            <VideoCard
                                id={video.id}
                                price={video.price}
                                videoLink={video.videoLink}
                                thumbnail={ video.bannerImage }
                                channelLogo={video.logo || profileLogo}
                                channelName={video.channelName}
                                videoTitle={video.name}
                                language={video.language}
                                views={video.views}
                                createdDate={video.createdDate}
                                type={video.type}
                            />
                        </div>
                    );
                })
            }
            </>)}
            

      </div>
  );
};

export default Movies;
