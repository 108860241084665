import React  from 'react'
import { useSelector } from 'react-redux';
import TrendingVideosByCategory from './categoriziedTrendingVideos';

const TrendingHomePage = () => {
    const  categoryData = useSelector(
        (store: any) => store?.dashboard?.allCategories?.data
      );
  return (
    <div>
        <TrendingVideosByCategory category={"All"} key={'All'}/>
        { categoryData?.map((category:any)=><>
        <TrendingVideosByCategory category={category?.subCategory} key={category?.subCategory}/>

        </>) }
    </div>
  )
}

export default TrendingHomePage;