import React, { useState } from 'react';

import CommentsComponent from '../Video/rightSection/comments';
import ReviewComponent from '../Video/rightSection/reviews';
import WriteReview from '../Video/rightSection/writereview';
import ReviewSummary from '../Video/rightSection/reviewsummary';
import ReviewSuccess from '../Video/rightSection/reviewsuccess';
import RelatedVideos from '../Dashboard/Relatedvideos';
import { useParams } from 'react-router-dom';


export default function RightPanel() {

  const [activeTab,setActiveTab]=useState(0)
 const params =useParams()
  return (
    <>
      <div className="border border-[#9AA0A6] rounded-lg  pt-5">
        <div role="tablist" className="tabs tabstyle pb-4">
          <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Comments" checked />
          <div role="tabpanel" className="tab-content pt-10"> <CommentsComponent/></div>

          <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Reviews"  />
          <div role="tabpanel" className="tab-content pt-10 px-3">
            {activeTab===0 && <ReviewComponent setActiveTab={setActiveTab}/>}
            {activeTab === 1 && <WriteReview setActiveTab={setActiveTab} cid={params.id} />}
            {activeTab===2 && <ReviewSummary setActiveTab={setActiveTab}/>}
            {activeTab===3 &&<ReviewSuccess />}
            </div>

        </div>
         
      </div>
      <RelatedVideos  />
    </>
  );
}
