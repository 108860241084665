import React from 'react'
import { useParams } from 'react-router-dom'
import FilteredMovies from './filteredMovies';
import MoviesHomePage from './moviesHome';

 const Movies = () => {
  const {filterBy} = useParams();

  return (
    <div>
        {filterBy ==='All' && <MoviesHomePage /> }
        {filterBy !=='All'&& <FilteredMovies/> }
      </div>
  )
}
export default Movies;
