import React, { useState } from "react";

import CommentsComponent from "./comments";
import ReviewComponent from "./reviews";
import WriteReview from "./writereview";
import ReviewSummary from "./reviewsummary";
import ReviewSuccess from "./reviewsuccess";
import RelatedVideos from "../../Dashboard/Relatedvideos";
import { useParams } from "react-router-dom";

export default function RightSection() {
  const [activeTab, setActiveTab] = useState(0);
  const params = useParams();
  const handleTabChange=(payload:number)=>{
    setActiveTab(payload)
  }
  return (
    <>
      <div className="border border-[#9AA0A6] rounded-lg  pt-5">
        <div role="tablist" className="tabs tabstyle">
          <input
            type="radio"
            name="my_tabs_1"
            role="tab"
            className="tab"
            aria-label="Comments"
            onChange={()=>handleTabChange(0)}
            checked={activeTab === 0}
          />
          {activeTab === 0 && <div role="tabpanel" className="tab-content pt-10">
            {" "}
             <CommentsComponent />
          </div>}
          <input
            type="radio"
            name="my_tabs_1"
            role="tab"
            className="tab"
            aria-label="Reviews"
            onChange={()=>handleTabChange(1)}
            checked={activeTab === 1}
          />
          {activeTab === 1 && <div role="tabpanel" className="tab-content pt-10">
            {" "}
            <ReviewComponent />
          </div>}
        </div>
      </div>
      <RelatedVideos />
    </>
  );
}
