import React, { useReducer, useRef } from "react";
import screenfull from "screenfull";
import ReactPlayer from "react-player";
import Button from "./Button";
import DropdownMenus from "./DropdownMenus";
function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours = hours > 0 ? `${hours}:` : "";
  const formattedMinutes = `${minutes < 10 && hours > 0 ? "0" : ""}${minutes}:`;
  const formattedSeconds = `${
    remainingSeconds < 10 ? "0" : ""
  }${remainingSeconds}`;

  return formattedHours + formattedMinutes + formattedSeconds;
}
const initialState = {
  url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  pip: false,
  playing: false,
  volume: 0.8,
  muted: false,
  duration: 0,
  playbackRate: 1.0,
  loop: false,
  showMenu: false,
  showPlaybackRateMenu: false,
  isFullScreen: false,
  seeking: false,
  iconToShow: null,
  shouldAutoplay: false,
  showControls: true,
  progress: {
    played: 0,
    loaded: 0,
    loadedSeconds: 0,
    playedSeconds: 0,
  },
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_SHOW_CONTROLS":
      return { ...state, showControls: action.payload };
    case "SET_SEEKING":
      return { ...state, seeking: action.payload };
    case "TOGGLE_PIP":
      return { ...state, pip: action.payload };
    case "TOGGLE_PLAYING":
      return { ...state, playing: action.payload };
    case "SET_VOLUME":
      return { ...state, volume: action.payload };
    case "TOGGLE_MUTED":
      return { ...state, muted: action.payload };
    case "SET_DURATION":
      return { ...state, duration: action.payload };
    case "SET_PLAYBACK_RATE":
      return { ...state, playbackRate: action.payload };
    case "TOGGLE_LOOP":
      return { ...state, loop: !state.loop };
    case "TOGGLE_MENU":
      return { ...state, [action.payload.key]: action.payload.value };
    case "TOGGLE_FULL_SCREEN":
      return { ...state, isFullScreen: action.payload };
    case "SET_ICON_TO_SHOW":
      return { ...state, iconToShow: action.payload };
    case "SET_AUTO_PLAY":
      return { ...state, shouldAutoplay: action.payload };
    case "SET_PROGRESS":
      return { ...state, progress: action.payload };
    default:
      return state;
  }
};

let inactivityTimer;
const VideoPlayer = (information: any) => {
  const { src, playerClass, controls = true, poster, videoclass } = information;
  const [
    {
      playing,
      volume,
      muted,
      loop,
      duration,
      playbackRate,
      pip,
      showMenu,
      showPlaybackRateMenu,
      isFullScreen,
      seeking,
      iconToShow,
      shouldAutoplay,
      showControls,
      progress,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const playerRef = useRef<any>(null);
  const playerWrapperRef = useRef<any>(null);

  const handlePlayPause = () => {
    dispatch({ type: "SET_ICON_TO_SHOW", payload: null });
    if (playing) {
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "play" });
    } else {
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "pause" });
    }
    setTimeout(() => {
      dispatch({ type: "SET_ICON_TO_SHOW", payload: null });
    }, 500);
    dispatch({ type: "TOGGLE_PLAYING", payload: !playing });
  };

  // const handleToggleLoop = () => {
  //   dispatch({ type: "TOGGLE_LOOP", loop: !loop });
  // };
  const handleVolumeChange = (e: any) => {
    const value = parseFloat(e.target.value);
    if (value > 0 && muted) {
      dispatch({ type: "TOGGLE_MUTED", payload: false });
    } else if (value === 0 && !muted) {
      dispatch({ type: "TOGGLE_MUTED", payload: true });
    }
    dispatch({ type: "SET_VOLUME", payload: parseFloat(e.target.value) });
  };

  const handleToggleMuted = () => {
    dispatch({ type: "SET_VOLUME", payload: muted ? 0.8 : 0 });
    dispatch({ type: "TOGGLE_MUTED", payload: !muted });
  };

  const toggleMenu = (key: string) => {
    if (key === "showMenu") {
      dispatch({
        type: "TOGGLE_MENU",
        payload: { key: key, value: !showMenu },
      });
      dispatch({
        type: "TOGGLE_MENU",
        payload: { key: "showPlaybackRateMenu", value: false },
      });
    } else if (key === "showPlaybackRateMenu") {
      dispatch({
        type: "TOGGLE_MENU",
        payload: { key: key, value: !showPlaybackRateMenu },
      });
      dispatch({
        type: "TOGGLE_MENU",
        payload: { key: "showMenu", value: false },
      });
    }
  };
  const handleOnPlaybackRateChange = (speed: any) => {
    dispatch({
      type: "SET_PLAYBACK_RATE",
      payload: parseFloat(speed),
    });
    dispatch({
      type: "TOGGLE_MENU",
      payload: { key: "showMenu", value: false },
    });
    dispatch({
      type: "TOGGLE_MENU",
      payload: { key: "showPlaybackRateMenu", value: false },
    });
  };

  const handleTogglePIP = () => {
    dispatch({ type: "TOGGLE_PIP", payload: !pip });
  };

  const handleSeekMouseDown = (e) => {
    dispatch({ type: "SET_SEEKING", payload: true });
    iconToShow === "replay" &&
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "play" });
    dispatch({
      type: "SET_PROGRESS",
      payload: { ...progress, played: parseFloat(e.target.value) },
    });
  };

  const handleSeekChange = (e) => {
    iconToShow === "replay" &&
      !playing &&
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "play" });
    dispatch({
      type: "SET_PROGRESS",
      payload: { ...progress, played: parseFloat(e.target.value) },
    });
  };

  const handleSeekMouseUp = (e) => {
    playerRef?.current?.seekTo(parseFloat(e.target.value), "fraction");
    iconToShow === "replay" &&
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "play" });
    dispatch({ type: "SET_SEEKING", payload: false });
    dispatch({
      type: "SET_PROGRESS",
      payload: { ...progress, played: parseFloat(e.target.value) },
    });
  };

  const handleProgress = (state: any) => {
    if (!seeking) {
      dispatch({ type: "SET_PROGRESS", payload: state });
    }
  };

  const handleEnded = () => {
    if (loop) {
      dispatch({ type: "TOGGLE_PLAYING", payload: loop });
    } else {
      dispatch({ type: "TOGGLE_PLAYING", payload: false });
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "replay" });
    }
  };

  const handleDuration = (duration: any) => {
    dispatch({ type: "SET_DURATION", payload: duration });
  };

  const handleClickFullscreen = () => {
    if (screenfull.isEnabled && playerWrapperRef.current) {
      if (!screenfull.isFullscreen) {
        screenfull.request(playerWrapperRef.current);
      } else {
        screenfull.exit();
      }
    }

    dispatch({ type: "TOGGLE_FULL_SCREEN", payload: !isFullScreen });
  };
  const handleBuffer = () => {
    dispatch({ type: "SET_ICON_TO_SHOW", payload: "loader" });
  };
  const handleReady = () => {
    dispatch({ type: "SET_ICON_TO_SHOW", payload: null });
  };
  const handleSeekBy = (seconds: number) => {
    const currentTime = playerRef?.current?.getCurrentTime();
    playerRef?.current?.seekTo(currentTime + seconds, "seconds");
    if (seconds < 0) {
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "backward" });
    } else {
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "forward" });
    }
    if (
      currentTime + seconds >= duration
    ) {
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "replay" });
      return;
    }
    setTimeout(() => {
      dispatch({
        type: "SET_ICON_TO_SHOW",
        payload: playing ? "pause" : "play",
      });
    }, 500);
  };
  const handleMouseOver = () => {
    if (playing && duration - progress.playedSeconds > 3) {
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "pause" });
    } else if (progress.playedSeconds === duration) {
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "replay" });
    } else if (iconToShow !== "replay") {
      dispatch({ type: "SET_ICON_TO_SHOW", payload: "play" });
    }
  };

  const handleMouseLeave = () => {
    dispatch({ type: "SET_ICON_TO_SHOW", payload: null });
  };
  const handleReplay = () => {
    playerRef?.current?.seekTo(0, "seconds");
    dispatch({ type: "TOGGLE_PLAYING", payload: true });
  };
  const playbackRateList = [
    { name: "0.5x", action: () => handleOnPlaybackRateChange(0.5) },
    { name: "1x", action: () => handleOnPlaybackRateChange(1) },
    { name: "1.5x", action: () => handleOnPlaybackRateChange(1.5) },
    { name: "2x", action: () => handleOnPlaybackRateChange(2) },
  ];
  const videoSettingsList = [
    { name: "Picture in picture", action: handleTogglePIP },
    {
      name: "Playback Rate Menu",
      children: (
        <DropdownMenus
          menuwidth={"!left-[-24px]"}
          btnClass={
            "block py-2 font-semibold focus:bg-transparent active:!bg-transparent active:!text-primary flex hover:text-primary hover:bg-transparent text-sm px-2 undefined bg-transparent"
          }
          btnContent={`Playback rate (${playbackRate}x)`}
          dropdownClass="dropdown-top dropdown-end"
          dropdownListClass={"text-sm px-2 hover:bg-[#f7f7f7]"}
          dropdownList={playbackRateList}
        />
      ),
    },
  ];

  const handleMouseActions = () => {
    clearTimeout(inactivityTimer);
    dispatch({ type: "SET_SHOW_CONTROLS", payload: true });
    inactivityTimer = setTimeout(
      () => dispatch({ type: "SET_SHOW_CONTROLS", payload: false }),
      3000
    );
  };
  return (
    <div className="app">
      <section className="section">
        <button
          ref={playerWrapperRef}
          className={`relative w-full bg-[black] rounded-tl-lg rounded-tr-lg ${playerClass}`}
          onMouseMove={handleMouseActions}
        >
          {showControls && (
            <div
              className={`${
                showControls &&
                iconToShow &&
                iconToShow !== "loader" &&
                iconToShow !== "replay"
                  ? "absolute rounded-full flex items-center top-[50%] bottom-[50%] right-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[0] w-fit h-fit p-4 bg-[rgba(0,0,0,0.5)]"
                  : ""
              }`}
            >
              {iconToShow === "play" && (
                <span className="icon play  scale-[1.4]"></span>
              )}
              {iconToShow === "pause" && (
                <span className="icon pause  scale-[1.4]"></span>
              )}
              {iconToShow === "backward" && (
                <span className="icon backward  scale-[1.4]"></span>
              )}
              {iconToShow === "forward" && (
                <span className="icon forward  scale-[1.4]"></span>
              )}
            </div>
          )}
          {iconToShow === "loader" && (
            <div className="absolute rounded-full flex items-center top-[50%] bottom-[50%] right-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[0] w-fit h-fit p-4 bg-[rgba(0,0,0,0.5)]">
              <span className="loading loading-spinner loading-lg text-[white]"></span>
            </div>
          )}
          {iconToShow === "replay" && (
            <div className="absolute rounded-full flex items-center top-[50%] bottom-[50%] right-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[0] w-fit h-fit p-4 bg-[rgba(0,0,0,0.5)]">
              <Button type="plain" handleClick={() => handleReplay()}>
              <span className="icon replay  scale-[1.4]"></span>
              </Button>
            </div>
          )}
          <button
            className="cursor-pointer h-full"
            onClick={handlePlayPause}
            onMouseLeave={handleMouseLeave}
            onMouseOver={handleMouseOver}
          >
            <ReactPlayer
              ref={playerRef}
              className={`react-player ${videoclass}`}
              width="100%"
              height="100%"
              url={src}
              pip={pip}
              playing={playing || shouldAutoplay}
              controls={false}
              loop={loop}
              seekTo={progress.played}
              playbackRate={playbackRate}
              volume={volume}
              muted={muted}
              onReady={() => handleReady()}
              onStart={() => console.log("onStart")}
              onBuffer={() => handleBuffer()}
              onEnded={handleEnded}
              onError={(e) => console.log("onError", e)}
              onProgress={handleProgress}
              onDuration={handleDuration}
              config={{
                file: {
                  attributes: {
                    poster: poster,
                  },
                },
              }}
              // onPlaybackQualityChange={(e) =>
              //   console.log("onPlaybackQualityChange", e)
              // }
            />
          </button>
          {controls && showControls && (
            <div className="px-3 py-4 absolute bottom-0 bg-[#00000040] w-full flex flex-col gap-2 z-[9999] opacity-100">
              <div className="flex w-full mb-3 items-center">
                <div className="relative flex-grow">
                  <input
                    className="range range-xs [--range-shdw:red] w-[100%] absolute right-0 z-[1] h-[6px]"
                    type="range"
                    min={0}
                    max={1}
                    step="any"
                    value={progress.played}
                    onMouseDown={handleSeekMouseDown}
                    onChange={handleSeekChange}
                    onMouseUp={handleSeekMouseUp}
                  />
                  <input
                    className="range video-range range-xs [--range-shdw:#A7A6A6] w-[100%] absolute right-0  bg-[#656464] h-[6px]"
                    type="range"
                    max={1}
                    value={progress.loaded}
                    disabled
                  />
                </div>
              </div>
              <div className="flex justify-between gap-5 items-center">
                {" "}
                <div className="flex gap-[5px] items-center">
                  <Button type="plain" handleClick={handlePlayPause}>
                    {playing ? (
                      <span className="icon pause"></span>
                    ) : (
                      <span className="icon play"></span>
                    )}
                  </Button>
                  <div className="flex items-center">
                    <Button type="plain" handleClick={handleToggleMuted}>
                      {volume === 0 ? (
                        <span className="icon muted"></span>
                      ) : (
                        <span className="icon volume"></span>
                      )}
                    </Button>

                    <input
                      type="range"
                      min={0}
                      max={1}
                      step="any"
                      value={volume}
                      onChange={handleVolumeChange}
                      className="range mt-1 volume-range range-xs [--range-shdw:red] bg-[#656464] h-[6px]"
                    />
                  </div>
                  <p className="text-white whitespace-nowrap mt-1">
                    {`${formatTime(progress.playedSeconds || 0)} / ${formatTime(
                      duration
                    )}`}
                  </p>
                  <Button type="plain" handleClick={() => handleSeekBy(-10)}>
                    <span className="icon backward ml-2"></span>
                  </Button>
                  <Button type="plain" handleClick={() => handleSeekBy(10)}>
                    <span className="icon forward ml-1"></span>
                  </Button>
                </div>
                <div className="flex gap-5 items-center relative">
                  <DropdownMenus
                    btnContent={<span className="icon settings"></span>}
                    dropdownList={videoSettingsList}
                    dropdownClass="dropdown-top dropdown-end"
                    btnClass="text-white"
                    dropdownListClass="text-sm px-2"
                    menuwidth={"min-w-[178px]"}
                  />
                  <div>
                    <Button
                      type="plain"
                      handleClick={() => handleClickFullscreen()}
                    >
                      {isFullScreen ? (
                        <span className="icon exit-fullscreen"></span>
                      ) : (
                        <span className="icon full-screen"></span>
                      )}
                    </Button>
                  </div>
                  {/* {showPlaybackRateMenu && (
                    <div className="flex flex-col bg-black">
                      {playbackRateList.map((playbackRate) => {
                        return (
                          <Button
                            key={playbackRate.name}
                            type="plain"
                            handleClick={() =>
                              handleOnPlaybackRateChange(playbackRate.value)
                            }
                          >
                            {playbackRate.name}
                          </Button>
                        );
                      })}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          )}
        </button>
      </section>
    </div>
  );
};

export default VideoPlayer;
